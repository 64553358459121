import { GridColDef, GridRenderCellParams, GridSelectionModel } from "@mui/x-data-grid"
import { translate } from "app/language/service"
import { useAppDispatch, useAppSelector } from "app/store/hooks"
import { store } from "app/store/store"
import { dateSVC } from "app/tool/date/service"
import { debounce } from "lodash"
import { messageSVC } from "message/service"
import { JTag } from "organization/model"
import React from "react"
import { DATA_SOURCE_PERMISSIONS, DATA_SOURCE_STATUSES, DATA_SOURCE_TYPES, JDataSource } from "spatialdatasource/model"
import { reload, setFilterModel, setPage, setPageSize, setSdsPermissionDialog, setSdsToUpdate, setSortModel, setTotalRowCount } from "spatialdatasource/store"
import { deleteDataSource, getDataSourceDeleteMessage, getDataSourceStatusLevel, getDataSources, getUserDataSourcePermissions } from "spatialdatasource/utils"
import { ButtonMenu, JButtonMenuItem } from "ui/components/ButtonMenu"
import { ButtonMenuSelected } from "ui/components/ButtonMenuSelected"
import { GRID_CELL_WITH_OVERFLOW_CLASS_NAME, GridCellOverflowBorder } from "ui/components/GridCellOverflowBorder"
import { PortalDataGrid } from "ui/components/PortalDataGrid"
import { StatusChip } from "ui/components/StatusChip"
import { useHoverableDataGridRows } from "ui/hooks"
import { STATUS_CHIP_LEVELS } from "ui/model"
import { getGridDateOperatorsForJMC, getGridStringOperatorsForJMC } from "ui/tools/grid"
import { isCurrentUserAtLeastOrgEditor } from "user/tools/permissions"

export interface JDataSourceGridProps {
  setDataSourceToDisplay: (dataSource: JDataSource) => void
  selectedDataSourceIds: GridSelectionModel
  setSelectedDataSourceIds: (ids: GridSelectionModel) => void
  quickFilter: string
  selectedDataSourcesIsOwner: boolean
  selectedDataSourcesIsOwnerLoading: boolean
}

export const DataSourceGrid = (props: JDataSourceGridProps): JSX.Element => {
  const [sources, setSources] = React.useState<JDataSource[]>([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [loadingError, setLoadingError] = React.useState<true | null>(null)
  const { hoveredRowId, setHoveredRowId, ...rowHandlers } = useHoverableDataGridRows()
  const [updateDataMenuItemDisabledBySourceId, setUpdateDataMenuItemDisabledBySourceId] = React.useState<Record<string, boolean>>({})
  const [deleteMenuItemDisabledBySourceId, setDeleteMenuItemDisabledBySourceId] = React.useState<Record<string, boolean>>({})
  const [permissionsMenuItemDisabled, setPermissionsMenuItemDisabled] = React.useState(true)
  const abortRef = React.useRef(0)

  const { page, pageSize, totalRowCount, sortModel, filterModel, reloadCounter } = useAppSelector(state => ({
    page: state.sds.page,
    pageSize: state.sds.pageSize,
    totalRowCount: state.sds.totalRowCount,
    sortModel: state.sds.sortModel,
    filterModel: state.sds.filterModel,
    reloadCounter: state.sds.reloadCounter
  }))
  const dispatch = useAppDispatch()

  const fetchDataSources = React.useCallback(
    debounce(async () => {
      setIsLoading(true)
      setLoadingError(null)
      const currentRequest = ++abortRef.current

      try {
        const resp = await getDataSources(page, pageSize, sortModel, {
          ...filterModel,
          quickFilterValues: [props.quickFilter]
        })

        if (currentRequest === abortRef.current) {
          dispatch(setTotalRowCount(resp.page.totalElements))
          setSources(resp.result)
        }
      } catch (error: any) {
        if (error.name !== "AbortError") {
          console.error(error)
          setLoadingError(true)
        }
      } finally {
        setIsLoading(false)
      }
    }, 300),
    [page, pageSize, sortModel, filterModel, props.quickFilter]
  )

  React.useEffect(() => {
    fetchDataSources()
    return () => fetchDataSources.cancel()
  }, [fetchDataSources, reloadCounter])

  const columns: Array<GridColDef<JDataSource, any, any>> = [
    {
      field: "name",
      headerName: translate("label.name"),
      minWidth: 280,
      flex: 2,
      filterOperators: getGridStringOperatorsForJMC()
    },
    {
      field: "action",
      sortable: false,
      filterable: false,
      headerName: "",
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      maxWidth: 10,
      align: "right",
      renderCell: (params: GridRenderCellParams<any, JDataSource, any>) => {
        if (params.id === hoveredRowId && props.selectedDataSourceIds.length >= 2 && props.selectedDataSourceIds.includes(params.row.id)) {
          const items: JButtonMenuItem[] = [
            {
              disabled: !props.selectedDataSourcesIsOwner,
              isLoading: props.selectedDataSourcesIsOwnerLoading,
              title: translate("label.delete"),
              onClick: () => {
                const dataSources = props.selectedDataSourceIds.map(dataSourceId => sources.find(source => source.id === dataSourceId))
                const references = dataSources.flatMap(dataSource => {
                  if (dataSource.additionalInfo?.references?.projects) {
                    return Object.keys(dataSource.additionalInfo.references.projects)
                  } else {
                    return []
                  }
                })

                const message = `${translate("sds.delete.many.message", { numSds: props.selectedDataSourceIds.length })} <br><br> ${
                  references.length > 0
                    ? `${translate("sds.batch.delete.message.references1")}<br><br>${translate("sds.batch.delete.message.references2")}`
                    : translate("sds.batch.delete.message.noReferences")
                }`

                messageSVC.confirmDialog({
                  confirmButtonLabel: translate("button.delete"),
                  cancelButtonLabel: translate("button.cancel"),
                  isCancelDefault: true,
                  title: translate("sds.delete.many.title"),
                  message,
                  onSuccess: () => {
                    const promises = props.selectedDataSourceIds.map(id => deleteDataSource(id as string))
                    Promise.allSettled(promises)
                      .then(result => {
                        const rejected = result.filter(r => r.status === "rejected")
                        if (rejected.length > 0) {
                          messageSVC.error(translate("sds.delete.many.error", { numSds: rejected.length }))
                        }
                        dispatch(reload())
                      })
                      .catch(error => {
                        console.error(error)
                      })
                  }
                })
              }
            }
          ]
          return <ButtonMenuSelected id={`sds-row-menu-${params.row.id}`} selectedIds={props.selectedDataSourceIds} small items={items} onClose={() => setHoveredRowId(null)} />
        } else if (params.id === hoveredRowId) {
          const items: JButtonMenuItem[] = [
            {
              disabled:
                (updateDataMenuItemDisabledBySourceId[params.row.id] ?? true) ||
                (params.row.status !== DATA_SOURCE_STATUSES.READY && params.row.status !== DATA_SOURCE_STATUSES.ERROR) ||
                ![DATA_SOURCE_TYPES.FILE, DATA_SOURCE_TYPES.RASTER].includes(params.row.type),
              title: translate("sds.update.data"),
              /** will trigger the popup of {@link FileSelectDialog} in {@link DataSourcePanel} */
              onClick: () => dispatch(setSdsToUpdate(params.row))
            },
            {
              disabled: permissionsMenuItemDisabled,
              title: translate("sds.permissions"),
              onClick: () => {
                store.dispatch(setSdsPermissionDialog(params.row))
              }
            },
            {
              disabled: params.row.status !== DATA_SOURCE_STATUSES.READY,
              title: translate("sds.details.title"),
              onClick: () => props.setDataSourceToDisplay(params.row)
            },
            {
              disabled: deleteMenuItemDisabledBySourceId[params.row.id] ?? true,
              title: translate("label.delete"),
              onClick: async () => {
                const message = await getDataSourceDeleteMessage(params.row)
                messageSVC.confirmDialog({
                  confirmButtonLabel: translate("button.delete"),
                  cancelButtonLabel: translate("button.cancel"),
                  isCancelDefault: true,
                  title: translate("sds.delete.title"),
                  message,
                  onSuccess: () => {
                    deleteDataSource(params.row.id)
                      .then(() => dispatch(reload()))
                      .catch(console.error)
                  }
                })
              }
            }
          ]
          return (
            <ButtonMenu
              id={`sds-row-menu-${params.row.id}`}
              small
              items={items}
              onClick={() => {
                if (isCurrentUserAtLeastOrgEditor()) {
                  getUserDataSourcePermissions(params.row.id).then(permissions => {
                    const userIsProjectOwner = permissions.includes(DATA_SOURCE_PERMISSIONS.OWNER)
                    setPermissionsMenuItemDisabled(!userIsProjectOwner)
                    setUpdateDataMenuItemDisabledBySourceId({ ...updateDataMenuItemDisabledBySourceId, [params.row.id]: !permissions.includes(DATA_SOURCE_PERMISSIONS.MODIFY) })
                    setDeleteMenuItemDisabledBySourceId({ ...updateDataMenuItemDisabledBySourceId, [params.row.id]: !permissions.includes(DATA_SOURCE_PERMISSIONS.OWNER) })
                  })
                }
              }}
              onClose={() => setHoveredRowId(null)}
            />
          )
        } else {
          return null
        }
      }
    },
    {
      field: "type",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerName: translate("label.type"),
      renderCell: params => translate(`sds.type.${params.row.type}`),
      minWidth: 140,
      flex: 1
    },
    {
      field: "status",
      headerName: translate("label.status"),
      minWidth: 140,
      flex: 1,
      type: "singleSelect",
      valueOptions: Object.values(DATA_SOURCE_STATUSES),
      renderCell: params => <StatusChip level={getDataSourceStatusLevel(params.row.status)} label={translate(`sds.status.${params.row.status}`)} />
    },
    {
      field: "crs",
      headerName: translate("label.crs"),
      minWidth: 140,
      flex: 1,
      filterOperators: getGridStringOperatorsForJMC()
    },
    {
      field: "featureCount",
      headerName: translate("label.elements"),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      minWidth: 90,
      flex: 1,
      valueGetter: params => params.row.additionalInfo?.featureCount
    },
    {
      field: "tags",
      headerName: translate("label.tags"),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      minWidth: 280,
      flex: 2,
      cellClassName: GRID_CELL_WITH_OVERFLOW_CLASS_NAME,
      renderCell: (params: GridRenderCellParams<any, JDataSource, any>) =>
        params.row.tags
          .map((tag: JTag) => <StatusChip sx={{ marginRight: "0.5rem" }} key={tag.id} level={STATUS_CHIP_LEVELS.NEUTRAL} label={tag.name} />)
          .concat([
            /* overflow fadeout border - must be used with cellClassName */
            <GridCellOverflowBorder key="grid-cell-overflow-border" />
          ])
    },
    {
      field: "lastModificationDate",
      type: "date",
      headerName: translate("label.lastModificationDate"),
      renderCell: params => dateSVC.format(params.row.lastModificationDate, { displayTime: true }),
      minWidth: 140,
      flex: 1,
      filterOperators: getGridDateOperatorsForJMC()
    }
  ]

  return (
    <PortalDataGrid
      rowType="sds"
      rows={sources}
      columns={columns}
      loading={isLoading}
      checkboxSelection
      paginationMode="server"
      sortingMode="server"
      filterMode="server"
      rowCount={totalRowCount}
      pageSize={pageSize}
      onPageSizeChange={s => dispatch(setPageSize(s))}
      page={page}
      onPageChange={p => dispatch(setPage(p))}
      selectionModel={props.selectedDataSourceIds}
      onSelectionModelChange={props.setSelectedDataSourceIds}
      error={loadingError}
      initialState={{
        sorting: {
          sortModel
        },
        filter: {
          filterModel
        }
      }}
      onSortModelChange={m => dispatch(setSortModel(m))}
      onFilterModelChange={m => dispatch(setFilterModel(m))}
      componentsProps={{
        row: rowHandlers
      }}
      onRowDoubleClick={params => {
        if (params.row.status === "READY" || params.row.status === "ERROR") {
          // Opens the dialog
          props.setDataSourceToDisplay(params.row)
        } else if (params.row.status === "UPDATING" || params.row.status === "NOT_READY") {
          messageSVC.warning(translate("sds.status.NOT_READY.message"))
        }
      }}
    />
  )
}
