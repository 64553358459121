import { Box, Typography } from "@mui/material"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { GridSelectionModel } from "@mui/x-data-grid-pro"
import { translate } from "app/language/service"
import { useAppDispatch, useAppSelector } from "app/store/hooks"
import { store } from "app/store/store"
import { dateSVC } from "app/tool/date/service"
import { getRouteBasePath } from "app/utils/common"
import { JProject } from "jmapcloud-types"
import { messageSVC } from "message/service"
import { JTag } from "organization/model"
import { activatePageByPathInStore } from "page/tools/common"
import { PROJECT_PERMISSIONS } from "project/model"
import { reload, setFilterModel, setFormDialog, setPage, setPageSize, setProjectMvtCacheDialog, setProjectPermissionDialog, setSortModel, setTotalRowCount } from "project/store"
import { copyProjectPublicUrlToClipboard, deleteProject, getProjectLayerCounts, getProjects, getUserProjectPermissions } from "project/utils"
import React from "react"
import { useNavigate } from "react-router-dom"
import { getNgBaseUrl } from "server/tools/common"
import { setProject } from "studio/store"
import { ButtonMenu, JButtonMenuItem } from "ui/components/ButtonMenu"
import { ButtonMenuSelected } from "ui/components/ButtonMenuSelected"
import { GRID_CELL_WITH_OVERFLOW_CLASS_NAME, GridCellOverflowBorder } from "ui/components/GridCellOverflowBorder"
import { PortalDataGrid } from "ui/components/PortalDataGrid"
import { StatusChip } from "ui/components/StatusChip"
import { useHoverableDataGridRows } from "ui/hooks"
import { STATUS_CHIP_LEVELS } from "ui/model"
import { getGridDateOperatorsForJMC, getGridStringOperatorsForJMC } from "ui/tools/grid"
import { isCurrentUserAtLeastOrgEditor } from "user/tools/permissions"

const projectsPermissionsMap = new Map<string, string[]>()

export const ProjectGrid = (): JSX.Element => {
  const [projects, setProjects] = React.useState<JProject[]>([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [loadingError, setLoadingError] = React.useState<true | null>(null)
  const [editMenuItemDisabledByProjectId, setEditMenuItemDisabledByProjectId] = React.useState<Record<string, boolean>>({})
  const [permissionsMenuItemDisabledByProjectId, setPermissionsMenuItemDisabledByProjectId] = React.useState<Record<string, boolean>>({})
  const [deleteMenuItemDisabledByProjectId, setDeleteMenuItemDisabledByProjectId] = React.useState<Record<string, boolean>>({})
  const [mvtCacheMenuItemDisabledByProjectId, setMvtCacheMenuItemDisabledByProjectId] = React.useState<Record<string, boolean>>({})
  const [selectedProjectIds, setSelectedProjectIds] = React.useState<GridSelectionModel>([])
  const [selectedProjectsIsOwner, setSelectedProjectsIsOwner] = React.useState<boolean>(false)
  const [selectedProjectsIsOwnerLoading, setSelectedProjectsIsOwnerLoading] = React.useState<boolean>(false)
  const navigate = useNavigate()

  const { hoveredRowId, setHoveredRowId, ...rowHandlers } = useHoverableDataGridRows()

  const { page, pageSize, totalRowCount, sortModel, filterModel, reloadCounter, quickFilter } = useAppSelector(state => ({
    page: state.project.page,
    pageSize: state.project.pageSize,
    totalRowCount: state.project.totalRowCount,
    sortModel: state.project.sortModel,
    filterModel: state.project.filterModel,
    reloadCounter: state.project.reloadCounter,
    quickFilter: state.project.quickFilter
  }))
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    setIsLoading(true)
    setLoadingError(null)
    getProjects(page, pageSize, sortModel, { ...filterModel, quickFilterValues: [quickFilter] })
      .then(resp => {
        dispatch(setTotalRowCount(resp.page.totalElements))
        const projectsWithoutLayerCounts = resp.result
        setProjects(projectsWithoutLayerCounts)
        getProjectLayerCounts(projectsWithoutLayerCounts)
          .then(projectsWithLayerCounts => {
            setProjects(projectsWithLayerCounts)
          })
          .catch(error => {
            console.error(error)
          })
      })
      .catch(error => {
        console.error(error)
        setLoadingError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [page, pageSize, sortModel, filterModel, reloadCounter, quickFilter])

  React.useEffect(() => {
    const checkIfAllProjectsAreOwners = async () => {
      const isOwnerArray = await buildIsOwnerArray()
      const allProjectsAreOwners = isOwnerArray.every(isOwner => isOwner === true)
      setSelectedProjectsIsOwner(allProjectsAreOwners)
    }
    setSelectedProjectsIsOwnerLoading(true)
    checkIfAllProjectsAreOwners()
  }, [selectedProjectIds])

  const buildIsOwnerArray = React.useCallback(async (): Promise<boolean[]> => {
    const isOwnerArray: boolean[] = await Promise.all(
      selectedProjectIds.map(async id => {
        if (!projectsPermissionsMap.has(id as string)) {
          const permissions = await getUserProjectPermissions(id as string)
          projectsPermissionsMap.set(id as string, permissions)
          return permissions.includes(PROJECT_PERMISSIONS.OWNER)
        } else {
          return projectsPermissionsMap.get(id as string)!.includes(PROJECT_PERMISSIONS.OWNER)
        }
      })
    )
    setSelectedProjectsIsOwnerLoading(false)
    return isOwnerArray
  }, [selectedProjectIds])

  const columns: Array<GridColDef<JProject, any, any>> = [
    {
      field: "name",
      valueGetter: params => params.row.name[params.row.defaultLanguage],
      headerName: translate("label.name"),
      minWidth: 280,
      flex: 2,
      filterOperators: getGridStringOperatorsForJMC()
    },
    {
      field: "action",
      sortable: false,
      filterable: false,
      headerName: "",
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      maxWidth: 10,
      align: "right",
      renderCell: (params: GridRenderCellParams<any, JProject, any>) => {
        if (params.id === hoveredRowId && selectedProjectIds.length >= 2 && selectedProjectIds.includes(params.row.id)) {
          const items: JButtonMenuItem[] = [
            {
              disabled: !selectedProjectsIsOwner,
              isLoading: selectedProjectsIsOwnerLoading,
              title: translate("label.delete"),
              onClick: () => {
                messageSVC.confirmDialog({
                  confirmButtonLabel: translate("button.delete"),
                  cancelButtonLabel: translate("button.cancel"),
                  isCancelDefault: true,
                  title: translate("project.delete.many.title"),
                  message: translate("project.delete.many.message", { num: selectedProjectIds.length }),
                  onSuccess: () => {
                    const promises = selectedProjectIds.map(id => deleteProject(id as string))
                    Promise.allSettled(promises)
                      .then(result => {
                        const rejected = result.filter(r => r.status === "rejected")
                        if (rejected.length > 0) {
                          messageSVC.error(translate("project.delete.many.error", { num: rejected.length }))
                        }
                        dispatch(reload())
                      })
                      .catch(error => {
                        console.error(error)
                      })
                  }
                })
              }
            }
          ]
          return <ButtonMenuSelected id={`project-row-menu-${params.row.id}`} selectedIds={selectedProjectIds} small items={items} onClose={() => setHoveredRowId(null)} />
        } else if (params.id === hoveredRowId) {
          const items: JButtonMenuItem[] = [
            {
              title: translate("label.open.ng"),
              onClick: () => {
                window.open(`${getNgBaseUrl()}?ngProjectId=${params.row.id}`, "_blank")
              }
            },
            {
              title: translate("label.open.studio"),
              disabled: editMenuItemDisabledByProjectId[params.row.id] ?? true,
              onClick: () => {
                store.dispatch(setProject({ id: params.row.id, name: params.row.name[params.row.defaultLanguage], defaultLanguage: params.row.defaultLanguage }))
                activatePageByPathInStore(`${getRouteBasePath()}/studio`)
                navigate(`${getRouteBasePath()}/studio`)
              }
            },
            {
              title: translate("label.edit"),
              disabled: editMenuItemDisabledByProjectId[params.row.id] ?? true,
              onClick: () => {
                store.dispatch(setFormDialog({ isOpen: true, project: params.row }))
              }
            },
            {
              title: translate("project.permissions"),
              disabled: permissionsMenuItemDisabledByProjectId[params.row.id] ?? true,
              onClick: () => {
                store.dispatch(setProjectPermissionDialog(params.row))
              }
            },
            {
              title: translate("project.public.copyUrl.tooltip"),
              disabled: !params.row.public,
              onClick: () => {
                copyProjectPublicUrlToClipboard(params.row)
              }
            },
            {
              title: translate("mvt.cache"),
              disabled: mvtCacheMenuItemDisabledByProjectId[params.row.id] ?? true,
              onClick: () => {
                store.dispatch(setProjectMvtCacheDialog(params.row))
              }
            },
            {
              title: translate("label.delete"),
              disabled: deleteMenuItemDisabledByProjectId[params.row.id] ?? true,
              onClick: () => {
                messageSVC.confirmDialog({
                  confirmButtonLabel: translate("button.delete"),
                  cancelButtonLabel: translate("button.cancel"),
                  isCancelDefault: true,
                  title: translate("project.delete.title"),
                  message: translate("project.delete.message"),
                  onSuccess: () => {
                    deleteProject(params.row.id)
                      .then(() => dispatch(reload()))
                      .catch(error => {
                        console.error(error)
                      })
                  }
                })
              }
            }
          ]
          return (
            <ButtonMenu
              id={`project-row-menu-${params.row.id}`}
              onClick={() => {
                if (isCurrentUserAtLeastOrgEditor()) {
                  getUserProjectPermissions(params.row.id).then(permissions => {
                    const userIsProjectOwner = permissions.includes(PROJECT_PERMISSIONS.OWNER)
                    const userCanModifyProject = permissions.includes(PROJECT_PERMISSIONS.MODIFY)
                    setEditMenuItemDisabledByProjectId({
                      ...editMenuItemDisabledByProjectId,
                      [params.row.id]: !userCanModifyProject
                    })
                    setPermissionsMenuItemDisabledByProjectId({
                      ...permissionsMenuItemDisabledByProjectId,
                      [params.row.id]: !userIsProjectOwner
                    })
                    setDeleteMenuItemDisabledByProjectId({
                      ...deleteMenuItemDisabledByProjectId,
                      [params.row.id]: !userIsProjectOwner
                    })
                    setMvtCacheMenuItemDisabledByProjectId({
                      ...mvtCacheMenuItemDisabledByProjectId,
                      [params.row.id]: !userCanModifyProject
                    })
                  })
                }
              }}
              small
              items={items}
              onClose={() => setHoveredRowId(null)}
            />
          )
        } else {
          return null
        }
      }
    },
    {
      field: "layerCount",
      headerName: translate("project.layer.count"),
      minWidth: 140,
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true
    },
    {
      field: "public",
      headerName: translate("project.visibility"),
      renderCell: params => <Typography color={params.row.public ? "green" : "red"}>{params.row.public ? translate("project.visibility.public") : translate("project.visibility.private")}</Typography>,
      minWidth: 140,
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true
    },
    {
      field: "tags",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerName: translate("label.tags"),
      minWidth: 280,
      flex: 2,
      cellClassName: GRID_CELL_WITH_OVERFLOW_CLASS_NAME,
      renderCell: (params: GridRenderCellParams<any, JProject, any>) =>
        params.row.tags
          .map((tag: JTag) => <StatusChip sx={{ marginRight: "0.5rem" }} key={tag.id} level={STATUS_CHIP_LEVELS.NEUTRAL} label={tag.name} />)
          .concat([
            /* overflow fadeout border - must be used with cellClassName */
            <GridCellOverflowBorder key="grid-cell-overflow-border" />
          ])
    },
    {
      field: "lastModificationDate",
      type: "date",
      filterOperators: getGridDateOperatorsForJMC(),
      headerName: translate("label.lastModificationDate"),
      renderCell: params => dateSVC.format(params.row.lastModificationDate, { displayTime: true }),
      minWidth: 140,
      flex: 1
    }
  ]

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        maxHeight: "100%",
        paddingTop: "1.5rem",
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        display: "flex",
        alignItems: "start",
        justifyContent: "start",
        overflow: "auto"
      }}
    >
      <PortalDataGrid
        rowType="project"
        rows={projects}
        columns={columns}
        loading={isLoading}
        checkboxSelection
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        rowCount={totalRowCount}
        pageSize={pageSize}
        onPageSizeChange={s => dispatch(setPageSize(s))}
        page={page}
        onPageChange={p => dispatch(setPage(p))}
        selectionModel={selectedProjectIds}
        onSelectionModelChange={setSelectedProjectIds}
        error={loadingError}
        initialState={{
          sorting: {
            sortModel
          },
          filter: {
            filterModel
          }
        }}
        onSortModelChange={m => dispatch(setSortModel(m))}
        onFilterModelChange={m => dispatch(setFilterModel(m))}
        componentsProps={{
          row: rowHandlers
        }}
      />
    </Box>
  )
}
