import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, TextField, Typography } from "@mui/material"
import { useErrorHandling } from "app/hook"
import { translate } from "app/language/service"
import { deepEquals } from "app/utils/common"
import { Formik } from "formik"
import { JGroup, JGroupCreateParams } from "group/model"
import { createGroup } from "group/tools/common"
import React from "react"
import { checkMinMaxLength } from "ui/tools/form"

interface GroupCreateDialogProps {
  existingGroups: JGroup[]
  onClose: () => void
  onCreate: () => void
}

export const GroupCreateDialog = (props: GroupCreateDialogProps): JSX.Element => {
  const { hasError, errorMessage, handleError, resetError } = useErrorHandling(translate("organization.group.create.submit.error"))
  const initialValues: JGroupCreateParams = {
    name: ""
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={values => {
        if (hasError) {
          resetError()
        }
        const errors: any = {}
        if (!values.name || values.name.length === 0) {
          errors.name = translate("label.field.required")
        }
        if (values.name && props.existingGroups.find(g => g.name.toLowerCase() === values.name.toLowerCase())) {
          errors.name = translate("label.name.exists.error")
        }
        checkMinMaxLength(errors, "name", values.name, 250, 2)
        return errors
      }}
      onSubmit={(values, { setSubmitting }) => {
        if (hasError) {
          resetError()
        }
        createGroup(values)
          .then(() => {
            props.onCreate()
          })
          .catch(error => {
            console.error(error)
            handleError(error)
          })
          .finally(() => setSubmitting(false))
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
        const nameError: string = touched.name === true && errors.name !== undefined ? errors.name : ""
        const hasChanged: boolean = !deepEquals(values, initialValues)
        const canSubmit: boolean = !isSubmitting && hasChanged && Object.keys(errors).length === 0
        return (
          <Dialog open fullWidth maxWidth="sm" id="group-create-popup" onClose={props.onClose}>
            <form onSubmit={handleSubmit}>
              <DialogTitle sx={{ padding: "0 1rem 1rem 1rem" }}>{translate("organization.group.create.title")}</DialogTitle>
              <DialogContent sx={{ padding: "0 1rem 1rem 1rem" }}>
                <Grid container>
                  <Grid item width={"100%"}>
                    <TextField
                      id="group-create-page-field-title"
                      name="name"
                      required
                      fullWidth
                      error={!!nameError}
                      label={translate("label.group.new.name")}
                      value={values.name}
                      helperText={nameError ? errors.name : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "space-between", padding: "1rem 1rem 0 1rem" }}>
                {hasError ? (
                  <Typography color="error" sx={{ marginLeft: "0.5rem" }}>
                    {errorMessage}
                  </Typography>
                ) : (
                  <div />
                )}
                <Stack direction="row" alignItems="center" spacing={1}>
                  {isSubmitting && <CircularProgress size={20} />}
                  <Button id="group-create-page-button-cancel" variant="outlined" disabled={isSubmitting} onClick={props.onClose} type="reset">
                    {translate("button.cancel")}
                  </Button>
                  <Button id="group-create-page-button-save" type="submit" disabled={!canSubmit}>
                    {translate("button.create")}
                  </Button>
                </Stack>
              </DialogActions>
            </form>
          </Dialog>
        )
      }}
    </Formik>
  )
}
