import { store } from "app/store/store"
import { ALL_MEMBER_ROLES, MEMBER_ROLES, ROLES } from "user/model"

export const orderedMemberRoles: MEMBER_ROLES[] = [ROLES.ORG_VIEWER, ROLES.ORG_EDITOR, ROLES.ORG_ADMIN]

export function rolesIncludeMinimumMemberRole(roles: ROLES[], minimumMemberRole: MEMBER_ROLES): boolean {
  // roles will maybe be multiple for members in the future,
  // a member can only have one of ORG_ADMIN, ORG_EDITOR or ORG_VIEWER, but other non-exclusive roles will
  // eventually be added. Thus, we keep an array logic here
  const userMemberRoles = roles.filter(role => ALL_MEMBER_ROLES.includes(role as MEMBER_ROLES)) as MEMBER_ROLES[]
  const requiredRoleIndex = orderedMemberRoles.indexOf(minimumMemberRole)
  for (const userMemberRole of userMemberRoles) {
    if (orderedMemberRoles.indexOf(userMemberRole) >= requiredRoleIndex) {
      return true
    }
  }
  return false
}

export function isCurrentUserAtLeastOrgAdmin(): boolean {
  return rolesIncludeMinimumMemberRole(store.getState().user.roles, ROLES.ORG_ADMIN)
}

export function isCurrentUserAtLeastOrgEditor(): boolean {
  return rolesIncludeMinimumMemberRole(store.getState().user.roles, ROLES.ORG_EDITOR)
}

export function doesCurrentUserHasAtLeastRole(minimumRole: MEMBER_ROLES): boolean {
  return rolesIncludeMinimumMemberRole(store.getState().user.roles, minimumRole)
}
