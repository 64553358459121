import { Avatar } from "@mui/material"
import { translate } from "app/language/service"
import { JGroup } from "group/model"
import { messageSVC } from "message/service"
import React from "react"
import { ROLES } from "user/model"
import { getAvatarLetterFromRole } from "user/utils"
import { StatusChip } from "./components/StatusChip"
import { STATUS_CHIP_LEVELS } from "./model"

export function copyFieldValueInClipboard(field: any, fieldName: string) {
  navigator.clipboard.writeText(field)
  const message = messageSVC.info(translate("field.value.copied.message", { fieldName }))
}

export function getRoleChips(roles: ROLES[]): JSX.Element[] {
  return roles
    .sort()
    .filter(role => role !== ROLES.SYS_ADMIN)
    .map(role => (
      <StatusChip key={role} sx={{ marginRight: "0.5rem" }} avatar={<Avatar>{getAvatarLetterFromRole(role)}</Avatar>} level={STATUS_CHIP_LEVELS.NEUTRAL} label={translate(`user.role.${role}`)} />
    ))
}

export function getGroupChips(groups: JGroup[]): JSX.Element[] {
  return groups.sort().map(group => <StatusChip key={group.id} sx={{ marginRight: "0.5rem" }} level={STATUS_CHIP_LEVELS.NEUTRAL} label={group.name} />)
}
