import { Avatar, AvatarGroup, useTheme } from "@mui/material"
import React from "react"
import { MEMBER_ROLES } from "user/model"
import { getOrgRoleAvatar } from "user/tools/common"

interface OrgRoleAvatarGroupProps {
  roles: MEMBER_ROLES[]
  avatarSize?: number
}

export const OrgRoleAvatarGroup = (props: OrgRoleAvatarGroupProps) => {
  const theme = useTheme()
  const color = theme.palette.mode === "dark" ? theme.palette.info.light : theme.palette.info.dark
  const backgroundColor = theme.palette.mode === "dark" ? theme.palette.info.dark : theme.palette.info.light
  return (
    <AvatarGroup
      sx={{
        ["& .MuiAvatarGroup-avatar"]: { borderColor: theme.palette.info.main, borderWidth: "1px" },
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: ".25rem"
      }}
    >
      {props.roles.map((r: MEMBER_ROLES) => (
        <Avatar key={getOrgRoleAvatar(r)} sx={{ width: props.avatarSize ?? 14, height: props.avatarSize ?? 14, color, backgroundColor }}>
          <span style={{ fontSize: "small" }}>{getOrgRoleAvatar(r)}</span>
        </Avatar>
      ))}
    </AvatarGroup>
  )
}
