import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { GridRowId, GridSelectionModel } from "@mui/x-data-grid-pro"
import { translate } from "app/language/service"
import { JGroupWithFullMembers } from "group/model"
import { deleteGroupMember } from "group/tools/common"
import { JMember } from "member/model"
import { messageSVC } from "message/service"
import React from "react"

interface GroupMembersDeleteDialogProps {
  memberIdsToDelete: GridSelectionModel
  currentRowMemberId: GridRowId | null
  group: JGroupWithFullMembers
  onClose: () => void
  onDelete: () => void
}

export const GroupMembersDeleteDialog = (props: GroupMembersDeleteDialogProps): JSX.Element => {
  const [members, setMembers] = React.useState<JMember[]>([])
  const [isDeletingMembers, setIsDeletingMembers] = React.useState(false)

  React.useEffect(() => {
    if (props.currentRowMemberId && !props.memberIdsToDelete.includes(props.currentRowMemberId)) {
      setMembers([props.group.members.find(member => member.id === props.currentRowMemberId)])
    } else {
      setMembers(props.memberIdsToDelete.map(id => props.group.members.find(member => member.id === id)))
    }
  }, [props.group, props.memberIdsToDelete, props.currentRowMemberId])

  const handleDeleteMembers = async () => {
    setIsDeletingMembers(true)
    try {
      await Promise.all(members.map(member => deleteGroupMember(props.group.id, member.id)))
      props.onDelete()
    } catch (error) {
      console.error(error)
      messageSVC.error(members.length > 1 ? translate("group.delete.members.multiple.error") : translate("group.delete.members.single.error"))
    } finally {
      setIsDeletingMembers(false)
      props.onClose()
    }
  }

  return (
    <Dialog open={true} aria-labelledby="form-dialog-title">
      <DialogTitle
        sx={{
          minWidth: "20rem"
        }}
      >
        {members.length > 1 ? translate("group.delete.members.multiple.message.title") : translate("group.delete.members.single.message.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          dangerouslySetInnerHTML={{
            __html:
              members.length > 1
                ? translate("group.delete.members.multiple.message.confirm", { memberNames: members.map(member => member.name).join("<br>"), groupName: props.group.name })
                : translate("group.delete.members.single.message.confirm", { memberName: members?.[0]?.name, groupName: props.group.name })
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()} variant="outlined" color="primary" disabled={isDeletingMembers}>
          {translate("button.cancel")}
        </Button>
        <Button onClick={handleDeleteMembers} disabled={isDeletingMembers}>
          {translate("button.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
