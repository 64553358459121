import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Card, CardContent, CardMedia, CircularProgress, Tooltip, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { translate } from "app/language/service"
import { store } from "app/store/store"
import { getRouteBasePath } from "app/utils/common"
import { JProject } from "jmapcloud-types"
import { messageSVC } from "message/service"
import { activatePageByPathInStore } from "page/tools/common"
import { PROJECT_PERMISSIONS } from "project/model"
import { reload, setFormDialog, setProjectMvtCacheDialog, setProjectPermissionDialog } from "project/store"
import { copyProjectPublicUrlToClipboard, deleteProject, getUserProjectPermissions } from "project/utils"
import React from "react"
import { useNavigate } from "react-router-dom"
import defaultProjectBackground from "resources/images/blur-map.jpeg"
import { getNgBaseUrl } from "server/tools/common"
import { setProject } from "studio/store"
import { ButtonMenu, JButtonMenuItem } from "ui/components/ButtonMenu"
import { isCurrentUserAtLeastOrgEditor } from "user/tools/permissions"

interface JProjectGridProps {
  project: JProject
}

export const ProjectCardItem = ({ project }: JProjectGridProps): JSX.Element => {
  const [editMenuItemDisabled, setEditMenuItemDisabled] = React.useState(true)
  const [permissionsMenuItemDisabled, setPermissionsMenuItemDisabled] = React.useState(true)
  const [deleteMenuItemDisabled, setDeleteMenuItemDisabled] = React.useState(true)
  const [mvtCacheMenuItemDisabled, setMvtCacheMenuItemDisabled] = React.useState(true)
  const navigate = useNavigate()

  const menuItems: JButtonMenuItem[] = [
    {
      title: translate("label.open.ng"),
      onClick: () => {
        window.open(`${getNgBaseUrl()}?ngProjectId=${project.id}`, "_blank")
      }
    },
    {
      title: translate("label.open.studio"),
      disabled: editMenuItemDisabled,
      onClick: () => {
        store.dispatch(setProject({ id: project.id, name: project.name[project.defaultLanguage], defaultLanguage: project.defaultLanguage }))
        activatePageByPathInStore(`${getRouteBasePath()}/studio`)
        navigate(`${getRouteBasePath()}/studio`)
      }
    },
    {
      title: translate("label.edit"),
      disabled: editMenuItemDisabled,
      onClick: () => {
        store.dispatch(setFormDialog({ isOpen: true, project }))
      }
    },
    {
      title: translate("project.permissions"),
      disabled: permissionsMenuItemDisabled,
      onClick: () => {
        store.dispatch(setProjectPermissionDialog(project))
      }
    },
    {
      title: translate("project.public.copyUrl.tooltip"),
      disabled: !project.public,
      onClick: () => {
        copyProjectPublicUrlToClipboard(project)
      }
    },
    {
      title: translate("mvt.cache"),
      disabled: mvtCacheMenuItemDisabled,
      onClick: () => {
        store.dispatch(setProjectMvtCacheDialog(project))
      }
    },
    {
      title: translate("label.delete"),
      disabled: deleteMenuItemDisabled,
      onClick: () => {
        const message = translate("project.delete.message", { projectName: `<b>${project.name[project.defaultLanguage]}</b>` })
        messageSVC.confirmDialog({
          confirmButtonLabel: translate("button.delete"),
          cancelButtonLabel: translate("button.cancel"),
          isCancelDefault: true,
          title: translate("project.delete.title"),
          message,
          onSuccess: () => {
            deleteProject(project.id)
              .then(() => {
                store.dispatch(reload())
              })
              .catch(error => {
                console.error(error)
              })
          }
        })
      }
    }
  ]

  const layerCountIsLoading = project.layerCount === undefined || project.layerCount === null

  return (
    <Card
      sx={{
        width: "18.125rem",
        height: "18.75rem"
      }}
    >
      {/* Top image part */}
      <CardMedia component={"img"} height={150} image={`${defaultProjectBackground}`}></CardMedia>

      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between"
        }}
      >
        {/* Row: Title with padlock and buttons */}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Tooltip title={project.name[project.defaultLanguage]}>
            <Typography
              color="textPrimary"
              sx={{
                fontWeight: "bold",
                fontSize: "1rem",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
              }}
            >
              {project.name[project.defaultLanguage]}
            </Typography>
          </Tooltip>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Tooltip title={project.public ? translate("project.card.public.yes") : translate("project.card.public.no")}>
              <Box>
                <FontAwesomeIcon icon={project.public ? solid("lock-open") : solid("lock")} />
              </Box>
            </Tooltip>
            <ButtonMenu
              id={`project-grid-item-${project.id}`}
              onClick={() => {
                if (isCurrentUserAtLeastOrgEditor()) {
                  getUserProjectPermissions(project.id).then(permissions => {
                    const userIsProjectOwner = permissions.includes(PROJECT_PERMISSIONS.OWNER)
                    const userCanModifyProject = permissions.includes(PROJECT_PERMISSIONS.MODIFY)
                    setEditMenuItemDisabled(!userCanModifyProject)
                    setPermissionsMenuItemDisabled(!userIsProjectOwner)
                    setDeleteMenuItemDisabled(!userIsProjectOwner)
                    setMvtCacheMenuItemDisabled(!userCanModifyProject)
                  })
                }
              }}
              items={menuItems}
              small
            />
          </Stack>
        </Box>

        {/* Description */}

        <Typography color="textPrimary" sx={{ fontSize: "0.875rem", height: "4.2rem", overflow: "auto" }}>
          {project.description[project.defaultLanguage] ?? ""}
        </Typography>

        {/* Number of layers */}

        <Box>
          {layerCountIsLoading ? (
            <CircularProgress size="1rem" />
          ) : (
            <Typography color="textPrimary" sx={{ fontSize: "0.875rem" }}>
              {translate("project.card.layer.count", { layerCount: project.layerCount })}
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  )
}
