import { regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Stack } from "@mui/material"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { translate } from "app/language/service"
import { useAppSelector } from "app/store/hooks"
import { store } from "app/store/store"
import { GroupGridCell } from "group/components/GroupGridCell"
import { getAllGroups } from "group/tools/common"
import { JMemberWithGroups } from "member/model"
import { getAllMembers, getFormattedRole } from "member/tools/common"
import { OrganizationInvitationCreateDialog } from "organization/components/OrganizationInvitationCreateDialog"
import { organizationActionCreator } from "organization/store/actions"
import { naturalSortString } from "project/utils"
import React from "react"
import { PortalDataGrid } from "ui/components/PortalDataGrid"
import { QuickFilterInput } from "ui/components/QuickFilterInput"
import { useHoverableDataGridRows } from "ui/hooks"
import { getUserOrganization } from "user/tools/common"
import { MemberDeleteDialog } from "./MemberDeleteDialog"
import { MemberDetailsDialog } from "./MemberDetailsDialog"
import { MemberGridRowMenu } from "./MemberGridRowMenu"
import { MemberUpdateDialog } from "./MemberUpdateDialog"

export const MemberGrid = (): JSX.Element => {
  const { hoveredRowId, setHoveredRowId, ...rowHandlers } = useHoverableDataGridRows()
  const [members, setMembers] = React.useState<JMemberWithGroups[]>([])
  const [initialMembers, setInitialMembers] = React.useState<JMemberWithGroups[]>([])
  const [selectedMember, setSelectedMember] = React.useState<JMemberWithGroups | null>(null)
  const [isDetailsDialogOpened, setIsDetailsDialogOpened] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)
  const [hasLoadingError, setHasLoadingError] = React.useState(false)
  const [isInvitationDialogOpened, setIsInvitationDialogOpened] = React.useState(false)
  const [isUpdatePopupOpened, setIsUpdatePopupOpened] = React.useState(false)
  const [isDeleteMemberDialogOpened, setIsDeleteMemberDialogOpened] = React.useState(false)
  const [quickFilter, setQuickFilter] = React.useState<string>("")
  const [reloadCounter, setReloadCounter] = React.useState(0)
  const { pageSize } = useAppSelector(state => state.organization)

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const [memberData, groupData] = await Promise.all([getAllMembers(getUserOrganization().id), getAllGroups()])
        const memberDataWithGroups = memberData
          .map(member => {
            const groupsOfMember = groupData.filter(group => group.members.some(m => m.id === member.id))
            return { ...member, groups: groupsOfMember }
          })
          .sort((m1, m2) => naturalSortString(m1.email, m2.email))
        setMembers(memberDataWithGroups)
        setInitialMembers(memberDataWithGroups)
      } catch (error) {
        console.error(error)
        setMembers([])
        setInitialMembers([])
        setHasLoadingError(true)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [reloadCounter])

  const reloadGrid = () => {
    setReloadCounter(v => v + 1)
  }

  React.useEffect(() => {
    const newFilteredMember = initialMembers.filter(member => member.name.toLowerCase().includes(quickFilter.toLowerCase()))
    setMembers(newFilteredMember)
  }, [quickFilter])

  const columns: GridColDef[] = [
    { field: "name", headerName: translate("label.name"), minWidth: 100, flex: 3 },
    {
      field: "action",
      sortable: false,
      filterable: false,
      headerName: "",
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      maxWidth: 10,
      align: "right",
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        if (params.id === hoveredRowId) {
          return (
            <MemberGridRowMenu
              onClose={() => setHoveredRowId(null)}
              memberId={params.id as string}
              name={params.row.name}
              onUpdateClick={() => {
                setSelectedMember(params.row)
                setIsUpdatePopupOpened(true)
              }}
              onShowEditDialog={() => {
                setSelectedMember(params.row)
                setIsDetailsDialogOpened(true)
              }}
              onDeleteClick={() => {
                setSelectedMember(params.row)
                setIsDeleteMemberDialogOpened(true)
              }}
            />
          )
        } else {
          return null
        }
      }
    },
    { field: "id", headerName: translate("label.id"), minWidth: 100, flex: 3 },
    { field: "email", headerName: translate("label.email"), minWidth: 100, flex: 3 },
    { field: "roles", headerName: translate("label.role"), minWidth: 100, flex: 2 },
    {
      field: "groups",
      headerName: translate("label.groups"),
      minWidth: 100,
      flex: 3,
      renderCell: (params: GridRenderCellParams<any, any, any>) => <GroupGridCell groups={params.row.groups} />
    }
  ]
  return (
    <Stack
      sx={{
        height: "100%"
      }}
    >
      <Stack direction="row" alignItems={"center"} justifyContent="space-between" sx={{ marginBottom: "1rem", marginTop: "2rem" }}>
        <QuickFilterInput value={quickFilter} onChange={setQuickFilter} />
        <Button startIcon={<FontAwesomeIcon className="create-button" icon={regular("plus")} />} onClick={() => setIsInvitationDialogOpened(true)}>
          {translate("organization.invitation.create.title")}
        </Button>
      </Stack>
      <PortalDataGrid
        rowType="member"
        rows={members.map(member => ({ ...member, roles: getFormattedRole(member.roles[0]) }))}
        columns={columns}
        pagination={false}
        className="member-list-data-grid"
        loading={isLoading}
        pageSize={pageSize}
        onPageSizeChange={s => store.dispatch(organizationActionCreator.setPageSize(s))}
        error={hasLoadingError}
        componentsProps={{
          row: rowHandlers
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }]
          }
        }}
      />

      {isDetailsDialogOpened && selectedMember && <MemberDetailsDialog member={selectedMember} onClose={() => setIsDetailsDialogOpened(false)} />}

      {isInvitationDialogOpened && (
        <OrganizationInvitationCreateDialog
          onClose={() => setIsInvitationDialogOpened(false)}
          onCreate={() => {
            setIsInvitationDialogOpened(false)
          }}
        />
      )}

      {isUpdatePopupOpened && selectedMember && (
        <MemberUpdateDialog
          member={selectedMember}
          onClose={() => setIsUpdatePopupOpened(false)}
          onUpdate={() => {
            reloadGrid()
            setIsUpdatePopupOpened(false)
          }}
        />
      )}

      {isDeleteMemberDialogOpened && selectedMember && (
        <MemberDeleteDialog
          member={selectedMember}
          onDelete={() => {
            reloadGrid()
            setIsDeleteMemberDialogOpened(false)
          }}
          onClose={() => setIsDeleteMemberDialogOpened(false)}
        />
      )}
    </Stack>
  )
}
