import { getAllGroups, retrieveRolesFromGroupMembers } from "group/tools/common"
import { getMembersAndRoles } from "member/tools/common"
import { getOrganizationApiKeys } from "organization/tools/apikey"
import * as React from "react"
import { MEMBER_ROLES } from "user/model"
import { JAcl, JAPIKeyAcl, JGroupAcl, JMemberAcl } from "./model"
import { getUsersDataSourcePermissions } from "./utils"

function getOrgRoleAvatar(role: MEMBER_ROLES): string {
  return role[4]
}

export function useSdsAcls(sdsId: string): [JAcl[], React.Dispatch<React.SetStateAction<JAcl[]>>] {
  const [acls, setAcls] = React.useState<JAcl[]>([])

  React.useEffect(() => {
    const getAcls = async () => {
      const [membersAndRoles, fetchedApiKeys, sdsAcls, groups] = await Promise.all([getMembersAndRoles(), getOrganizationApiKeys(), getUsersDataSourcePermissions(sdsId), getAllGroups()])
      const memberAcls: JMemberAcl[] = membersAndRoles.map(m => ({
        ...m,
        roles: m.roles.sort((r1, r2) => getOrgRoleAvatar(r1).localeCompare(getOrgRoleAvatar(r2))), // AEV order
        isAcl: false,
        isSelected: false,
        toBeAdded: false,
        isDirty: false,
        principal: m.email,
        permissions: [],
        inheritedPermissions: [],
        isAPIKey: false,
        isGroup: false
      }))
      const apiKeyAcls: JAPIKeyAcl[] = fetchedApiKeys.map(k => ({
        ...k,
        name: k.title,
        roles: k.roles.sort((r1, r2) => getOrgRoleAvatar(r1).localeCompare(getOrgRoleAvatar(r2))), // AEV order
        isAcl: false,
        isSelected: false,
        toBeAdded: false,
        isDirty: false,
        principal: k.email,
        permissions: [],
        inheritedPermissions: [],
        isAPIKey: true,
        isGroup: false
      }))
      const groupsAcls: JGroupAcl[] = groups.map(g => ({
        ...g,
        roles: retrieveRolesFromGroupMembers(g, membersAndRoles),
        isAcl: false,
        isSelected: false,
        toBeAdded: false,
        isDirty: false,
        principal: g.email,
        permissions: [],
        inheritedPermissions: [],
        isAPIKey: false,
        isGroup: true
      }))
      const membersAndApiKeysAndGroupsAcls: JAcl[] = [...memberAcls, ...apiKeyAcls, ...groupsAcls]
      const sdsAclsByPrincipal = Object.fromEntries(sdsAcls.map(a => [a.principal, a]))
      const allAcls = membersAndApiKeysAndGroupsAcls
        .map(a => {
          const sdsAcl = sdsAclsByPrincipal[a.principal]
          return sdsAcl ? { ...a, ...sdsAcl, isAcl: true } : a
        })
        .sort((a1, a2) => a1.name.localeCompare(a2.name))
      setAcls(allAcls)
    }
    getAcls()
  }, [])
  return [acls, setAcls]
}
