import { faArrowRightToArc, faArrowsRotate } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Stack, Typography, useTheme } from "@mui/material"
import { translate } from "app/language/service"
import React, { ErrorInfo } from "react"
import logoDark from "resources/images/logo-dark.png"
import logoLight from "resources/images/logo-light.png"

interface ErrorBoundaryProps {
  children: React.ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
    this.handleReload = this.handleReload.bind(this)
    this.handleHome = this.handleHome.bind(this)
  }

  public static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("ErrorBoundary caught an error:", error, errorInfo)
  }

  public handleReload() {
    window.location.reload()
  }

  public handleHome() {
    window.location.href = "/"
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorBoundaryMessage handleHome={this.handleHome} handleRefresh={this.handleReload} />
    }

    return this.props.children
  }
}

interface Props {
  handleRefresh: () => void
  handleHome: () => void
}

const ErrorBoundaryMessage = (props: Props) => {
  const theme = useTheme()

  return (
    <Stack alignItems={"center"} justifyContent={"center"} sx={{ position: "relative", height: "100%", width: "100%" }}>
      <Stack flexDirection={"row"} justifyContent={"center"} gap={"3.5rem"} sx={{ width: "100%" }}>
        <Box
          sx={{
            width: "242px",
            height: "148px",
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${theme.palette.mode === "dark" ? logoDark : logoLight})`
          }}
        />
        <Stack gap={"2rem"} sx={{ width: "458px" }}>
          <Typography variant="h4" sx={{ fontSize: "2rem", fontWeight: "600", color: theme.palette.text.primary, lineHeight: "normal" }}>
            {translate("error.boundary.title")}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "1.5rem", color: theme.palette.text.primary, lineHeight: "normal" }}>
            {translate("error.boundary.message")}
          </Typography>
          <Stack justifyContent={"end"} flexDirection={"row"} gap={"0.75rem"}>
            <Button id="jmap-confirm-message-cancel-button" onClick={props.handleHome} color="primary" variant="outlined">
              <Stack flexDirection={"row"} alignItems={"center"} gap={"0.5rem"} sx={{ fontSize: "1rem" }}>
                <FontAwesomeIcon style={{ color: theme.palette.primary.dark }} icon={faArrowRightToArc} size={"lg"} />
                {translate("error.homePage")}
              </Stack>
            </Button>
            <Button onClick={props.handleRefresh} color="primary" variant="contained">
              <Stack flexDirection={"row"} alignItems={"center"} gap={"0.5rem"} sx={{ fontSize: "1rem" }}>
                <FontAwesomeIcon style={{ color: "#FFFFFF" }} icon={faArrowsRotate} size={"sm"} />
                {translate("error.reloadPage")}
              </Stack>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
