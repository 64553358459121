import { regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { DashboardPage } from "dashboard/components/DashboardPage"
import { JobPage } from "job/components/JobPage"
import { OrganizationPage } from "organization/components/OrganizationPage"
import { DASHBOARD_PATH, JOB_PATH, JPage, ORGANIZATION_PATH, PROJECT_PATH, SDS_PATH, STUDIO_PATH } from "page/model"
import { ProjectPage } from "project/components/ProjectPage"
import { DataSourcePage } from "spatialdatasource/components/DataSourcePage"
import { StudioPage } from "studio/component/StudioPage"
import { ROLES } from "user/model"

// no icon means page is not shown in the menu
// ! parent path must indicate a menu that have an icon !
// page titles are stored as translation keys because we cannot call the translation service (which uses the store) during store initialzation
export const getAllPages = (): JPage[] =>
  new Array<JPage>()
    .concat([
      {
        path: DASHBOARD_PATH,
        titleTranslationKey: "page.dashboard.title",
        icon: regular("chart-tree-map"),
        jsxElement: DashboardPage,
        minimumRequiredRole: ROLES.ORG_EDITOR
      },
      {
        path: SDS_PATH,
        titleTranslationKey: "page.sds.title",
        icon: regular("database"),
        jsxElement: DataSourcePage,
        minimumRequiredRole: ROLES.ORG_EDITOR
      },
      {
        path: PROJECT_PATH,
        titleTranslationKey: "page.project.title",
        icon: regular("map"),
        jsxElement: ProjectPage,
        minimumRequiredRole: ROLES.ORG_EDITOR
      },
      {
        path: JOB_PATH,
        titleTranslationKey: "page.job.title",
        icon: regular("list-check"),
        jsxElement: JobPage,
        minimumRequiredRole: ROLES.ORG_EDITOR
      },
      {
        path: STUDIO_PATH,
        titleTranslationKey: "page.studio.title",
        parentPath: PROJECT_PATH,
        jsxElement: StudioPage,
        minimumRequiredRole: ROLES.ORG_EDITOR
      }
    ])
    .concat([
      {
        path: ORGANIZATION_PATH,
        titleTranslationKey: "page.organization.title",
        icon: regular("sitemap"),
        jsxElement: OrganizationPage,
        minimumRequiredRole: ROLES.ORG_ADMIN
      }
    ])
