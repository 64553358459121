import { store } from "app/store/store"
import { ORGANIZATION_TABS } from "organization/model"
import { organizationActionCreator } from "organization/store/actions"

export function setOrganizationActiveTab(tab: ORGANIZATION_TABS): void {
  store.dispatch(organizationActionCreator.setActiveTab(tab))
}

export function setOrganizationSelectedGroupId(groupId: string): void {
  store.dispatch(organizationActionCreator.setSelectedGroupId(groupId))
}
