import { ajaxSVC } from "app/ajax/service"
import { JMember } from "member/model"
import { JSERVER_MICRO_SERVICE_IDS } from "server/model"
import { getMicroServiceBaseUrlById } from "server/tools/common"
import { JGroup, JGroupCreateParams, JGroupMember, JGroupRepository, JGroupUpdateParams } from "./model"

const groupRPO: JGroupRepository = {
  getAllGroups(organizationId: string): Promise<JGroup[]> {
    return new Promise<JGroup[]>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${organizationId}/groups`
          })
          .then((response: any) => resolve(getGroupsFromRaw(response.result)))
          .catch((error: any) => {
            console.error("Error while getting all groups from server", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while getting all groups", ex)
        reject(ex)
      }
    })
  },
  getGroupById(groupId: string, organizationId: string): Promise<JGroup> {
    return new Promise<JGroup>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${organizationId}/groups/${groupId}`
          })
          .then((response: any) => resolve(getGroupFromRaw(response.result)))
          .catch((error: any) => {
            console.error("Error while getting group from server", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while getting group", ex)
        reject(ex)
      }
    })
  },
  updateGroup(groupId: string, organizationId: string, params: JGroupUpdateParams): Promise<JGroup> {
    return new Promise<JGroup>((resolve, reject) => {
      try {
        ajaxSVC
          .patch({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${organizationId}/groups/${groupId}`,
            params: {
              name: params.name
            }
          })
          .then((group: JGroup) => resolve(group))
          .catch((error: any) => {
            console.error("Error while updating group", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while updating group", ex)
        reject(ex)
      }
    })
  },
  deleteGroup(groupId: string, organizationId: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        ajaxSVC
          .del({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${organizationId}/groups/${groupId}`
          })
          .then((id: string) => resolve(id))
          .catch((error: any) => {
            console.error("Error while deleting group", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while deleting group", ex)
        reject(ex)
      }
    })
  },
  createGroup(organizationId: string, params: JGroupCreateParams): Promise<JGroup> {
    return new Promise<JGroup>((resolve, reject) => {
      try {
        ajaxSVC
          .post({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${organizationId}/groups`,
            params: {
              name: params.name
            }
          })
          .then((group: JGroup) => resolve(group))
          .catch((error: any) => {
            console.error("Error while creating group", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while creating group", ex)
        reject(ex)
      }
    })
  },
  deleteGroupMember(groupId: string, organizationId: string, memberId: string): Promise<JGroupMember[]> {
    return new Promise<JGroupMember[]>((resolve, reject) => {
      try {
        ajaxSVC
          .del({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${organizationId}/groups/${groupId}/members/${memberId}`
          })
          .then((members: JGroupMember[]) => resolve(members))
          .catch((error: any) => {
            console.error("Error while deleting group members", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while deleting group members", ex)
        reject(ex)
      }
    })
  },

  addGroupMembers(groupId: string, organizationId: string, emails: string[]): Promise<JGroupMember[]> {
    return new Promise<JGroupMember[]>((resolve, reject) => {
      try {
        ajaxSVC
          .post({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.SECURITY)}/rest/v1/organizations/${organizationId}/groups/${groupId}/members`,
            params: {
              members: emails
            }
          })
          .then((members: JGroupMember[]) => resolve(members))
          .catch((error: any) => {
            console.error("Error while adding group members", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while adding group members", ex)
        reject(ex)
      }
    })
  }
}

function getGroupsFromRaw(rawGroups: any[]): JGroup[] {
  return rawGroups.map(rawGroup => getGroupFromRaw(rawGroup))
}

function getGroupFromRaw(rawGroup: any): JGroup {
  return {
    id: rawGroup.id,
    name: rawGroup.name,
    email: rawGroup.email,
    organizationId: rawGroup.organizationId,
    members: Array.isArray(rawGroup.members) ? getMembersFromRaw(rawGroup.members) : []
  }
}

function getMembersFromRaw(rawMembers: any[]): JMember[] {
  return rawMembers.map(rawMember => getMemberFromRaw(rawMember))
}

function getMemberFromRaw(rawMember: any): JMember {
  return {
    id: rawMember.email,
    name: rawMember.name,
    email: rawMember.email,
    organizationId: rawMember.organizationId,
    roles: Array.isArray(rawMember.roles) ? rawMember.roles : []
  }
}

export default groupRPO
