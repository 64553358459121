import { TextField, useTheme } from "@mui/material"
import { translate } from "app/language/service"
import React from "react"

interface QuickFilterInputProps {
  value: string
  onChange: (value: string) => void
}

export const QuickFilterInput = (props: QuickFilterInputProps) => {
  const theme = useTheme()
  const inputRef = React.useRef<HTMLInputElement>()

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const inputElement = inputRef.current
      if (!inputElement) {
        return
      }

      const activeElement = document.activeElement as HTMLElement
      const isInputOrTextarea = activeElement.tagName === "INPUT" || activeElement.tagName === "TEXTAREA"

      if (event.key === "/" && !isInputOrTextarea) {
        event.preventDefault()
        inputElement.focus()
      }
    }

    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  return (
    <TextField
      value={props.value}
      size="small"
      inputRef={inputRef}
      sx={{
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: `${theme.palette.text.primary}`,
            borderRadius: "5px"
          },
          "&.Mui-focused fieldset": {
            borderColor: `${theme.palette.primary.main}`
          }
        }
      }}
      variant="outlined"
      label={translate("label.search")}
      onChange={e => props.onChange(e.target.value)}
    />
  )
}
