import { GridFilterModel, GridSortModel } from "@mui/x-data-grid"
import { JLocaleTranslation, JMAP_SERVER_DISTANCE_UNITS, JProject, JServerExtent } from "jmapcloud-types"
import { JMember } from "member/model"
import { JApiKey, JExtension } from "organization/model"
import { JDataGridPagedResponse, JDataGridState } from "ui/tools/grid"

export const ALL_SERVER_DISTANCE_UNITS: JMAP_SERVER_DISTANCE_UNITS[] = ["MILLIMETER", "CENTIMETER", "METER", "KILOMETER", "INCH", "FOOT", "YARD", "MILE", "NAUTICAL_MILE"]

export const enum TASK_PROCESS {
  SEED = "VTCS:SEED_MVT_CACHE",
  TRUNCATE = "VTCS:TRUNCATE_MVT_CACHE"
}

export const enum TASK_TYPES {
  FILL = "seed",
  DELETE = "truncate"
}

export const enum TASK_LEVELS {
  ALL = "all-levels",
  SOME = "some-levels"
}

export enum LIST_SORT_DIRECTIONS {
  ASC = "asc",
  DESC = "desc"
}
export const ALL_LIST_SORT_DIRECTIONS: LIST_SORT_DIRECTIONS[] = [LIST_SORT_DIRECTIONS.ASC, LIST_SORT_DIRECTIONS.DESC]

export interface JProjectSubmitValues {
  defaultLanguage: string
  name: JLocaleTranslation
  description: JLocaleTranslation
  mapCrs: string
  mapUnit: JMAP_SERVER_DISTANCE_UNITS
  distanceUnit: JMAP_SERVER_DISTANCE_UNITS
  displayUnit: JMAP_SERVER_DISTANCE_UNITS
  backgroundColor: string
  defaultSelectionColor: string
  rotation: number
  tags?: string[] // tags are only for creation
  public: boolean
  extensions: JExtension[]
}

export interface JProjectRepository {
  get(organizationId: string, page: number, size: number, sortModel: GridSortModel, filterModel: GridFilterModel): Promise<JDataGridPagedResponse<JProject>>
  getProject(organizationId: string, projectId: string): Promise<JProject>
  getLayer(organizationId: string, projectId: string, layerId: string): Promise<any>
  getCount(organizationId: string): Promise<number>
  create(organizationId: string, project: JProjectSubmitValues): Promise<string>
  update(organizationId: string, projectId: string, project: JProjectSubmitValues): Promise<void>
  addTag(organizationId: string, projectId: string, tag: string): Promise<void>
  deleteTag(organizationId: string, projectId: string, tagId: string): Promise<void>
  delete(organizationId: string, projectId: string): Promise<void>
  getPermissionACLs(organizationId: string, projectId: string): Promise<JProjectAcl[]>
  getProjectExtent(organizationId: string, projectId: string): Promise<JServerExtent>
  getUserPermissions(organizationId: string, projectId: string): Promise<PROJECT_PERMISSIONS[]>
  updatePermissionACLs(organizationId: string, projectId: string, acls: JProjectAcl[]): Promise<void>
  getProjectMetrics(organizationId: string, projectId: string): Promise<any>
  getLayerCount(organizationId: string, projectId: string): Promise<number>
}

export interface JProjectState extends JDataGridState {
  displayCards: boolean
  // filter: string
  // listSort: LIST_SORT_DIRECTIONS
  isFormDialogOpen: boolean
  savedProject: JProject | null
  projectForWhichToEditPermissions: JProject | null
  projectForWhichToManageMvtCache: JProject | null
  isLoading: boolean
  quickFilter: string
}

export enum PROJECT_PERMISSIONS {
  OWNER = "OWNER",
  MODIFY = "MODIFY",
  VIEW = "VIEW"
}

export interface JProjectAcl {
  principal: string
  permissions: PROJECT_PERMISSIONS[]
  inheritedPermissions: JInheritedPermissions[]
}

export interface JInheritedPermissions {
  permission: PROJECT_PERMISSIONS
  fromResourceType: string
  fromResourceId: string
}

// This element type is meant to model and maintain a single list across both the ACL table and the autocomplete
// widget. It combines the member infos (name and roles), the project ACLs and extra bookkeeping values (selection,
// dirtiness, etc).
export interface JMemberAcl extends JMember, JProjectAcl {
  isAcl: boolean // whether it's a table row or an autocomplete option
  isSelected: boolean
  toBeAdded: boolean // whether it's an autocomplete chip (waiting to be made an ACL)
  isDirty: boolean // whether it needs to be updated (when saving)
  isAPIKey: boolean
}

export interface JAPIKeyAcl extends JApiKey, JProjectAcl {
  name: string
  isAcl: boolean // whether it's a table row or an autocomplete option
  isSelected: boolean
  toBeAdded: boolean // whether it's an autocomplete chip (waiting to be made an ACL)
  isDirty: boolean // whether it needs to be updated (when saving)
  isAPIKey: boolean
}

export type JAcl = JMemberAcl | JAPIKeyAcl
