import { light } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material"
import { Box, Stack } from "@mui/system"
import { useErrorHandling } from "app/hook"
import { ALL_LOCALES } from "app/language/model"
import { languageSVC, translate } from "app/language/service"
import { ColorField } from "form/component/ColorField"
import { JLocaleTranslation, JMAP_SERVER_DISTANCE_UNITS, JProject, LOCALES } from "jmapcloud-types"
import { useTags } from "organization/hooks"
import { JExtension } from "organization/model"
import { getExtensionConfiguration, getOrganizationActiveExtensions, updateExtensionConfiguration } from "organization/tools/common"
import { ALL_SERVER_DISTANCE_UNITS, JProjectSubmitValues, PROJECT_PERMISSIONS } from "project/model"
import { addTagToProject, copyProjectPublicUrlToClipboard, createProject, deleteTagFromProject, getUserProjectPermissions, naturalSortString, updateProject } from "project/utils"
import { JProjection } from "projection/model"
import { projectionSVC } from "projection/service"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { StatusChip } from "ui/components/StatusChip"
import { STATUS_CHIP_LEVELS } from "ui/model"
import { isCurrentUserAtLeastOrgAdmin } from "user/tools/permissions"

interface JProjectFormDialogProps {
  project: JProject | null // project to update, if null will create
  onClose(): any
  onCreate(): any
}

// This is the type of a project when it's being manipulated inside the form
interface JProjectFormValues {
  currentLanguage: LOCALES
  defaultLanguage: LOCALES
  name: JLocaleTranslation
  description: JLocaleTranslation
  backgroundColor: string
  defaultSelectionColor: string
  mapUnit: JMAP_SERVER_DISTANCE_UNITS
  distanceUnit: JMAP_SERVER_DISTANCE_UNITS // label is Measurement Unit
  displayUnit: JMAP_SERVER_DISTANCE_UNITS
  mapCrs: JProjection
  rotation: number
  tags: string[]
  public: boolean
  extensions: JExtension[]
}

export const ProjectFormDialog = (props: JProjectFormDialogProps): JSX.Element => {
  const [userIsProjectOwner, setUserIsProjectOwner] = React.useState(false)
  const [userIsOrgAdmin] = React.useState(isCurrentUserAtLeastOrgAdmin())
  const [extensionsIsLoading, setExtensionsIsLoading] = React.useState(false)

  const existingTags = useTags()

  const [initialExtensions, setInitialExtensions] = React.useState<JExtension[]>([])
  const theme = useTheme()
  React.useEffect(() => {
    const fetchExtensions = async () => {
      setExtensionsIsLoading(true)
      try {
        const activeExtensions = await getOrganizationActiveExtensions()
        if (!project) {
          const extensionsWithConfig = activeExtensions
            .map(ext => ({
              ...ext,
              enabled: false,
              configuration: {}
            }))
            .sort((a, b) => naturalSortString(a.name, b.name))
          setValue("extensions", extensionsWithConfig)
          setInitialExtensions(extensionsWithConfig)
        } else {
          const extensionConfigurationPromiseArray = activeExtensions.map(ext => getExtensionConfiguration(props.project!.id, ext.id))
          const extensionConfigurations = await Promise.all(extensionConfigurationPromiseArray)
          const extensionsWithConfig = activeExtensions
            .map((ext, index) => ({
              ...ext,
              ...extensionConfigurations[index]
            }))
            .sort((a, b) => naturalSortString(a.name, b.name))
          setValue("extensions", extensionsWithConfig)
          setInitialExtensions(extensionsWithConfig)
        }
      } catch (err) {
        console.error(err)
      }
      setExtensionsIsLoading(false)
    }
    fetchExtensions()
  }, [])
  let defaultValues: JProjectFormValues
  const { project } = props
  const { hasError, errorMessage, handleError, resetError } = useErrorHandling(translate(project ? "project.update.submit.error" : "project.create.submit.error"))
  if (project) {
    // UPDATE
    defaultValues = {
      currentLanguage: project.defaultLanguage,
      defaultLanguage: project.defaultLanguage,
      name: { ...Object.fromEntries(ALL_LOCALES.map(lang => [lang, ""])), ...project.name },
      description: { ...Object.fromEntries(ALL_LOCALES.map(lang => [lang, ""])), ...project.description },
      backgroundColor: project.backgroundColor,
      defaultSelectionColor: project.defaultSelectionColor,
      mapUnit: project.mapUnit,
      distanceUnit: project.distanceUnit, // label is Measurement Unit
      displayUnit: project.displayUnit,
      mapCrs: projectionSVC.getProjections().find(proj => proj.code === project.mapCrs),
      rotation: project.rotation,
      tags: project.tags.map(tag => tag.name),
      public: project.public,
      extensions: []
    }
  } else {
    // CREATE
    defaultValues = {
      currentLanguage: languageSVC.getLocale(),
      defaultLanguage: languageSVC.getLocale(),
      name: Object.fromEntries(ALL_LOCALES.map(lang => [lang, ""])),
      description: Object.fromEntries(ALL_LOCALES.map(lang => [lang, ""])),
      backgroundColor: "#ffffff", // white
      defaultSelectionColor: "#ffeda2", // baby chick
      mapUnit: "METER",
      distanceUnit: "METER", // label is Measurement Unit
      displayUnit: "METER",
      mapCrs: projectionSVC.getProjections().find(proj => proj.code === "EPSG:3857"),
      rotation: 0,
      tags: [],
      public: false,
      extensions: []
    }
  }

  React.useEffect(() => {
    if (!project) {
      return
    }
    getUserProjectPermissions(project.id).then(permissions => {
      setUserIsProjectOwner(permissions.includes(PROJECT_PERMISSIONS.OWNER))
    })
  }, [project])

  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<JProjectFormValues>({
    defaultValues
  })

  const onSubmit = async (values: JProjectFormValues) => {
    if (hasError) {
      resetError()
    }

    const submitValues: JProjectSubmitValues = {
      defaultLanguage: values.defaultLanguage,
      name: values.name,
      description: values.description,
      mapCrs: values.mapCrs.code,
      mapUnit: values.mapUnit,
      distanceUnit: values.distanceUnit,
      displayUnit: values.displayUnit,
      backgroundColor: values.backgroundColor,
      defaultSelectionColor: values.defaultSelectionColor,
      rotation: values.rotation,
      public: values.public,
      extensions: values.extensions
    }

    const updatedExtensions = values.extensions.filter(ext => {
      const initialExt = initialExtensions.find(e => e.id === ext.id)
      return initialExt.enabled !== ext.enabled
    })
    if (project) {
      await updateProject(project.id, submitValues)
        .then(async () => {
          // If any fresh tag is not found in the project tags, add it
          values.tags.forEach(tagString => {
            if (!project.tags.find(t => t.name === tagString)) {
              addTagToProject(project.id, tagString)
            }
          })
          // If any project tag is not found in the fresh tags, delete it
          project.tags.forEach(t => {
            if (!values.tags.find(tagString => tagString === t.name)) {
              deleteTagFromProject(project.id, t.id)
            }
          })

          try {
            const updatedExtensionPromises = updatedExtensions.map(ext => updateExtensionConfiguration(project.id, ext.id, { enabled: ext.enabled }))
            await Promise.all(updatedExtensionPromises)
            return props.onCreate ? props.onCreate() : props.onClose()
          } catch (error: any) {
            console.error(error)
            handleError(error)
          }
        })
        .catch(error => {
          console.error(error)
          handleError(error)
        })
    } else {
      const createValues: JProjectSubmitValues = {
        ...submitValues,
        tags: values.tags
      }

      await createProject(createValues)
        .then(async projectId => {
          try {
            const updatedExtensionPromises = updatedExtensions.map(ext => updateExtensionConfiguration(projectId, ext.id, { enabled: ext.enabled }))
            await Promise.all(updatedExtensionPromises)
          } catch (error: any) {
            console.error(error)
            handleError(error)
          }
          return props.onCreate ? props.onCreate() : props.onClose()
        })
        .catch(error => {
          console.error(error)
          handleError(error)
        })
    }
  }

  return (
    <Dialog maxWidth="md" fullWidth open scroll="paper">
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Stack direction="row" sx={{ justifyContent: "space-between" }}>
          {translate("label.project")}
        </Stack>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
          <Grid container spacing={4}>
            {/* Row ------------------------------------------------------------------------------------------ */}

            {/* Name  */}
            <Grid item xs={6}>
              <Controller
                control={control}
                name="name"
                rules={{
                  validate: v => {
                    const defaultLang = getValues("defaultLanguage")
                    if (!v[defaultLang]) {
                      return translate("label.name.translation.missing.error", {
                        locale: translate(`user.locale.${defaultLang}`)
                      })
                    }
                    return true
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    error={errors.name !== undefined}
                    helperText={<>{errors.name?.message}</>}
                    type="text"
                    label={translate("label.name")}
                    value={watch("name")[getValues("currentLanguage")]}
                    fullWidth
                    onChange={event => {
                      const name = {
                        ...getValues("name"),
                        [getValues("currentLanguage")]: event.target.value
                      }
                      field.onChange(name)
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LanguageChipMenu
                            label={translate(`user.locale.${watch("currentLanguage")}`)}
                            items={ALL_LOCALES.map(lang => ({
                              label: translate(`user.locale.${lang}`),
                              isDefault: lang === watch("defaultLanguage"),
                              onClick: () => {
                                setValue("currentLanguage", lang)
                              }
                            }))}
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />
            </Grid>

            {/* Default language */}
            <Grid item xs={3}>
              <Controller
                control={control}
                name="defaultLanguage"
                rules={{ required: { value: true, message: translate("label.field.required") } }}
                render={({ field }) => (
                  <TextField {...field} required label={translate("label.default.language")} value={getValues("defaultLanguage")} fullWidth select>
                    {ALL_LOCALES.map(lang => (
                      <MenuItem key={lang} value={lang}>
                        {translate(`user.locale.${lang}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            {/* Public  */}

            <Grid item xs={3}>
              <Stack direction="row" alignItems={"center"}>
                <Controller
                  control={control}
                  name="public"
                  render={({ field }) => (
                    <Tooltip title={props.project && !userIsProjectOwner ? translate("project.public.must.be.owner.message") : ""}>
                      <FormControlLabel
                        sx={{ marginRight: 0 }}
                        control={<Checkbox {...{ ...field, checked: field.value, disabled: props.project != null && !userIsProjectOwner }} />}
                        label={translate("project.public.label")}
                      />
                    </Tooltip>
                  )}
                />
                {watch("public") && project !== null && (
                  <Tooltip title={translate("project.public.copyUrl.tooltip")}>
                    <IconButton
                      size="small"
                      onClick={() => {
                        copyProjectPublicUrlToClipboard(project)
                      }}
                    >
                      <FontAwesomeIcon style={{ aspectRatio: "1/1" }} icon={light("link")} />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </Grid>

            {/* Row ------------------------------------------------------------------------------------------ */}

            {/* Map CRS */}
            <Grid item xs={6}>
              <Controller
                control={control}
                name="mapCrs"
                rules={{ required: { value: true, message: translate("label.field.required") } }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    options={projectionSVC.getProjections()}
                    onChange={(event, value) => field.onChange(value)}
                    ListboxProps={{ style: { maxHeight: 200 } }}
                    isOptionEqualToValue={(option, value) => option.code === value.code}
                    renderInput={params => <TextField {...params} required error={errors.mapCrs !== undefined} helperText={errors.mapCrs?.message} label={translate("label.map.crs")} />}
                  />
                )}
              />
            </Grid>

            {/* Map unit */}
            <Grid item xs={3}>
              <Controller
                control={control}
                name="mapUnit"
                rules={{ required: { value: true, message: translate("label.field.required") } }}
                render={({ field }) => (
                  <TextField {...field} required label={translate("label.map.unit")} fullWidth error={errors.mapUnit !== undefined} helperText={errors.mapUnit?.message} select>
                    {ALL_SERVER_DISTANCE_UNITS.map(unit => (
                      <MenuItem key={unit} value={unit}>
                        {translate(unit)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            {/* Display unit */}
            <Grid item xs={3}>
              <Controller
                control={control}
                name="displayUnit"
                rules={{ required: { value: true, message: translate("label.field.required") } }}
                render={({ field }) => (
                  <TextField {...field} required label={translate("label.display.unit")} fullWidth error={errors.displayUnit !== undefined} helperText={errors.displayUnit?.message} select>
                    {ALL_SERVER_DISTANCE_UNITS.map(unit => (
                      <MenuItem key={unit} value={unit}>
                        {translate(unit)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            {/* Row ------------------------------------------------------------------------------------------ */}

            {/* Background color */}
            <Grid item xs={3}>
              <Controller
                control={control}
                name="backgroundColor"
                rules={{ required: { value: true, message: translate("label.field.required") } }}
                render={({ field }) => <ColorField {...field} required id="project-form-backgroundColor" title={translate("label.background.color")} />}
              />
            </Grid>

            {/* Selection color */}
            <Grid item xs={3}>
              <Controller
                control={control}
                name="defaultSelectionColor"
                rules={{ required: { value: true, message: translate("label.field.required") } }}
                render={({ field }) => <ColorField {...field} required id="project-form-defaultSelectionColor" title={translate("label.selection.color")} />}
              />
            </Grid>

            {/* Measurement / distance unit */}
            <Grid item xs={3}>
              <Controller
                control={control}
                name="distanceUnit"
                rules={{ required: { value: true, message: translate("label.field.required") } }}
                render={({ field }) => (
                  <TextField {...field} required label={translate("label.measurement.unit")} fullWidth error={errors.distanceUnit !== undefined} helperText={errors.distanceUnit?.message} select>
                    {ALL_SERVER_DISTANCE_UNITS.map(unit => (
                      <MenuItem key={unit} value={unit}>
                        {translate(unit)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            {/* Rotation */}
            <Grid item xs={3}>
              <Controller
                control={control}
                name="rotation"
                rules={{
                  min: { value: 0, message: translate("label.rotation.validation.error") },
                  max: { value: 360, message: translate("label.rotation.validation.error") }
                }}
                render={({ field }) => (
                  <TextField {...field} required label={translate("label.initial.rotation")} type="number" error={errors.rotation !== undefined} helperText={errors.rotation?.message} />
                )}
              />
            </Grid>

            {/* Row ------------------------------------------------------------------------------------------ */}

            {/* Description */}
            <Grid item xs={6}>
              <Controller
                control={control}
                name="description"
                render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    type="text"
                    label={translate("label.description")}
                    value={watch("description")[getValues("currentLanguage")]}
                    fullWidth
                    onChange={event => {
                      const desc = {
                        ...getValues("description"),
                        [getValues("currentLanguage")]: event.target.value
                      }
                      field.onChange(desc)
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LanguageChipMenu
                            label={translate(`user.locale.${watch("currentLanguage")}`)}
                            items={ALL_LOCALES.map(lang => ({
                              label: translate(`user.locale.${lang}`),
                              isDefault: lang === watch("defaultLanguage"),
                              onClick: () => {
                                setValue("currentLanguage", lang)
                              }
                            }))}
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />
            </Grid>

            {/* Tags */}
            <Grid item xs={6}>
              <Controller
                control={control}
                name="tags"
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    options={existingTags.map(t => t.name)}
                    disableListWrap
                    onChange={(_event, tags) => field.onChange(tags)}
                    renderInput={params => <TextField {...params} label={translate("label.tags")} />}
                    multiple
                    freeSolo
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => <StatusChip label={option} level={STATUS_CHIP_LEVELS.NEUTRAL} {...getTagProps({ index })} />)
                    }
                  />
                )}
              />
            </Grid>

            {/* Extensions */}
            <Grid item xs={4}>
              <Typography variant="subtitle1" sx={{ color: theme.palette.text.secondary }}>
                {translate("organization.tab.extension")}
              </Typography>
              <Stack direction={"column"} marginTop={"1rem"} gap={1}>
                {extensionsIsLoading ? (
                  <CircularProgress sx={{ marginLeft: "1rem" }} size={20} />
                ) : getValues("extensions").length === 0 ? (
                  <Typography>{translate("organization.extension.none.activated")}</Typography>
                ) : (
                  getValues("extensions").map((ext, index) => (
                    <Controller
                      key={ext.id}
                      control={control}
                      name={`extensions.${index}.enabled`}
                      render={({ field }) => (
                        <Grid container alignItems="center">
                          <Tooltip title={userIsOrgAdmin ? "" : translate("project.public.must.be.administrator.message.extensions")}>
                            <Grid item>
                              <Checkbox
                                sx={{ padding: 0, marginRight: "0.5rem" }}
                                {...field}
                                checked={field.value}
                                disabled={!userIsOrgAdmin}
                                onChange={event => {
                                  const isChecked = event.target.checked
                                  field.onChange(isChecked)
                                }}
                              />
                            </Grid>
                          </Tooltip>
                          <Grid item xs zeroMinWidth>
                            <Typography
                              noWrap
                              sx={{
                                width: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                              }}
                            >
                              {ext.name}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Tooltip title={translate("label.edit")} placement="top">
                              <span>
                                <IconButton sx={{ padding: 0 }} color="inherit" size="medium" disabled>
                                  <FontAwesomeIcon icon={light("square-sliders-vertical")} />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      )}
                    />
                  ))
                )}
              </Stack>
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        {hasError ? (
          <Typography color="error" sx={{ marginLeft: "0.5em" }}>
            {errorMessage}
          </Typography>
        ) : (
          <div />
        )}
        <Stack direction="row" alignItems="center" spacing={1}>
          {isSubmitting && <CircularProgress size={20} />}
          <Button variant="outlined" disabled={isSubmitting} onClick={props.onClose}>
            {translate("button.cancel")}
          </Button>
          <Button type="submit" disabled={isSubmitting} onClick={() => handleSubmit(onSubmit)()}>
            {translate(project ? "button.update" : "button.create")}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

interface JLanguageChipMenuItem {
  label: string
  isDefault: boolean
  onClick: () => void
}

const LanguageChipMenu = ({ label, items }: { label: string; items: JLanguageChipMenuItem[] }): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Chip
        label={label}
        size="small"
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget)
        }}
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {items.map(item => (
          <MenuItem
            key={item.label}
            onClick={() => {
              item.onClick()
              handleClose()
            }}
          >
            <Stack direction="row">
              <Box>{item.label}</Box>
              {item.isDefault && (
                <Box display="flex" alignItems="center" paddingLeft="4rem">
                  <Typography color="textSecondary" fontSize="0.8rem">
                    {translate("label.default")}
                  </Typography>
                </Box>
              )}
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
