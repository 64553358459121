import { ACTIONS } from "app/store/actions"
import { ORGANIZATION_TABS } from "organization/model"
import { Action } from "redux"

export interface JOrganizationActionSetActiveTab extends Action {
  activeTab: ORGANIZATION_TABS
}
export interface JOrganizationActionSetPageSize extends Action {
  pageSize: number
}

export interface JOrganizationActionSetSelectedGroupId extends Action {
  selectedGroupId: string
}

export const organizationActionCreator = {
  setActiveTab(activeTab: ORGANIZATION_TABS) {
    return {
      type: ACTIONS.ORGANIZATION_SET_ACTIVE_TAB,
      activeTab
    }
  },
  setPageSize(pageSize: number) {
    return {
      type: ACTIONS.ORGANIZATION_SET_PAGE_SIZE,
      pageSize
    }
  },
  setSelectedGroupId(selectedGroupId: string) {
    return {
      type: ACTIONS.ORGANIZATION_SET_SELECTED_GROUP_ID,
      selectedGroupId
    }
  }
}
