import { light } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PersonRemoveAlt1 from "@mui/icons-material/PersonRemoveAlt1"
import {
  Autocomplete,
  Avatar,
  AvatarGroup,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material"
import { Box } from "@mui/system"
import { useErrorHandling } from "app/hook"
import { translate } from "app/language/service"
import { useAppSelector } from "app/store/hooks"
import { store } from "app/store/store"
import React from "react"
import { useSdsAcls } from "spatialdatasource/hook"
import {
  DATA_SOURCE_PERMISSIONS,
  DATA_SOURCE_PERMISSIONS_GROUP,
  DATA_SOURCE_PERMISSIONS_RASTER_UI,
  DATA_SOURCE_PERMISSIONS_VECTOR_UI,
  DATA_SOURCE_TYPES,
  JAcl,
  JDataSource,
  PERMISSIONS
} from "spatialdatasource/model"
import { setSdsPermissionDialog } from "spatialdatasource/store"
import { updateUsersDataSourcePermissions } from "spatialdatasource/utils"
import { PermissionCheckbox } from "ui/components/PermissionCheckbox"
import { MEMBER_ROLES, ROLES } from "user/model"
import { rolesIncludeMinimumMemberRole } from "user/tools/permissions"

const OrgRoleChip = (props: any) => (
  <Chip
    size="small"
    sx={{
      color: "black",
      boxSizing: "border-box",
      border: "1px solid",
      borderColor: "#bddaff",
      paddingLeft: `${(props.roles.length - 1) * 0.6}rem`
    }}
    avatar={
      <AvatarGroup>
        {props.roles.map((r: MEMBER_ROLES) => (
          <Avatar key={getOrgRoleAvatar(r)} sx={{ width: 14, height: 14 }}>
            <span style={{ fontSize: "small" }}>{getOrgRoleAvatar(r)}</span>
          </Avatar>
        ))}
      </AvatarGroup>
    }
    {...props}
  ></Chip>
)

// Role -> A/E/V single letter
function getOrgRoleAvatar(role: MEMBER_ROLES): string {
  return role[4]
}

export const DataSourcePermissionDialog = ({ sds }: { sds: JDataSource }) => {
  const [acls, setAcls] = useSdsAcls(sds.id)
  const [isSaving, setIsSaving] = React.useState(false)
  const [sdsUIType, setSdsUIType] = React.useState(DATA_SOURCE_PERMISSIONS_VECTOR_UI)
  const { hasError, errorMessage, handleError, resetError } = useErrorHandling()

  React.useEffect(() => {
    if (sds.type === DATA_SOURCE_TYPES.RASTER || sds.type === DATA_SOURCE_TYPES.WMS_WMTS) {
      setSdsUIType(DATA_SOURCE_PERMISSIONS_RASTER_UI)
    }
  }, [sds.type])
  //
  // Derived from state
  const aclsWithOwnerAreAllSelected =
    acls.filter(a => a.isAcl && a.permissions.includes(DATA_SOURCE_PERMISSIONS.OWNER) && !a.isAPIKey).length ===
    acls.filter(a => a.isAcl && a.permissions.includes(DATA_SOURCE_PERMISSIONS.OWNER) && a.isSelected && !a.isAPIKey).length

  const theme = useAppSelector(state => state.ui.theme)

  const handleSave = async () => {
    if (hasError) {
      resetError()
    }

    const aclsToUpdate = acls.filter(a => a.isDirty).map(a => ({ principal: a.principal, permissions: a.permissions }))
    if (aclsToUpdate.length > 0) {
      setIsSaving(true)
      try {
        await updateUsersDataSourcePermissions(sds.id, aclsToUpdate)
        setIsSaving(false)
      } catch (error: any) {
        setIsSaving(false)
        handleError(error)
        return
      }
    }
    store.dispatch(setSdsPermissionDialog(null))
  }

  const handlePermissionUpdate = (acl: JAcl, permission: DATA_SOURCE_PERMISSIONS, permissionType: DATA_SOURCE_PERMISSIONS_GROUP, selected: boolean) => {
    // Check the permission exists
    const index = PERMISSIONS[permissionType].indexOf(permission)
    if (index === -1) {
      return
    }

    setAcls(
      acls.map(a => {
        if (a.principal === acl.principal) {
          let newPermissions = []
          if (selected) {
            if (permissionType === DATA_SOURCE_PERMISSIONS_GROUP.EDITOR) {
              // Permissions (view, modify, owner) comes with the lower permissions (e.g. EDITOR comes with VIEW)
              newPermissions = PERMISSIONS[permissionType].slice(0, index + 1)
            } else if (permissionType === DATA_SOURCE_PERMISSIONS_GROUP.VIEWER_RASTER || permissionType === DATA_SOURCE_PERMISSIONS_GROUP.VIEWER_VECTOR) {
              // Viewer permissions comes with the permission to view the data source
              newPermissions.push(DATA_SOURCE_PERMISSIONS.VIEW)
              newPermissions.push(permission)
            }

            newPermissions = [...a.permissions, ...newPermissions]
            // remove duplicates permissions
            const noduplocatePermissions = new Set(newPermissions)
            newPermissions = Array.from(noduplocatePermissions)
          } else {
            // unselect
            newPermissions = a.permissions.filter(p => p !== permission)
          }
          return {
            ...a,
            permissions: newPermissions,
            isDirty: true
          }
        }
        return a
      })
    )
  }

  const handleDeleteSelectedAcls = () => {
    const selectedPrincipals = acls.filter(a => a.isSelected).map(a => a.principal)
    setAcls(
      acls.map(a =>
        selectedPrincipals.includes(a.principal)
          ? {
              ...a,
              isAcl: false, // return item to dropdown
              isSelected: false,
              isDirty: true, // mark for update
              permissions: [] // will delete
            }
          : a
      )
    )
  }

  const isPermissionCheckboxEnabled = (permission: DATA_SOURCE_PERMISSIONS, acl: JAcl, permissionType: DATA_SOURCE_PERMISSIONS_GROUP) => {
    // cannot remove OWNER if there is only one OWNER
    if (permission === DATA_SOURCE_PERMISSIONS.OWNER) {
      const ownerAcls = acls.filter(a => a.permissions.includes(DATA_SOURCE_PERMISSIONS.OWNER) && !a.isAPIKey)
      if (ownerAcls.length === 1 && ownerAcls[0].principal === acl.principal) {
        return false
      }
    }
    // If not ORG_EDITOR, cannot modify OWNER or MODIFY
    if (!rolesIncludeMinimumMemberRole(acl.roles, ROLES.ORG_EDITOR) && [DATA_SOURCE_PERMISSIONS.OWNER, DATA_SOURCE_PERMISSIONS.MODIFY].includes(permission)) {
      return false
    }
    // remove higher permissions before removing lower permissions (e.g. EDITOR before VIEW) (view, modify, owner)
    if (permissionType === DATA_SOURCE_PERMISSIONS_GROUP.EDITOR) {
      const index = PERMISSIONS[permissionType].indexOf(permission)
      if (acl.permissions.includes(PERMISSIONS[permissionType][index + 1])) {
        return false
      }
    }
    // viewer cannot be removed if other permissions are set
    if (permission === DATA_SOURCE_PERMISSIONS.VIEW) {
      if (acl.permissions.length > 1) {
        return false
      }
    }
    return true
  }

  return (
    <Dialog open aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="xl">
      <DialogTitle id="form-dialog-title">
        {translate("project.permissions")} - {sds.name}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} style={{ borderBottomWidth: 0 }}>
                      {/* N members selected, and remove button */}
                      <Stack alignItems="center" direction="row" spacing={2}>
                        {acls.filter(a => a.isSelected).length > 0 && (
                          <>
                            <Typography style={{ marginLeft: 5, marginBottom: 0 }} variant="body2" gutterBottom>
                              {translate("project.permissions.members.selected", {
                                numSelected: acls.filter(a => a.isSelected).length
                              })}
                            </Typography>
                            <IconButton color="primary" onClick={handleDeleteSelectedAcls} disabled={aclsWithOwnerAreAllSelected}>
                              <PersonRemoveAlt1 />
                            </IconButton>
                            {aclsWithOwnerAreAllSelected && (
                              <>
                                <Box sx={{ marginLeft: "0.25rem" }}>
                                  <FontAwesomeIcon color={theme.palette.warning.main} icon={light("triangle-exclamation")} />
                                </Box>
                                <Typography style={{ marginLeft: 5, marginBottom: 0 }} variant="body2" gutterBottom>
                                  {translate("project.permissions.members.selected.at.least.one.owner")}
                                </Typography>
                              </>
                            )}
                          </>
                        )}
                      </Stack>
                    </TableCell>
                    {/* Permission roles */}
                    {sdsUIType.header.map(header => (
                      <React.Fragment key={header.label}>
                        <TableCell align="center" colSpan={header.colspan} style={{ borderBottomWidth: 2, borderBottomColor: theme.palette.text.secondary }}>
                          <Typography variant="overline">{translate(header.label)}</Typography>
                        </TableCell>
                        <TableCell colSpan={1} style={{ borderBottomWidth: 0 }}></TableCell>
                      </React.Fragment>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell padding="checkbox">
                      {/* Select all checkboxes */}
                      <Checkbox
                        color="primary"
                        onChange={e => {
                          if (e.target.checked) {
                            setAcls(acls.map(a => ({ ...a, isSelected: a.isAcl })))
                          } else {
                            setAcls(acls.map(a => ({ ...a, isSelected: false })))
                          }
                        }}
                        checked={acls.filter(a => a.isSelected).length === acls.filter(a => a.isAcl).length}
                      />
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>{translate("project.permissions.member.name")}</TableCell>
                    {/* Permission name */}
                    {Object.keys(sdsUIType.permissions).map((permissionGroup, index) => (
                      <React.Fragment key={index}>
                        {sdsUIType.permissions[permissionGroup].map((permission, indexx) => (
                          <TableCell key={indexx} align="center" style={{ fontWeight: "bold" }}>
                            {translate(permission[0])}
                          </TableCell>
                        ))}
                        <TableCell align="center" style={{ fontWeight: "bold" }}></TableCell>
                      </React.Fragment>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {acls.length === 0 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={6}>
                        <LinearProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    acls
                      .filter(a => a.isAcl)
                      .map(acl => {
                        const inheritedPermissions = acl.inheritedPermissions.map(p => p.permission)
                        return (
                          <TableRow key={acl.principal} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <TableCell padding="checkbox">
                              {/* Select ACL checkbox */}
                              <Checkbox
                                color="primary"
                                onChange={e =>
                                  setAcls(
                                    acls.map(a => ({
                                      ...a,
                                      isSelected: a.principal === acl.principal ? e.target.checked : a.isSelected
                                    }))
                                  )
                                }
                                checked={acl.isSelected}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Stack direction="row" gap={2} alignItems="center">
                                {acl.isAPIKey ? <FontAwesomeIcon icon={light("key-skeleton")} /> : <FontAwesomeIcon icon={light("user")} />}
                                <Grid container spacing={2}>
                                  {acl.name !== acl.principal ? (
                                    <>
                                      {/* Show name and principal */}
                                      <Grid item xs={12}>
                                        <Stack alignItems="center" direction="row" spacing={2}>
                                          {acl.name}
                                          <AvatarGroup sx={{ paddingLeft: "0.5rem" }}>
                                            {acl.roles.map(role => (
                                              <Avatar key={role} sx={{ width: 16, height: 16 }}>
                                                <span style={{ fontSize: "small" }}>{getOrgRoleAvatar(role)}</span>
                                              </Avatar>
                                            ))}
                                          </AvatarGroup>
                                        </Stack>
                                      </Grid>
                                      <Grid item xs={12} style={{ paddingTop: 0 }}>
                                        {acl.principal}
                                      </Grid>
                                    </>
                                  ) : (
                                    // Show principal only
                                    <Grid item xs={12}>
                                      <Stack alignItems="center" direction="row" spacing={2}>
                                        {acl.principal}
                                        <AvatarGroup sx={{ paddingLeft: "0.5rem" }}>
                                          {acl.roles.map(role => (
                                            <Avatar key={role} sx={{ width: 16, height: 16 }}>
                                              <span style={{ fontSize: "small" }}>{getOrgRoleAvatar(role)}</span>
                                            </Avatar>
                                          ))}
                                        </AvatarGroup>
                                      </Stack>
                                    </Grid>
                                  )}
                                </Grid>
                              </Stack>
                            </TableCell>
                            {/* Permissions checkboxes */}
                            {Object.keys(sdsUIType.permissions).map((permissionGroup, index) => (
                              <React.Fragment key={index}>
                                {sdsUIType.permissions[permissionGroup].map((permission, indexKey: number) => {
                                  const isInheritedPermission = inheritedPermissions.includes(permission[1])
                                  return (
                                    <PermissionCheckbox
                                      key={indexKey}
                                      isInherited={isInheritedPermission}
                                      onChange={e => handlePermissionUpdate(acl, permission[1], permission[2], e.target.checked)}
                                      checked={acl.permissions.includes(permission[1])}
                                      disabled={!isPermissionCheckboxEnabled(permission[1], acl, permission[2])}
                                    />
                                  )
                                })}
                                <TableCell align="center" style={{ fontWeight: "bold" }}></TableCell>
                              </React.Fragment>
                            ))}
                          </TableRow>
                        )
                      })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={9}>
            <Stack alignItems="center" justifyContent="flex-start" direction="row" spacing={2}>
              <Autocomplete
                multiple
                limitTags={3}
                value={acls.filter(a => a.toBeAdded)}
                id="multiple-limit-tags"
                options={acls.filter(a => !a.isAcl)}
                getOptionLabel={option => option.name}
                renderInput={params => <TextField {...params} label={translate("project.permissions.member.add")} />}
                renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => <OrgRoleChip {...getTagProps({ index })} label={option.name} roles={option.roles} />)}
                sx={{ minWidth: "400px" }}
                style={{ marginLeft: 65 }}
                renderOption={(props, option) => (
                  <li {...props}>
                    <ListItemIcon sx={{ minWidth: "1.5rem" }}>{option.isAPIKey ? <FontAwesomeIcon icon={light("key-skeleton")} /> : <FontAwesomeIcon icon={light("user")} />}</ListItemIcon>
                    <ListItemText>{option.name}</ListItemText>
                  </li>
                )}
                // A member was selected in the autocomplete
                onChange={(event, aclsToBeAdded) => {
                  const principalsToBeAdded = aclsToBeAdded.map(a => a.principal)
                  setAcls(acls.map(a => ({ ...a, toBeAdded: principalsToBeAdded.includes(a.principal) })))
                }}
              />
              <IconButton
                color="primary"
                // Set members to be added as ACLs (will add rows)
                onClick={() => {
                  const aclsToBeAdded = acls.filter(a => a.toBeAdded)
                  const remainingAcls = acls.filter(a => !a.toBeAdded)
                  // The added ones will go at the end of the array
                  setAcls(remainingAcls.concat(aclsToBeAdded.map(a => ({ ...a, toBeAdded: false, isAcl: true }))))
                }}
              >
                <FontAwesomeIcon size="sm" icon={light("user-plus")} />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="caption">{translate("project.permissions.roles")}</Typography>
            <Stack alignItems="center" justifyContent="flex-start" direction="row" spacing={1}>
              <Chip avatar={<Avatar>{getOrgRoleAvatar(ROLES.ORG_ADMIN)}</Avatar>} label={translate("project.permissions.roles.admin")} variant="outlined" size="small" />
              <Chip avatar={<Avatar>{getOrgRoleAvatar(ROLES.ORG_EDITOR)}</Avatar>} label={translate("project.permissions.roles.editor")} variant="outlined" size="small" />
              <Chip avatar={<Avatar>{getOrgRoleAvatar(ROLES.ORG_VIEWER)}</Avatar>} label={translate("project.permissions.roles.viewer")} variant="outlined" size="small" />
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems="center" spacing={1}>
          {isSaving && <CircularProgress size={20} />}
          {hasError && <Typography color="error">{errorMessage}</Typography>}
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              store.dispatch(setSdsPermissionDialog(null))
            }}
          >
            {translate("button.cancel")}
          </Button>
          <Button disabled={acls.filter(a => a.isDirty).length === 0} color="primary" onClick={handleSave}>
            {translate("button.save")}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
