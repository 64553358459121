import { Chip, ChipProps, useTheme } from "@mui/material"
import React from "react"
import { MEMBER_ROLES } from "user/model"
import { OrgRoleAvatarGroup } from "./OrgRoleAvatarGroup"

interface OrgRoleChipsProps extends ChipProps {
  roles: MEMBER_ROLES[]
  avatarSize?: number
}

export const OrgRoleChip = (props: OrgRoleChipsProps) => {
  const theme = useTheme()
  const darkColor = theme.palette.info.dark
  const lightColor = theme.palette.info.light
  const color = theme.palette.mode === "dark" ? lightColor : darkColor
  const backgroundColor = theme.palette.mode === "dark" ? darkColor : lightColor
  return (
    <Chip
      {...props}
      size="small"
      variant="outlined"
      sx={{
        ...props.sx,
        color,
        backgroundColor,
        padding: ".2rem"
      }}
      avatar={<OrgRoleAvatarGroup roles={props.roles} avatarSize={props.avatarSize} />}
    />
  )
}
