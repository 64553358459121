import { light } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PersonRemoveAlt1 from "@mui/icons-material/PersonRemoveAlt1"
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, ListItemIcon, ListItemText, TextField } from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import { Box } from "@mui/system"
import { useErrorHandling } from "app/hook"
import { translate } from "app/language/service"
import { useAppSelector } from "app/store/hooks"
import { store } from "app/store/store"
import { JProject } from "jmapcloud-types"
import { useAcls } from "project/hooks"
import { JAcl, PROJECT_PERMISSIONS } from "project/model"
import { setProjectPermissionDialog } from "project/store"
import { updateProjectAcls } from "project/utils"
import React from "react"
import { PermissionCheckbox } from "ui/components/PermissionCheckbox"
import { StatusChip } from "ui/components/StatusChip"
import { STATUS_CHIP_LEVELS } from "ui/model"
import { getRoleChips } from "ui/utils"
import { OrgRoleAvatarGroup } from "user/components/OrgRoleAvatarGroup"
import { OrgRoleChip } from "user/components/OrgRoleChip"
import { ROLES } from "user/model"
import { rolesIncludeMinimumMemberRole } from "user/tools/permissions"

export const ProjectPermissionDialog = ({ project }: { project: JProject }) => {
  const [acls, setAcls] = useAcls(project.id)
  const [isSaving, setIsSaving] = React.useState(false)
  const { hasError, errorMessage, handleError, resetError } = useErrorHandling()

  // Derived from state
  const aclsWithOwnerAreAllSelected =
    acls.filter(a => a.isAcl && a.permissions.includes(PROJECT_PERMISSIONS.OWNER) && !a.isAPIKey).length ===
    acls.filter(a => a.isAcl && a.permissions.includes(PROJECT_PERMISSIONS.OWNER) && a.isSelected && !a.isAPIKey).length

  const theme = useAppSelector(state => state.ui.theme)

  const handleSave = async () => {
    if (hasError) {
      resetError()
    }

    const aclsToUpdate = acls.filter(a => a.isDirty).map(a => ({ principal: a.principal, permissions: a.permissions, inheritedPermissions: a.inheritedPermissions }))
    if (aclsToUpdate.length > 0) {
      setIsSaving(true)
      try {
        await updateProjectAcls(project.id, aclsToUpdate)
        setIsSaving(false)
      } catch (error: any) {
        setIsSaving(false)
        handleError(error)
        return
      }
    }
    store.dispatch(setProjectPermissionDialog(null))
  }

  const handlePermissionUpdate = (acl: JAcl, permission: PROJECT_PERMISSIONS, selected: boolean) => {
    setAcls(
      acls.map(a => {
        if (a.principal === acl.principal) {
          let updatedPermissions
          if (selected) {
            if (permission === PROJECT_PERMISSIONS.OWNER) {
              updatedPermissions = [PROJECT_PERMISSIONS.OWNER, PROJECT_PERMISSIONS.MODIFY, PROJECT_PERMISSIONS.VIEW]
            } else if (permission === PROJECT_PERMISSIONS.MODIFY) {
              updatedPermissions = [PROJECT_PERMISSIONS.MODIFY, PROJECT_PERMISSIONS.VIEW]
            } else {
              updatedPermissions = [...a.permissions, PROJECT_PERMISSIONS.VIEW]
            }
          } else {
            // unselect
            updatedPermissions = a.permissions.filter(p => p !== permission)
          }
          return {
            ...a,
            permissions: updatedPermissions,
            isDirty: true
          }
        }
        return a
      })
    )
  }

  const handleDeleteSelectedAcls = () => {
    const selectedPrincipals = acls.filter(a => a.isSelected).map(a => a.principal)
    setAcls(
      acls.map(a =>
        selectedPrincipals.includes(a.principal)
          ? {
              ...a,
              isAcl: false, // return item to dropdown
              isSelected: false,
              isDirty: true, // mark for update
              permissions: [] // will delete
            }
          : a
      )
    )
  }

  const isPermissionCheckboxEnabled = (permission: PROJECT_PERMISSIONS, acl: JAcl) => {
    // Cannot uncheck last OWNER
    if (permission === PROJECT_PERMISSIONS.OWNER) {
      const ownerAcls = acls.filter(a => a.permissions.includes(PROJECT_PERMISSIONS.OWNER) && !a.isAPIKey)
      if (ownerAcls.length === 1 && ownerAcls[0].principal === acl.principal) {
        return false
      }
    }
    // If not ORG_EDITOR, cannot modify OWNER or MODIFY
    if (!rolesIncludeMinimumMemberRole(acl.roles, ROLES.ORG_EDITOR) && [PROJECT_PERMISSIONS.OWNER, PROJECT_PERMISSIONS.MODIFY].includes(permission)) {
      return false
    }
    // if OWNER is checked, cannot modify MODIFY or VIEW
    if (acl.permissions.includes(PROJECT_PERMISSIONS.OWNER) && [PROJECT_PERMISSIONS.MODIFY, PROJECT_PERMISSIONS.VIEW].includes(permission)) {
      return false
    }
    // if MODIFY is checked, cannot modify VIEW
    if (acl.permissions.includes(PROJECT_PERMISSIONS.MODIFY) && permission === PROJECT_PERMISSIONS.VIEW) {
      return false
    }
    // if isGroup, cannot be owner
    if (acl.isGroup && permission === PROJECT_PERMISSIONS.OWNER) {
      return false
    }

    return true
  }

  return (
    <Dialog open aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="lg">
      <DialogTitle id="form-dialog-title">
        {translate("project.permissions")}
        <Typography variant="subtitle1" sx={{ fontSize: "18px" }}>
          {project.name[project.defaultLanguage]}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} style={{ borderBottomWidth: 0 }}>
                      {/* N members selected, and remove button */}
                      <Stack alignItems="center" direction="row" spacing={2}>
                        {acls.filter(a => a.isSelected).length > 0 && (
                          <>
                            <Typography style={{ marginLeft: 5, marginBottom: 0 }} variant="body2" gutterBottom>
                              {translate("project.permissions.members.selected", {
                                numSelected: acls.filter(a => a.isSelected).length
                              })}
                            </Typography>
                            <IconButton color="primary" onClick={handleDeleteSelectedAcls} disabled={aclsWithOwnerAreAllSelected}>
                              <PersonRemoveAlt1 />
                            </IconButton>
                            {aclsWithOwnerAreAllSelected && (
                              <>
                                <Box sx={{ marginLeft: "0.25rem" }}>
                                  <FontAwesomeIcon color={theme.palette.warning.main} icon={light("triangle-exclamation")} />
                                </Box>
                                <Typography style={{ marginLeft: 5, marginBottom: 0 }} variant="body2" gutterBottom>
                                  {translate("project.permissions.members.selected.at.least.one.owner")}
                                </Typography>
                              </>
                            )}
                          </>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell align="center" colSpan={2} style={{ borderBottomWidth: 2, borderBottomColor: theme.palette.text.secondary }}>
                      <Typography variant="overline">{translate("project.permissions.category.editor")}</Typography>
                    </TableCell>
                    <TableCell colSpan={1} style={{ borderBottomWidth: 0 }}></TableCell>
                    <TableCell align="center" colSpan={1} style={{ borderBottomWidth: 2, borderBottomColor: theme.palette.text.secondary }}>
                      <Typography variant="overline">{translate("project.permissions.category.viewer")}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell padding="checkbox">
                      {/* Select all checkboxes */}
                      <Checkbox
                        color="primary"
                        onChange={e => {
                          if (e.target.checked) {
                            setAcls(acls.map(a => ({ ...a, isSelected: a.isAcl })))
                          } else {
                            setAcls(acls.map(a => ({ ...a, isSelected: false })))
                          }
                        }}
                        checked={acls.filter(a => a.isSelected).length === acls.filter(a => a.isAcl).length}
                      />
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>{translate("label.name")}</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      {translate("project.permissions.owner")}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      {translate("project.permissions.modify")}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}></TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      {translate("project.permissions.view")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {acls.length === 0 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={6}>
                        <LinearProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    acls
                      .filter(a => a.isAcl)
                      .map(acl => {
                        const inheritedPermissions = acl.inheritedPermissions.map(p => p.permission)
                        const ownerIsInherited = inheritedPermissions.includes(PROJECT_PERMISSIONS.OWNER)
                        const modifyIsInherited = inheritedPermissions.includes(PROJECT_PERMISSIONS.MODIFY)
                        const viewIsInherited = inheritedPermissions.includes(PROJECT_PERMISSIONS.VIEW)

                        return (
                          <TableRow key={acl.principal} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <TableCell padding="checkbox">
                              {/* Select ACL checkbox */}
                              <Checkbox
                                color="primary"
                                onChange={e =>
                                  setAcls(
                                    acls.map(a => ({
                                      ...a,
                                      isSelected: a.principal === acl.principal ? e.target.checked : a.isSelected
                                    }))
                                  )
                                }
                                checked={acl.isSelected}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Stack direction="row" gap={2} alignItems="center">
                                {acl.isAPIKey ? (
                                  <FontAwesomeIcon icon={light("key-skeleton")} width={16} />
                                ) : acl.isGroup ? (
                                  <FontAwesomeIcon icon={light("users")} width={16} />
                                ) : (
                                  <FontAwesomeIcon icon={light("user")} width={16} />
                                )}
                                <Grid container spacing={2}>
                                  {acl.name !== acl.principal ? (
                                    <>
                                      {/* Show name and principal */}
                                      <Grid item xs={12}>
                                        <Stack alignItems="center" direction="row" spacing={2} gap={1}>
                                          {acl.name}
                                          {!acl.isGroup && <OrgRoleAvatarGroup roles={acl.roles} avatarSize={16} />}
                                        </Stack>
                                      </Grid>
                                      <Grid item xs={12} style={{ paddingTop: 0 }}>
                                        {acl.principal}
                                      </Grid>
                                    </>
                                  ) : (
                                    // Show principal only
                                    <Grid item xs={12}>
                                      <Stack alignItems="center" direction="row" spacing={2} gap={1}>
                                        {acl.principal}
                                        {!acl.isGroup && <OrgRoleAvatarGroup roles={acl.roles} avatarSize={16} />}
                                      </Stack>
                                    </Grid>
                                  )}
                                </Grid>
                              </Stack>
                            </TableCell>
                            {/* Owner checkbox */}
                            <PermissionCheckbox
                              isInherited={ownerIsInherited}
                              onChange={e => handlePermissionUpdate(acl, PROJECT_PERMISSIONS.OWNER, e.target.checked)}
                              checked={acl.permissions.includes(PROJECT_PERMISSIONS.OWNER)}
                              disabled={!isPermissionCheckboxEnabled(PROJECT_PERMISSIONS.OWNER, acl)}
                            />
                            {/* Modify checkbox */}
                            <PermissionCheckbox
                              isInherited={modifyIsInherited}
                              onChange={e => handlePermissionUpdate(acl, PROJECT_PERMISSIONS.MODIFY, e.target.checked)}
                              checked={acl.permissions.includes(PROJECT_PERMISSIONS.MODIFY)}
                              disabled={!isPermissionCheckboxEnabled(PROJECT_PERMISSIONS.MODIFY, acl)}
                            />
                            <TableCell align="center" style={{ width: 1 }}></TableCell>
                            {/* View checkbox */}
                            <PermissionCheckbox
                              isInherited={viewIsInherited}
                              onChange={e => handlePermissionUpdate(acl, PROJECT_PERMISSIONS.VIEW, e.target.checked)}
                              checked={acl.permissions.includes(PROJECT_PERMISSIONS.VIEW)}
                              disabled={!isPermissionCheckboxEnabled(PROJECT_PERMISSIONS.VIEW, acl)}
                            />
                          </TableRow>
                        )
                      })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={8}>
            <Stack alignItems="center" justifyContent="flex-start" direction="row" spacing={2}>
              <Autocomplete
                multiple
                limitTags={3}
                value={acls.filter(a => a.toBeAdded)}
                id="multiple-limit-tags"
                options={acls.filter(a => !a.isAcl)}
                getOptionLabel={option => option.name}
                renderInput={params => <TextField {...params} label={translate("permissions.add.entity")} />}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) =>
                    option.isGroup ? (
                      <StatusChip {...getTagProps({ index })} level={STATUS_CHIP_LEVELS.NEUTRAL} label={option.name} />
                    ) : (
                      <OrgRoleChip {...getTagProps({ index })} label={option.name} roles={option.roles} />
                    )
                  )
                }
                sx={{ minWidth: "400px" }}
                style={{ marginLeft: 65 }}
                renderOption={(props, option) => (
                  <li {...props} style={{ display: "flex", alignItems: "center", gap: "0.25rem" }}>
                    <ListItemIcon sx={{ minWidth: "1.5rem" }}>
                      {option.isAPIKey ? <FontAwesomeIcon icon={light("key-skeleton")} /> : option.isGroup ? <FontAwesomeIcon icon={light("users")} /> : <FontAwesomeIcon icon={light("user")} />}
                    </ListItemIcon>
                    <ListItemText>{option.name}</ListItemText>
                  </li>
                )}
                // A member was selected in the autocomplete
                onChange={(event, aclsToBeAdded) => {
                  const principalsToBeAdded = aclsToBeAdded.map(a => a.principal)
                  setAcls(acls.map(a => ({ ...a, toBeAdded: principalsToBeAdded.includes(a.principal) })))
                }}
              />
              <IconButton
                color="primary"
                // Set members to be added as ACLs (will add rows)
                onClick={() => {
                  const aclsToBeAdded = acls.filter(a => a.toBeAdded)
                  const remainingAcls = acls.filter(a => !a.toBeAdded)
                  // The added ones will go at the end of the array
                  setAcls(remainingAcls.concat(aclsToBeAdded.map(a => ({ ...a, toBeAdded: false, isAcl: true }))))
                }}
              >
                <FontAwesomeIcon size="sm" icon={light("user-plus")} />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="caption">{translate("project.permissions.roles")}</Typography>
            <Stack alignItems="center" justifyContent="flex-start" direction="row" spacing={1}>
              {getRoleChips([ROLES.ORG_ADMIN, ROLES.ORG_EDITOR, ROLES.ORG_VIEWER])}
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems="center" spacing={1}>
          {isSaving && <CircularProgress size={20} />}
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              store.dispatch(setProjectPermissionDialog(null))
            }}
          >
            {translate("button.cancel")}
          </Button>
          <Button disabled={acls.filter(a => a.isDirty).length === 0} color="primary" onClick={handleSave}>
            {translate("button.save")}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
