import { Stack } from "@mui/system"
import { JGroup } from "group/model"
import React from "react"
import { StatusChip } from "ui/components/StatusChip"
import { STATUS_CHIP_LEVELS } from "ui/model"

interface GroupGridCellProps {
  groups: JGroup[]
}

export const GroupGridCell = (props: GroupGridCellProps): JSX.Element => {
  const cellRef = React.useRef<HTMLDivElement>(null)
  const [visibleGroups, setVisibleGroups] = React.useState<JGroup[]>([])
  const [remainingCount, setRemainingCount] = React.useState<number>(0)
  const gapWidth = 8

  React.useEffect(() => {
    const updateVisibleGroups = () => {
      if (!cellRef.current) {
        return
      }

      const cellWidth = cellRef.current.offsetWidth - 10 // remove 10px to account for the possible scrollbar width
      if (cellWidth <= 0) {
        return
      }

      let usedWidth = 0
      const newVisibleGroups: JGroup[] = []
      let extraGroups = 0

      // Temporary chip container for dynamic width calculation of the chips inside
      const tempContainer = document.createElement("div")
      tempContainer.style.visibility = "hidden"
      tempContainer.style.position = "absolute"
      document.body.appendChild(tempContainer)

      for (const group of props.groups) {
        // Measure the group chip width. Style of div has to correspond to Chip style for it to have the same width.
        const tempChip = document.createElement("div")
        tempChip.style.display = "inline-block"
        tempChip.style.marginRight = `${gapWidth}px`
        tempChip.style.padding = ".4rem"
        tempChip.style.fontSize = ".85rem"
        tempChip.innerText = group.name
        tempContainer.appendChild(tempChip)
        const chipWidth = tempChip.getBoundingClientRect().width
        // Measure the number chip width. Style of div has to correspond to Chip style for it to have the same width.
        const tempNumberChip = document.createElement("div")
        tempNumberChip.style.display = "inline-block"
        tempNumberChip.style.marginRight = `${gapWidth}px`
        tempNumberChip.style.padding = ".4rem"
        tempNumberChip.style.fontSize = ".85rem"
        tempNumberChip.innerText = `+${extraGroups}`
        tempContainer.appendChild(tempNumberChip)
        const numberChipWidth = tempNumberChip.getBoundingClientRect().width

        // Check if there's room for the group chip and the number chip (unless we are at the last group)
        if (usedWidth + chipWidth + (props.groups.length === newVisibleGroups.length + 1 ? 0 : numberChipWidth) <= cellWidth) {
          newVisibleGroups.push(group)
          usedWidth += chipWidth + gapWidth
        } else {
          extraGroups = props.groups.length - newVisibleGroups.length
          // Ensure there's enough room for the number chip. If not, remove the last group added to make room for the number chip and adjust the extra group count
          if (usedWidth + numberChipWidth > cellWidth && newVisibleGroups.length > 0) {
            ++extraGroups
            newVisibleGroups.pop()
          }
          break
        }
      }

      document.body.removeChild(tempContainer)

      setVisibleGroups(newVisibleGroups)
      setRemainingCount(extraGroups)
    }

    const resizeObserver = new ResizeObserver(() => {
      updateVisibleGroups()
    })
    if (cellRef.current) {
      resizeObserver.observe(cellRef.current)
      updateVisibleGroups()
    }

    return () => {
      if (cellRef.current) {
        resizeObserver.unobserve(cellRef.current)
      }
    }
  }, [props.groups])

  return (
    <div ref={cellRef} style={{ width: "100%" }}>
      <Stack direction="row" alignItems="center" gap={`${gapWidth}px`}>
        {visibleGroups.map((group: JGroup) => (
          <StatusChip key={group.id} level={STATUS_CHIP_LEVELS.NEUTRAL} label={group.name} sx={{ height: "fit-content" }} />
        ))}
        {remainingCount > 0 && <StatusChip key="more-groups" level={STATUS_CHIP_LEVELS.NEUTRAL} label={`+${remainingCount}`} sx={{ height: "fit-content" }} />}
      </Stack>
    </div>
  )
}
