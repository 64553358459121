import { faChevronLeft, faPlus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, IconButton, Stack, TextField, Typography } from "@mui/material"
import { GridColDef, GridRenderCellParams, GridRowId, GridSelectionModel } from "@mui/x-data-grid"
import { useErrorHandling } from "app/hook"
import { translate } from "app/language/service"
import { useAppSelector } from "app/store/hooks"
import { store } from "app/store/store"
import { JGroupWithFullMembers } from "group/model"
import { getGroupById, getSelectedGroupId, updateGroup } from "group/tools/common"
import { debounce } from "lodash"
import { MemberDetailsDialog } from "member/components/MemberDetailsDialog"
import { JMember } from "member/model"
import { getFormattedRole, getMember } from "member/tools/common"
import { ORGANIZATION_TABS } from "organization/model"
import { organizationActionCreator } from "organization/store/actions"
import { setOrganizationActiveTab, setOrganizationSelectedGroupId } from "organization/tools/display"
import { naturalSortString } from "project/utils"
import React from "react"
import { PortalDataGrid } from "ui/components/PortalDataGrid"
import { QuickFilterInput } from "ui/components/QuickFilterInput"
import { useHoverableDataGridRows } from "ui/hooks"
import { GroupMemberGridRowMenu } from "./GroupMemberGridMenu"
import { GroupMembersAddDialog } from "./GroupMembersAddDialog"
import { GroupMembersDeleteDialog } from "./GroupMembersDeleteDialog"

export const GroupGrid = (): JSX.Element => {
  const { hasError, errorMessage, handleError, resetError } = useErrorHandling(translate("organization.group.update.name.error"))
  const [validationError, setValidationError] = React.useState("")
  const { hoveredRowId, setHoveredRowId, ...rowHandlers } = useHoverableDataGridRows()
  const [group, setGroup] = React.useState<JGroupWithFullMembers>()
  const [filteredGroupMembers, setFilteredGroupMembers] = React.useState<JMember[]>([])
  const [selectedGroupMemberIds, setSelectedGroupMemberIds] = React.useState<GridSelectionModel>([])
  const [currentRowMemberId, setCurrentRowMemberId] = React.useState<GridRowId | null>(null)
  const [isGroupMemberDetailsDialogOpened, setIsGroupMemberDetailsDialogOpened] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)
  const [hasLoadingError, setHasLoadingError] = React.useState(false)
  const [isAddGroupMembersDialogOpened, setIsAddGroupMembersDialogOpened] = React.useState(false)
  const [isDeleteGroupMembersDialogOpened, setIsDeleteGroupMembersDialogOpened] = React.useState(false)
  const [quickFilter, setQuickFilter] = React.useState<string>("")
  const [reloadCounter, setReloadCounter] = React.useState(0)
  const { pageSize } = useAppSelector(state => state.organization)

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const groupData = await getGroupById(getSelectedGroupId())
        const groupFullMembers = await Promise.all(
          groupData.members.map(async member => {
            try {
              const fullMemberInfo = await getMember(member.id)
              return {
                ...member,
                ...fullMemberInfo
              }
            } catch {
              // Handle case where the member hasn't accepted its invitation
              return {
                ...member,
                roles: [],
                name: translate("organization.invitation.pending")
              }
            }
          })
        )
        const sortedMembers = groupFullMembers.sort((m1, m2) => naturalSortString(m1.email, m2.email))
        const groupWithFullMembers = { ...groupData, members: sortedMembers }
        setFilteredGroupMembers(sortedMembers)
        setGroup(groupWithFullMembers)
        setSelectedGroupMemberIds(selectedGroupMemberIds.filter(id => sortedMembers.some(member => member.id === id)))
        setHasLoadingError(false)
      } catch (error) {
        console.error(error)
        setFilteredGroupMembers([])
        setGroup(undefined)
        setSelectedGroupMemberIds([])
        setHasLoadingError(true)
      } finally {
        setCurrentRowMemberId("")
        setIsLoading(false)
      }
    }

    fetchData()
  }, [reloadCounter])

  const reloadGrid = () => {
    setReloadCounter(v => v + 1)
  }

  const debouncedUpdateGroup = React.useCallback(
    debounce((newName: string) => {
      updateGroup(getSelectedGroupId(), { name: newName }).catch(error => {
        console.error(error)
        handleError(error)
      })
    }, 500),
    []
  )

  React.useEffect(() => {
    if (group) {
      const newFilteredGroupMembers = group.members.filter(member => member.name.toLowerCase().includes(quickFilter.toLowerCase()) || member.email.toLowerCase().includes(quickFilter.toLowerCase()))
      setFilteredGroupMembers(newFilteredGroupMembers)
    }
  }, [quickFilter])

  const returnToGroups = () => {
    setOrganizationActiveTab(ORGANIZATION_TABS.GROUPS)
    setOrganizationSelectedGroupId("")
  }

  const handleGroupNameChange = (newName: string) => {
    if (hasError) {
      resetError()
    }
    if (group) {
      setGroup({ ...group, name: newName })
    }
    if (newName.length < 2) {
      setValidationError(translate("organization.group.update.name.error.2chars"))
    } else if (newName.length > 255) {
      setValidationError(translate("organization.group.update.name.error.255chars"))
    } else {
      setValidationError("")
      debouncedUpdateGroup(newName)
    }
  }

  const handleDeleteDialog = (id: GridRowId) => {
    setCurrentRowMemberId(id)
    setIsDeleteGroupMembersDialogOpened(true)
  }
  const handleMemberDetailsDialog = (id: GridRowId) => {
    setCurrentRowMemberId(id)
    setIsGroupMemberDetailsDialogOpened(true)
  }

  const columns: GridColDef[] = [
    { field: "name", headerName: translate("label.name"), minWidth: 250, flex: 3 },
    {
      field: "action",
      sortable: false,
      filterable: false,
      headerName: "",
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      maxWidth: 10,
      align: "right",
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        if (params.id === hoveredRowId) {
          return (
            <GroupMemberGridRowMenu
              selectedGroupMemberIds={selectedGroupMemberIds}
              memberId={params.id}
              onShowDetailsDialog={() => {
                handleMemberDetailsDialog(params.id)
              }}
              onDeleteClick={() => {
                handleDeleteDialog(params.id)
              }}
              onClose={() => setHoveredRowId(null)}
            />
          )
        } else {
          return null
        }
      }
    },
    { field: "email", headerName: translate("label.email"), minWidth: 250, flex: 3 },
    { field: "roles", headerName: translate("label.role"), minWidth: 250, flex: 2 }
  ]
  return (
    <Stack
      sx={{
        height: "100%"
      }}
    >
      <Stack direction="row" alignItems={"center"} justifyContent="space-between" sx={{ marginBottom: "1rem", marginTop: "2rem" }}>
        <Stack direction="row" alignItems={"center"} justifyContent="start" gap=".5rem" width={"80%"}>
          <IconButton color="primary" aria-label="return to groups" onClick={returnToGroups}>
            <FontAwesomeIcon size="lg" icon={faChevronLeft} />
          </IconButton>
          <TextField
            id="group-name-input"
            variant="standard"
            sx={{ minWidth: "5rem", maxWidth: "30rem", flex: 1 }}
            value={group?.name ?? ""}
            onChange={event => handleGroupNameChange(event.target.value)}
          />
          <QuickFilterInput value={quickFilter} onChange={setQuickFilter} />
        </Stack>
        <Button startIcon={<FontAwesomeIcon icon={faPlus} />} onClick={() => setIsAddGroupMembersDialogOpened(true)} sx={{ whiteSpace: "nowrap" }}>
          {translate("organization.group.members.add")}
        </Button>
      </Stack>
      {hasError ? (
        <Typography color="error" sx={{ marginLeft: "0.5rem" }}>
          {errorMessage}
        </Typography>
      ) : validationError.length > 0 ? (
        <Typography color="error" sx={{ marginLeft: "0.5rem" }}>
          {validationError}
        </Typography>
      ) : (
        <div />
      )}
      <PortalDataGrid
        rowType="member"
        rows={filteredGroupMembers.map(member => ({ ...member, roles: getFormattedRole(member.roles[0]) }))}
        columns={columns}
        pagination={false}
        className="member-list-data-grid"
        loading={isLoading}
        pageSize={pageSize}
        onPageSizeChange={s => store.dispatch(organizationActionCreator.setPageSize(s))}
        checkboxSelection
        selectionModel={selectedGroupMemberIds}
        onSelectionModelChange={setSelectedGroupMemberIds}
        error={hasLoadingError}
        componentsProps={{
          row: rowHandlers
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }]
          }
        }}
      />

      {isGroupMemberDetailsDialogOpened && currentRowMemberId && group && (
        <MemberDetailsDialog member={group.members.find(member => member.id === currentRowMemberId)} onClose={() => setIsGroupMemberDetailsDialogOpened(false)} />
      )}

      {isAddGroupMembersDialogOpened && group && (
        <GroupMembersAddDialog
          groupId={group.id}
          currentMembers={group.members}
          onClose={() => setIsAddGroupMembersDialogOpened(false)}
          onCreate={() => {
            setIsAddGroupMembersDialogOpened(false)
            reloadGrid()
          }}
        />
      )}

      {isDeleteGroupMembersDialogOpened && (selectedGroupMemberIds.length > 0 || currentRowMemberId) && group && (
        <GroupMembersDeleteDialog
          memberIdsToDelete={selectedGroupMemberIds}
          currentRowMemberId={currentRowMemberId}
          group={group}
          onDelete={reloadGrid}
          onClose={() => {
            setCurrentRowMemberId("")
            setIsDeleteGroupMembersDialogOpened(false)
          }}
        />
      )}
    </Stack>
  )
}
