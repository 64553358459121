import { Box, CircularProgress, Typography } from "@mui/material"
import { useErrorHandling } from "app/hook"
import { translate } from "app/language/service"
import { getLayer, getProject } from "project/utils"
import React from "react"
import { JDataSourceReference, JLayerError, JLayerWithStatus, JProjectError, JProjectWithStatus } from "spatialdatasource/model"

interface Props {
  references: JDataSourceReference
}

export const DataSourceReferences = (props: Props) => {
  const [projects, setProjects] = React.useState<Array<JProjectWithStatus | JProjectError>>()
  const [layers, setLayers] = React.useState<Array<JLayerWithStatus | JLayerError>>()
  const [loading, setLoading] = React.useState(false)
  const { hasError, errorMessage, handleError, resetError } = useErrorHandling(translate("sds.delete.message.error"))

  const projectIds = Object.keys(props.references.projects)

  React.useEffect(() => {
    const fetchData = async () => {
      resetError()
      setLoading(true)
      try {
        const projectRequestIds = projectIds.map(projectId => ({ projectId }))

        const layerRequestIds = projectIds.flatMap(projectId =>
          props.references.projects[projectId].layers.map((layerId: string) => ({
            projectId,
            layerId
          }))
        )

        const [fetchedProjects, fetchedLayers] = await Promise.all([
          Promise.allSettled(projectRequestIds.map(({ projectId }) => getProject(projectId))),
          Promise.allSettled(layerRequestIds.map(({ projectId, layerId }) => getLayer(projectId, layerId)))
        ])

        setProjects(
          fetchedProjects.map((result, index) => {
            const { projectId } = projectRequestIds[index]
            if (result.status === "fulfilled") {
              return { ...result.value, status: "success" }
            } else {
              return { id: projectId, status: "error", errorMessage: result.reason.response?.status === 403 ? translate("sds.references.project.unknown") : translate("sds.references.project.error") }
            }
          })
        )
        setLayers(
          fetchedLayers.map((result, index) => {
            const { layerId } = layerRequestIds[index]
            if (result.status === "fulfilled") {
              return { ...result.value, status: "success" }
            } else {
              return { id: layerId, status: "error", errorMessage: result.reason.response?.status === 403 ? translate("sds.references.layer.unknown") : translate("sds.references.layer.error") }
            }
          })
        )
      } catch (error: any) {
        console.error(error)
        handleError(error)
      } finally {
        setLoading(false)
      }
    }
    if (Object.keys(props.references.projects).length > 0) {
      fetchData()
    }
  }, [props.references])

  if (loading) {
    return (
      <Box>
        <CircularProgress sx={{ margin: "1rem" }} size={"0.75rem"} />
      </Box>
    )
  }
  if (hasError) {
    return <Typography>{errorMessage}</Typography>
  }
  if (!projects || !layers) {
    return <Typography>{translate("sds.delete.message.noReferences")}</Typography>
  }
  return (
    <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
      {projectIds.map(projectId => {
        const project = projects.find((p: any) => p.id === projectId)
        if (project.status === "error") {
          return (
            <li key={projectId} style={{ display: "list-item" }}>
              <Typography sx={{ fontStyle: "italic" }}>{project.errorMessage}</Typography>
            </li>
          )
        } else {
          const layersInProject = props.references.projects[projectId].layers
            .map(layerId => {
              const layer = layers.find((l: any) => l.id === layerId)
              if (layer.status === "error") {
                return layer.errorMessage
              } else {
                return layer.name[project.defaultLanguage] || ""
              }
            })
            .filter(Boolean)

          const layerLabel = translate(`label.layer${layersInProject.length > 1 ? "s" : ""}`)
          const layersName = layersInProject.join(", ")
          return (
            <li key={projectId} style={{ display: "list-item" }}>
              <Typography>{`${translate("label.project")}: ${project.name[project.defaultLanguage]} (${layerLabel}: ${layersName})`}</Typography>
            </li>
          )
        }
      })}
    </ul>
  )
}
