import { faCube } from "@fortawesome/pro-thin-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Card, Link, Stack, Switch, Typography } from "@mui/material"
import { translate } from "app/language/service"
import { messageSVC } from "message/service"
import { JExtension } from "organization/model"
import React from "react"

interface OrganizationExtensionCardProps {
  extension: JExtension
  isActivated: boolean
  disabled: boolean
  toggleActivation: (isActivated: boolean, extensionId: string) => void
}

export const OrganizationExtensionCard = (props: OrganizationExtensionCardProps) => {
  const handleToggleActivation = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      props.toggleActivation(true, props.extension.id)
    } else {
      messageSVC.confirmDialog({
        confirmButtonLabel: translate("button.continue"),
        cancelButtonLabel: translate("button.cancel"),
        isCancelDefault: true,
        title: translate("organization.extension.deactivate.title"),
        message: translate("organization.extension.deactivate.message", { extensionName: props.extension.name }),
        onSuccess: () => {
          props.toggleActivation(false, props.extension.id)
        }
      })
    }
  }
  return (
    <Card sx={{ padding: "1rem" }}>
      <Stack gap={1} height="100%">
        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
          <Typography variant="h6">{props.extension.name}</Typography>
          <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
            <Typography variant="h6">{translate(props.isActivated ? "organization.tab.extension.activated" : "organization.tab.extension.deactivated").toUpperCase()}</Typography>
            <Switch disabled={props.disabled} checked={props.isActivated} onChange={handleToggleActivation}></Switch>
          </Stack>
        </Stack>
        <Stack flexDirection={"row"} gap={4} height="11rem">
          <Stack justifyContent={"center"} flexWrap={"wrap"} alignContent={"center"} sx={{ height: "100%", width: "10rem" }}>
            {props.extension.imageUrl ? (
              <img src={props.extension.imageUrl} style={{ height: "auto", maxHeight: "80%", width: "auto", maxWidth: "80%" }} />
            ) : (
              <FontAwesomeIcon style={{ height: "80%", aspectRatio: "1/1" }} icon={faCube} />
            )}
          </Stack>
          <Stack gap={1} flexGrow={1} justifyContent="space-between">
            <Stack flexDirection={"row"} gap={10} sx={{ flex: "0 1 auto" }}>
              <Stack flexDirection={"row"} gap={3}>
                <Typography variant={"body1"}>Editor</Typography>
                <Typography variant={"body1"} sx={{ fontWeight: "bold" }}>
                  {props.extension.editor}
                </Typography>
              </Stack>
              <Stack flexDirection={"row"} gap={3}>
                <Typography variant={"body1"}>Version</Typography>
                <Typography variant={"body1"} sx={{ fontWeight: "bold" }}>
                  {props.extension.version}
                </Typography>
              </Stack>
            </Stack>
            <Typography
              sx={{
                overflow: "auto",
                flex: 1,
                paddingRight: "0.5rem"
              }}
            >
              {props.extension.description}
            </Typography>
            <Link href={props.extension.presentationPageUrl} target="_blank" rel="noreferrer" sx={{ flex: "0 1 auto" }}>
              {translate("label.more.information")}
            </Link>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  )
}
