import { faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, IconButton } from "@mui/material"
import { Stack } from "@mui/system"
import { GridColDef, GridRenderCellParams, GridRowId, GridSelectionModel } from "@mui/x-data-grid"
import { translate } from "app/language/service"
import { useAppSelector } from "app/store/hooks"
import { store } from "app/store/store"
import { JGroup } from "group/model"
import { getAllGroups } from "group/tools/common"
import { ORGANIZATION_TABS } from "organization/model"
import { organizationActionCreator } from "organization/store/actions"
import { setOrganizationActiveTab, setOrganizationSelectedGroupId } from "organization/tools/display"
import { naturalSortString } from "project/utils"
import React from "react"
import { PortalDataGrid } from "ui/components/PortalDataGrid"
import { QuickFilterInput } from "ui/components/QuickFilterInput"
import { useHoverableDataGridRows } from "ui/hooks"
import { getGridStringOperatorsForJMC } from "ui/tools/grid"
import { GroupCreateDialog } from "./GroupCreateDialog"
import { GroupsDeleteDialog } from "./GroupsDeleteDialog"

export const GroupsGrid = (): JSX.Element => {
  const [groups, setGroups] = React.useState<JGroup[]>([])
  const [initialGroups, setInitialGroups] = React.useState<JGroup[]>([])
  const { hoveredRowId, setHoveredRowId, ...rowHandlers } = useHoverableDataGridRows()
  const [selectedGroupIds, setSelectedGroupIds] = React.useState<GridSelectionModel>([])
  const [currentRowGroupId, setCurrentRowGroupId] = React.useState<GridRowId | null>(null)
  const [isLoading, setIsLoading] = React.useState(true)
  const [hasLoadingError, setHasLoadingError] = React.useState(false)
  const [isCreateGroupDialogOpened, setIsCreateGroupDialogOpened] = React.useState(false)
  const [isDeleteGroupsDialogOpened, setIsDeleteGroupsDialogOpened] = React.useState(false)
  const [quickFilter, setQuickFilter] = React.useState<string>("")
  const [reloadCounter, setReloadCounter] = React.useState(0)
  const { pageSize } = useAppSelector(state => state.organization)

  React.useEffect(() => {
    getAllGroups()
      .then(groupsData => {
        groupsData.sort((g1, g2) => naturalSortString(g1.name, g2.name))
        setGroups(groupsData)
        setInitialGroups(groupsData)
        setSelectedGroupIds(selectedGroupIds.filter(id => groupsData.some(group => group.id === id)))
      })
      .catch(error => {
        console.error(error)
        setGroups([])
        setInitialGroups([])
        setSelectedGroupIds([])
        setHasLoadingError(true)
      })
      .finally(() => {
        setCurrentRowGroupId("")
        setIsLoading(false)
      })
  }, [reloadCounter])

  const reloadGrid = () => {
    setReloadCounter(v => v + 1)
  }

  React.useEffect(() => {
    const newFilteredGroups = initialGroups.filter(group => group.name.toLowerCase().includes(quickFilter.toLowerCase()))
    setGroups(newFilteredGroups)
  }, [quickFilter])

  const handleDeleteDialog = (id: GridRowId) => {
    setCurrentRowGroupId(id)
    setIsDeleteGroupsDialogOpened(true)
  }

  const columns: Array<GridColDef<JGroup, any, any>> = [
    {
      field: "name",
      headerName: translate("label.name"),
      minWidth: 250,
      flex: 20,
      filterOperators: getGridStringOperatorsForJMC()
    },
    { field: "numberOfMembers", headerName: translate("label.members"), minWidth: 200, flex: 4, align: "right", headerAlign: "right", filterOperators: getGridStringOperatorsForJMC() },
    {
      field: "action",
      sortable: false,
      filterable: false,
      headerName: "",
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      flex: 3,
      minWidth: 50,
      align: "right",
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        if (params.id === hoveredRowId) {
          return (
            <IconButton aria-label="open delete dialog" onClick={() => handleDeleteDialog(params.id)} size="small">
              <FontAwesomeIcon size="sm" icon={faTrash} />
            </IconButton>
          )
        } else {
          return null
        }
      }
    }
  ]

  return (
    <Stack
      sx={{
        height: "100%"
      }}
    >
      <Stack direction="row" alignItems={"center"} justifyContent="space-between" sx={{ marginBottom: "1rem", marginTop: "2rem" }}>
        <QuickFilterInput value={quickFilter} onChange={setQuickFilter} />
        <Button startIcon={<FontAwesomeIcon icon={faPlus} />} onClick={() => setIsCreateGroupDialogOpened(true)}>
          {translate("organization.group.create.title")}
        </Button>
      </Stack>
      <PortalDataGrid
        rowType="group"
        rows={groups.map(group => {
          let numberOfMembers
          switch (true) {
            case group.members === undefined:
              numberOfMembers = `0 ${translate("group.grid.member.number", { numMembers: 0 })}`
              break
            case group.members.length > 100:
              numberOfMembers = `100+ ${translate("group.grid.member.number", { numMembers: group.members.length })}`
              break
            default:
              numberOfMembers = `${group.members.length} ${translate("group.grid.member.number", { numMembers: group.members.length })}`
          }

          return {
            ...group,
            numberOfMembers
          }
        })}
        columns={columns}
        pagination={false}
        loading={isLoading}
        pageSize={pageSize}
        onPageSizeChange={s => store.dispatch(organizationActionCreator.setPageSize(s))}
        checkboxSelection
        selectionModel={selectedGroupIds}
        onSelectionModelChange={setSelectedGroupIds}
        error={hasLoadingError}
        componentsProps={{
          row: rowHandlers
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }]
          }
        }}
        onRowDoubleClick={params => {
          setOrganizationActiveTab(ORGANIZATION_TABS.GROUP)
          setOrganizationSelectedGroupId(params.id as string)
        }}
        sx={{
          "& .MuiDataGrid-row": {
            cursor: "pointer"
          }
        }}
      />

      {isCreateGroupDialogOpened && (
        <GroupCreateDialog
          existingGroups={groups}
          onClose={() => setIsCreateGroupDialogOpened(false)}
          onCreate={() => {
            setIsCreateGroupDialogOpened(false)
            reloadGrid()
          }}
        />
      )}

      {isDeleteGroupsDialogOpened && (selectedGroupIds.length > 0 || currentRowGroupId) && (
        <GroupsDeleteDialog
          groupIdsToDelete={selectedGroupIds}
          currentRowGroupId={currentRowGroupId}
          allGroups={initialGroups}
          onDelete={reloadGrid}
          onClose={() => {
            setCurrentRowGroupId("")
            setIsDeleteGroupsDialogOpened(false)
          }}
        />
      )}
    </Stack>
  )
}
