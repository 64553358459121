import { Stack } from "@mui/system"
import { GridColDef, GridFilterModel, GridRowId } from "@mui/x-data-grid-pro"
import { translate } from "app/language/service"
import { FILE_INFO_TYPES, JFileInfo } from "file/model"
import React from "react"
import { PortalDataGrid } from "ui/components/PortalDataGrid"
import { QuickFilterInput } from "ui/components/QuickFilterInput"

interface Props {
  files: JFileInfo[]
  onSelectionChange: (callback: (previouslySelectedFileInfos: JFileInfo[]) => JFileInfo[]) => void
}

export const FileSelectDialogDatagrid = (props: Props) => {
  const [quickFilter, setQuickFilter] = React.useState<string>("")
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([])

  const handleSelectedFiles = (selectedIds: GridRowId[]) => {
    props.onSelectionChange((previouslySelectedFileInfos: JFileInfo[]) => {
      const fileInfos = selectedIds.map(id => props.files.find(file => file.id === id))
      if (fileInfos.length === 0) {
        setSelectionModel([])
        return []
      }
      if (previouslySelectedFileInfos.length === 0) {
        const rasterFiles = fileInfos.filter(file => file.type === FILE_INFO_TYPES.RASTER_DATA)
        if (rasterFiles.length === 0) {
          setSelectionModel([selectedIds[selectedIds.length - 1]])
          return [props.files.find(file => file.id === selectedIds[selectedIds.length - 1])]
        } else {
          setSelectionModel(rasterFiles.map(file => file.id))
          return rasterFiles
        }
      } else {
        const rasterFiles = fileInfos.filter(file => file.type === FILE_INFO_TYPES.RASTER_DATA)
        if (rasterFiles.length === 0) {
          // necessary to select the clicked file when the previously selected file is a vector file and lower than the selected vector files
          const previouslySelectedVectorIndex = props.files.findIndex(file => file.id === selectedIds[0])
          const lastSelectedVectorFileIndex = props.files.findIndex(file => file.id === selectedIds[selectedIds.length - 1])
          if (Math.abs(previouslySelectedVectorIndex - lastSelectedVectorFileIndex) === 1 && selectedIds.length > 1) {
            setSelectionModel([selectedIds[1]])
            return [props.files.find(file => file.id === selectedIds[1])]
          }
          setSelectionModel([selectedIds[selectedIds.length - 1]])
          return [props.files.find(file => file.id === selectedIds[selectedIds.length - 1])]
        } else {
          setSelectionModel(rasterFiles.map(file => file.id))
          return rasterFiles
        }
      }
    })
  }

  const columns: GridColDef[] = [
    { field: "filename", headerName: translate("label.name"), flex: 1 },
    { field: "type", headerName: translate("label.type"), flex: 3 }
  ]

  const filterModel: GridFilterModel = {
    items: quickFilter
      ? [
          {
            columnField: "filename",
            operatorValue: "contains",
            value: quickFilter
          }
        ]
      : []
  }

  return (
    <Stack style={{ height: 500, width: "100%" }}>
      <Stack flexDirection={"row"}>
        <QuickFilterInput value={quickFilter} onChange={setQuickFilter} />
      </Stack>
      <PortalDataGrid
        rowType="file"
        checkboxSelection
        rows={props.files}
        columns={columns}
        pagination={false}
        filterModel={filterModel}
        selectionModel={selectionModel}
        hideFooterRowCount
        hideFooter
        onSelectionModelChange={handleSelectedFiles}
      />
    </Stack>
  )
}
