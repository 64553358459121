import { GridFilterModel, GridSortModel } from "@mui/x-data-grid"
import { translate } from "app/language/service"
import { JTag } from "organization/model"
import { getLayer, getProject } from "project/utils"
import {
  DATA_SOURCE_STATUSES,
  DATA_SOURCE_TYPES,
  DETAILS_DIALOG_SECTION,
  DETAILS_DIALOG_SECTIONS_PERMISSIONS,
  JDataSource,
  JDataSourceSubmitValues,
  JLayerError,
  JLayerWithStatus,
  JProjectError,
  JProjectWithStatus
} from "spatialdatasource/model"
import { sdsRPO } from "spatialdatasource/repository"
import { STATUS_CHIP_LEVELS } from "ui/model"
import { JDataGridPagedResponse } from "ui/tools/grid"
import { getUserOrganization } from "user/tools/common"

export function getDataSources(page: number, size: number, sortModel: GridSortModel, filterModel: GridFilterModel): Promise<JDataGridPagedResponse<JDataSource>> {
  return sdsRPO.get(getUserOrganization().id, page, size, sortModel, filterModel)
}

export function getDataSourceCount(): Promise<number> {
  return sdsRPO.getCount(getUserOrganization().id)
}

export function createDataSource(sds: JDataSourceSubmitValues): Promise<string> {
  return sdsRPO.create(getUserOrganization().id, sds)
}

export function updateDataSource(sds: JDataSourceSubmitValues): Promise<void> {
  return sdsRPO.update(getUserOrganization().id, sds)
}

export function deleteDataSource(sourceId: string): Promise<void> {
  return sdsRPO.delete(getUserOrganization().id, sourceId)
}

export function getUsersDataSourcePermissions(sourceId: string) {
  return sdsRPO.getUsersPermissions(getUserOrganization().id, sourceId)
}

export function getUserDataSourcePermissions(sourceId: string) {
  return sdsRPO.getUserPermissions(getUserOrganization().id, sourceId)
}

export function updateUsersDataSourcePermissions(sourceId: string, acls: any) {
  return sdsRPO.updateUsersPermission(getUserOrganization().id, sourceId, acls)
}

export async function updateDataSourceTags(sds: JDataSource, newTagNames: string[]): Promise<JTag[]> {
  const updatedTags: JTag[] = []

  // If any fresh tag is not found in the data source tags, add it
  for (const newTagName of newTagNames) {
    const foundTag = sds.tags.find(existingTag => newTagName === existingTag.name)
    if (foundTag) {
      updatedTags.push(foundTag)
    } else {
      const newTag = await sdsRPO.addTag(getUserOrganization().id, sds.id, newTagName)
      updatedTags.push(newTag)
    }
  }

  // If any data source tag is not found in the fresh tags, delete it
  for (const existingTag of sds.tags) {
    if (!newTagNames.includes(existingTag.name)) {
      await sdsRPO.deleteTag(getUserOrganization().id, sds.id, existingTag.id)
    }
  }

  return updatedTags
}

export function getDataSourceStatusLevel(status: DATA_SOURCE_STATUSES): STATUS_CHIP_LEVELS {
  switch (status) {
    case DATA_SOURCE_STATUSES.READY:
      return STATUS_CHIP_LEVELS.GREEN

    case DATA_SOURCE_STATUSES.UPDATING:
    case DATA_SOURCE_STATUSES.NOT_READY:
      return STATUS_CHIP_LEVELS.ORANGE

    case DATA_SOURCE_STATUSES.ERROR:
      return STATUS_CHIP_LEVELS.RED

    default:
      return STATUS_CHIP_LEVELS.NEUTRAL
  }
}

export function isHttpsUrl(value: string) {
  return /^https:\/\/.+\..+/.test(value)
}

export function dataSourceTypeCanDisplayDialogSection(section: DETAILS_DIALOG_SECTION, type: DATA_SOURCE_TYPES) {
  return DETAILS_DIALOG_SECTIONS_PERMISSIONS[type].includes(section)
}

export async function getDataSourceDeleteMessage(dataSource: JDataSource) {
  let message = `${translate("sds.delete.message1")} <b>${dataSource.name}</b>?<br><br>${translate("sds.delete.message2")}<br>`

  const { additionalInfo } = dataSource
  if (additionalInfo === undefined) {
    return message
  }
  const { references } = additionalInfo
  if (!references || Object.keys(references.projects).length === 0) {
    message += `<br>${translate("sds.delete.message.noReferences")}`
    return message
  }

  const projectIds = Object.keys(references.projects)

  try {
    const projectRequestIds = projectIds.map(projectId => ({ projectId }))

    const layerRequestIds = projectIds.flatMap(projectId =>
      references.projects[projectId].layers.map((layerId: string) => ({
        projectId,
        layerId
      }))
    )

    const [fetchedProjects, fetchedLayers] = await Promise.all([
      Promise.allSettled(projectRequestIds.map(({ projectId }) => getProject(projectId))),
      Promise.allSettled(layerRequestIds.map(({ projectId, layerId }) => getLayer(projectId, layerId)))
    ])

    const projects: Array<JProjectWithStatus | JProjectError> = fetchedProjects.map((result, index) => {
      const { projectId } = projectRequestIds[index]
      if (result.status === "fulfilled") {
        return { ...result.value, status: "success" }
      } else {
        return { id: projectId, status: "error", errorMessage: result.reason.response?.status === 403 ? translate("sds.references.project.unknown") : translate("sds.references.project.error") }
      }
    })

    const layers: Array<JLayerWithStatus | JLayerError> = fetchedLayers.map((result, index) => {
      const { layerId } = layerRequestIds[index]
      if (result.status === "fulfilled") {
        return { ...result.value, status: "success" }
      } else {
        return { id: layerId, status: "error", errorMessage: result.reason.response?.status === 403 ? translate("sds.references.layer.unknown") : translate("sds.references.layer.error") }
      }
    })

    message += `<br>${translate("sds.delete.message3")}`

    const projectMessages = projectIds.map(projectId => {
      const project = projects.find(p => p.id === projectId)

      if (project.status === "error") {
        return `<br>&nbsp;&nbsp;&nbsp;&#8226;&nbsp;&nbsp;&nbsp;<em>${translate("sds.references.project.unknown")}</em>`
      }

      const layersInProject = references.projects[projectId].layers
        .map(layerId => {
          const layer = layers.find(l => l.id === layerId)
          if (layer.status === "success") {
            return layer.name[project.defaultLanguage]
          }
          return layer.errorMessage
        })
        .filter(Boolean)

      const layerLabel = translate(`label.layer${layersInProject.length > 1 ? "s" : ""}`)
      const layersList = layersInProject.map(name => `<b>${name}</b>`).join(", ")

      return `<br>&nbsp;&nbsp;&nbsp;&#8226;&nbsp;&nbsp;&nbsp;${translate("label.project")}: <b>${project.name[project.defaultLanguage]}</b> (${layerLabel}: ${layersList})`
    })

    message += projectMessages.join("")
    message += `<br><br>${translate("sds.delete.message4")}`
  } catch (error) {
    console.error(error)
    message += `<br>${translate("sds.delete.message.error")}`
  }
  return message
}
