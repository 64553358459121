import { light } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Autocomplete, Box, createFilterOptions, ListItemIcon, ListItemText, TextField } from "@mui/material"
import { translate } from "app/language/service"
import { JMember } from "member/model"
import React from "react"
import { OrgRoleChip } from "user/components/OrgRoleChip"

interface GroupMemberSelectProps {
  addedMembers: JMember[] // only contains newly added members since dialog was opened
  filteredExistingMembers: JMember[] // does not contain members already in the group (as from server state)
  onChange: (members: JMember[]) => void
  minWidth?: string
}

export const GroupMemberSelect = (props: GroupMemberSelectProps): JSX.Element => {
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option: JMember) => option.name || option.email
  })
  return (
    <Autocomplete
      multiple
      limitTags={3}
      disableCloseOnSelect
      value={props.addedMembers}
      id="add-members-to-group-autocomplete"
      options={props.filteredExistingMembers}
      filterOptions={filterOptions}
      getOptionLabel={option => option.name}
      renderInput={params => <TextField {...params} label={translate("group.add.member.search")} />}
      renderTags={(tagValue, getTagProps) => (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            maxHeight: "5rem",
            overflowY: "auto",
            padding: "0.25rem",
            gap: "0.25rem"
          }}
        >
          {tagValue.map((option, index) => (
            <OrgRoleChip {...getTagProps({ index })} label={option.name} roles={option.roles} />
          ))}
        </Box>
      )}
      sx={{ minWidth: props.minWidth || "400px" }}
      renderOption={(propss, option) => (
        <li {...propss}>
          <ListItemIcon sx={{ minWidth: "1.5rem" }}>
            <FontAwesomeIcon icon={light("user")} />
          </ListItemIcon>
          <ListItemText>{option.name}</ListItemText>
        </li>
      )}
      onChange={(_, members) => {
        props.onChange(members)
      }}
    />
  )
}
