import { GlobalStyles } from "@mui/material"
import { Theme, ThemeProvider } from "@mui/material/styles"
import { JRootState } from "app/model"
import { store } from "app/store/store"
import React from "react"
import Favicon from "react-favicon"
import { Provider, connect } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import "resources/css/reset.css"
import faviconDark from "resources/images/favicon-dark.png"
import faviconLight from "resources/images/favicon-light.png"
import { ErrorBoundary } from "ui/components/ErrorBoundary"
import { Routes } from "ui/components/Routes"
import { OrganizationSelectDialog } from "user/components/OrganizationSelectDialog"

interface JAppProps {
  theme: Theme
}

const AppFn = (props: JAppProps) => {
  const favicon = props.theme.palette.mode === "dark" ? faviconDark : faviconLight
  return (
    <ThemeProvider theme={props.theme}>
      <ErrorBoundary>
        <Favicon url={favicon}></Favicon>
        <GlobalStyles
          styles={{
            "body *": {
              // only supported on Webkit-based browsers (like chrome or edge),
              // see https://developer.mozilla.org/en-US/docs/Web/CSS/::-webkit-scrollbar
              "&::-webkit-scrollbar": {
                backgroundColor: "transparent",
                width: "10px",
                height: "10px",
                borderStyle: "none"
              },
              "&::-webkit-scrollbar-corner": {
                backgroundColor: "transparent"
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#757575",
                borderRadius: "10px",
                backgroundClip: "content-box",
                border: "2.5px solid transparent"
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#9E9E9E",
                borderRadius: "10px",
                backgroundClip: "content-box",
                border: "2.5px solid transparent"
              }
            }
          }}
        />

        <OrganizationSelectDialog />
        <Router>
          <Routes />
        </Router>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

const AppConnect = connect(
  (state: JRootState) =>
    ({
      theme: state.ui.theme
    } as JAppProps)
)(AppFn)

export const App = (): JSX.Element => (
  <Provider store={store}>
    <AppConnect />
  </Provider>
)
