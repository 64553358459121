import { Box, Tab, Tabs } from "@mui/material"
import { translate } from "app/language/service"
import { JRootState } from "app/model"
import { MemberGrid } from "member/components/MemberGrid"
import { ORGANIZATION_TABS } from "organization/model"
import { setOrganizationActiveTab } from "organization/tools/display"
import React from "react"
import { connect } from "react-redux"
import { OrganizationApiKeyGrid } from "./OrganizationApiKeyGrid"
import { OrganizationExtension } from "./OrganizationExtension"
import { OrganizationInvitationGrid } from "./OrganizationInvitationGrid"
import { OrganizationMain } from "./OrganizationMain"

interface JOrganizationPageProps {
  activeTab: ORGANIZATION_TABS
}

const TAB_CONTENT_BY_VALUE = {
  [ORGANIZATION_TABS.MAIN]: OrganizationMain,
  [ORGANIZATION_TABS.APIKEY]: OrganizationApiKeyGrid,
  [ORGANIZATION_TABS.EXTENSION]: OrganizationExtension,
  [ORGANIZATION_TABS.MEMBER]: MemberGrid,
  [ORGANIZATION_TABS.INVITATION]: OrganizationInvitationGrid
}

const OrganizationPageFn = (props: JOrganizationPageProps): JSX.Element => {
  const PageContent = TAB_CONTENT_BY_VALUE[props.activeTab]
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        minHeight: "100%",
        maxHeight: "100%",
        padding: "2rem",
        display: "flex",
        flexDirection: "column",
        overflow: "auto"
      }}
    >
      <Box
        sx={{
          "borderBottom": 1,
          "borderColor": "divider",
          "& .MuiTabs-indicator": {
            backgroundColor: theme => theme.palette.primary.main
          }
        }}
      >
        <Tabs
          value={props.activeTab}
          sx={{
            "& button": {
              textTransform: "none",
              fontSize: "1rem",
              fontWeight: 500,
              color: theme => theme.palette.text.primary
            },
            "& button.Mui-selected": {
              color: theme => theme.palette.primary.main
            }
          }}
          onChange={(_event: any, newActiveTab: ORGANIZATION_TABS) => setOrganizationActiveTab(newActiveTab)}
        >
          <Tab value={ORGANIZATION_TABS.MAIN} label={translate("organization.tab.main")} />
          <Tab value={ORGANIZATION_TABS.APIKEY} label={translate("organization.tab.apikey")} />
          <Tab value={ORGANIZATION_TABS.EXTENSION} label={translate("organization.tab.extension")} />
          <Tab value={ORGANIZATION_TABS.MEMBER} label={translate("organization.tab.member")} />
          <Tab value={ORGANIZATION_TABS.INVITATION} label={translate("organization.tab.invitation")} />
        </Tabs>
      </Box>
      <PageContent />
    </Box>
  )
}

export const OrganizationPage = connect(
  (state: JRootState) =>
    ({
      activeTab: state.organization.activeTab
    } as JOrganizationPageProps)
)(OrganizationPageFn)
