import { GridFilterModel, GridSortModel } from "@mui/x-data-grid"
import { JVectorFileInfoLayerAttribute, JVectorFileInfoLayerIndexedAttribute } from "file/model"
import { JGroup } from "group/model"
import { JLayer, JProject } from "jmapcloud-types"
import { JMember } from "member/model"
import { JApiKey, JTag } from "organization/model"
import { JDataGridPagedResponse, JDataGridState } from "ui/tools/grid"
import { MEMBER_ROLES } from "user/model"

export const enum DATA_SOURCE_TABS {
  SOURCES = "SOURCES",
  FILES = "FILES"
}

export enum DATA_SOURCE_STATUSES {
  NOT_READY = "NOT_READY",
  READY = "READY",
  UPDATING = "UPDATING",
  ERROR = "ERROR"
}

export enum DATA_SOURCE_TYPES {
  FILE = "FileDataSource",
  VECTOR = "VectorDataSource",
  RASTER = "RasterDataSource",
  OGC_API_FEATURES = "ApiFeaturesDataSource",
  WMS_WMTS = "WmsWmtsDataSource"
}

export enum ATTRIBUTE_TYPES {
  VARCHAR = "VARCHAR",
  DOUBLE = "DOUBLE",
  INTEGER = "INTEGER",
  FLOAT = "FLOAT",
  DATE = "DATE"
}

export enum DATA_SOURCE_PERMISSIONS {
  OWNER = "OWNER",
  MODIFY = "MODIFY",
  VIEW = "VIEW",
  VECTOR_EXTRACT_FEATURE = "EXTRACT_FEATURE",
  VECTOR_CREATE_FEATURE = "CREATE_FEATURE",
  VECTOR_EDIT_FEATURE_GEOMETRY = "EDIT_FEATURE_GEOMETRY",
  VECTOR_EDIT_FEATURE_ATTRIBUTE = "EDIT_FEATURE_ATTRIBUTES",
  VECTOR_DELETE_FEATURE = "DELETE_FEATURE",
  RASTER_EXTRACT = "EXTRACT_RASTER"
}

export enum DATA_SOURCE_PERMISSIONS_GROUP {
  EDITOR = "editor",
  VIEWER_RASTER = "viewer_raster",
  VIEWER_VECTOR = "viewer_vector"
}

interface permissionsType {
  [key: string]: DATA_SOURCE_PERMISSIONS[]
}

// used when adding permissions to follow the rules set and understand the hierarchy
export const PERMISSIONS: permissionsType = {
  viewer_raster: [DATA_SOURCE_PERMISSIONS.VIEW, DATA_SOURCE_PERMISSIONS.RASTER_EXTRACT],
  viewer_vector: [
    DATA_SOURCE_PERMISSIONS.VIEW,
    DATA_SOURCE_PERMISSIONS.VECTOR_EXTRACT_FEATURE,
    DATA_SOURCE_PERMISSIONS.VECTOR_CREATE_FEATURE,
    DATA_SOURCE_PERMISSIONS.VECTOR_EDIT_FEATURE_GEOMETRY,
    DATA_SOURCE_PERMISSIONS.VECTOR_EDIT_FEATURE_ATTRIBUTE,
    DATA_SOURCE_PERMISSIONS.VECTOR_DELETE_FEATURE
  ],
  editor: [DATA_SOURCE_PERMISSIONS.VIEW, DATA_SOURCE_PERMISSIONS.MODIFY, DATA_SOURCE_PERMISSIONS.OWNER]
}

export interface header {
  label: string
  colspan: number
}

interface permission {
  [key: string]: Array<[string, DATA_SOURCE_PERMISSIONS, DATA_SOURCE_PERMISSIONS_GROUP]>
}

export interface PermissionsUI {
  header: header[]
  permissions: permission
}

// Data used to generate the permissions table in the data source permissions dialog
export const DATA_SOURCE_PERMISSIONS_VECTOR_UI: PermissionsUI = {
  header: [
    { label: "sds.permissions.editor", colspan: 2 },
    { label: "sds.permissions.viewer", colspan: 6 }
  ],
  permissions: {
    editor: [
      ["sds.permissions.editor.owner", DATA_SOURCE_PERMISSIONS.OWNER, DATA_SOURCE_PERMISSIONS_GROUP.EDITOR],
      ["sds.permissions.editor.modify", DATA_SOURCE_PERMISSIONS.MODIFY, DATA_SOURCE_PERMISSIONS_GROUP.EDITOR]
    ],
    viewer: [
      ["sds.permissions.viewer.view", DATA_SOURCE_PERMISSIONS.VIEW, DATA_SOURCE_PERMISSIONS_GROUP.EDITOR],
      ["sds.permissions.viewer.extract", DATA_SOURCE_PERMISSIONS.VECTOR_EXTRACT_FEATURE, DATA_SOURCE_PERMISSIONS_GROUP.VIEWER_VECTOR],
      ["sds.permissions.viewer.create", DATA_SOURCE_PERMISSIONS.VECTOR_CREATE_FEATURE, DATA_SOURCE_PERMISSIONS_GROUP.VIEWER_VECTOR],
      ["sds.permissions.viewer.editGeometry", DATA_SOURCE_PERMISSIONS.VECTOR_EDIT_FEATURE_GEOMETRY, DATA_SOURCE_PERMISSIONS_GROUP.VIEWER_VECTOR],
      ["sds.permissions.viewer.editAttributes", DATA_SOURCE_PERMISSIONS.VECTOR_EDIT_FEATURE_ATTRIBUTE, DATA_SOURCE_PERMISSIONS_GROUP.VIEWER_VECTOR],
      ["sds.permissions.viewer.delete", DATA_SOURCE_PERMISSIONS.VECTOR_DELETE_FEATURE, DATA_SOURCE_PERMISSIONS_GROUP.VIEWER_VECTOR]
    ]
  }
}
export const DATA_SOURCE_PERMISSIONS_RASTER_UI: PermissionsUI = {
  header: [
    { label: "sds.permissions.editor", colspan: 2 },
    { label: "sds.permissions.viewer", colspan: 2 }
  ],
  permissions: {
    editor: [
      ["sds.permissions.editor.owner", DATA_SOURCE_PERMISSIONS.OWNER, DATA_SOURCE_PERMISSIONS_GROUP.EDITOR],
      ["sds.permissions.editor.modify", DATA_SOURCE_PERMISSIONS.MODIFY, DATA_SOURCE_PERMISSIONS_GROUP.EDITOR]
    ],
    viewer: [
      ["sds.permissions.viewer.view", DATA_SOURCE_PERMISSIONS.VIEW, DATA_SOURCE_PERMISSIONS_GROUP.EDITOR],
      ["sds.permissions.viewer.extractRaster", DATA_SOURCE_PERMISSIONS.RASTER_EXTRACT, DATA_SOURCE_PERMISSIONS_GROUP.VIEWER_RASTER]
    ]
  }
}

export interface JDataSourceAcl {
  principal: string
  permissions: DATA_SOURCE_PERMISSIONS[]
  inheritedPermissions: JInheritedPermissions[]
}

export interface JInheritedPermissions {
  permission: DATA_SOURCE_PERMISSIONS
  fromResourceType: string
  fromResourceId: string
}

export interface JDataSourceBase {
  id: string
  name: string
  type: DATA_SOURCE_TYPES
  description: string
  status: DATA_SOURCE_STATUSES
  createdBy: string
  creationDate: Date
  lastModifiedBy: string
  lastModificationDate: Date
  organizationId: string
  crs: string
  tags: JTag[]
  attributes: JAttribute[]
  idAttribute: JAttribute
  params: any
  indexedAttributes: JVectorFileInfoLayerIndexedAttribute[]
  additionalInfo?: JDataSourceAdditionalInfo
}

export interface JRasterDataSource extends JDataSourceBase {
  type: DATA_SOURCE_TYPES.RASTER
}

export interface JWmsWmtsDataSource extends JDataSourceBase {
  type: DATA_SOURCE_TYPES.WMS_WMTS
  capabilitiesUrl: string
}

export interface JFileDataSource extends JDataSourceBase {
  type: DATA_SOURCE_TYPES.FILE
  idAttribute: JAttribute
}

export interface JVectorDataSource extends JDataSourceBase {
  type: DATA_SOURCE_TYPES.VECTOR
  idAttribute: JAttribute
}

export type JDataSource = JFileDataSource | JRasterDataSource | JWmsWmtsDataSource | JVectorDataSource

export interface JDataSourceReference {
  projects: {
    [key: string]: {
      layers: string[]
    }
  }
}
export interface JDataSourceAdditionalInfo {
  featureCount?: number
  references?: JDataSourceReference
}

export interface JAttribute {
  id: string
  name: string
  type: ATTRIBUTE_TYPES
  indexed: boolean
}

export interface JDataSourceSubmitValuesBase {
  id?: string // set for an update
  name: string
  description: string
  tags: string[]
  idAttributeName?: string
  indexedAttributes?: JVectorFileInfoLayerIndexedAttribute[]
}

export interface JVectorDataSourceSubmitValues extends JDataSourceSubmitValuesBase {
  type: "FILE_VECTOR"
  fileId: string
  layer: string
  crs: string
  columnX: string
  columnY: string
  params: {
    attributes: JVectorFileInfoLayerAttribute[]
    columnX?: string
    columnY?: string
    layers?: string[]
  }
  indexedAttributes?: JVectorFileInfoLayerIndexedAttribute[]
}

export interface JRasterDataSourceSubmitValues extends JDataSourceSubmitValuesBase {
  type: "FILE_RASTER"
  fileIds: string[]
}

export interface JWmsDataSourceSubmitValues extends JDataSourceSubmitValuesBase {
  type: "WMS_WMTS"
  capabilitiesUrl: string
}

/** This is the type used for inline updates in {@link DataSourceDetailsDialog} */
export type JDataSourceInlineUpdateSubmitValues = Partial<JDataSourceSubmitValuesBase> & { idAttributeName?: string }

// This is the most general type that is used for create and update repository functions
export type JDataSourceSubmitValues = JVectorDataSourceSubmitValues | JRasterDataSourceSubmitValues | JWmsDataSourceSubmitValues | JDataSourceInlineUpdateSubmitValues

export interface JDataSourceRepository {
  get(organizationId: string, page: number, size: number, sort: GridSortModel, filter: GridFilterModel): Promise<JDataGridPagedResponse<JDataSource>>
  getCount(organizationId: string): Promise<number>
  create(organizationId: string, sds: JDataSourceSubmitValues): Promise<string>
  update(organizationId: string, sds: JDataSourceSubmitValues): Promise<void>
  delete(organizationId: string, sourceId: string): Promise<void>
  addTag(organizationId: string, sourceId: string, tag: string): Promise<JTag>
  deleteTag(organizationId: string, sourceId: string, tagId: string): Promise<void>
  getFeatures(organizationId: string, sourceId: string, offset: number, limit: number): Promise<GeoJSON.FeatureCollection>
  getFeatureCount(organizationId: string, sourceId: string): Promise<number>
  getDataSourceExtent(organizationId: string, source: JDataSource): Promise<JMapBoxBounds | null>
  getUsersPermissions(organizationId: string, sourceId: string): Promise<JDataSourceAcl[]>
  getUserPermissions(organizationId: string, sourceId: string): Promise<DATA_SOURCE_PERMISSIONS[]>
  updateUsersPermission(organizationId: string, sourceId: string, acls: JDataSourceAcl[]): Promise<void>
}

export interface JMapBoxBounds {
  sw: {
    lat: number
    lng: number
  }
  ne: {
    lat: number
    lng: number
  }
}

export interface JSpatialDataSourceService {
  setSdsToDisplayDetails: (sds: JDataSource | null) => void
}

export interface JDataSourceState extends JDataGridState {
  activeTab: DATA_SOURCE_TABS
  sdsToUpdate: JDataSource | null
  sdsForWhichToEditPermissions: JDataSource | null
  sdsToDisplayDetails: JDataSource | null
}

export enum DETAILS_DIALOG_SECTION {
  ID = "ID",
  LEFT_CRS = "LEFT_CRS",
  RIGHT_CRS = "RIGHT_CRS",
  FEATURE_COUNT = "FEATURE_COUNT",
  REFERENCES = "References",
  LEFT_TAG = "LEFT_TAG",
  FULL_TAG = "FULL_TAG",
  ATTRIBUTES = "ATTRIBUTES",
  UNIQUE_ID = "UNIQUE_ID",
  CAPABILITIES_URL = "CAPABILITIES_URL",
  DESCRIPTION = "DESCRIPTION"
}

export const DETAILS_DIALOG_SECTIONS_PERMISSIONS: {
  [key in DATA_SOURCE_TYPES]: DETAILS_DIALOG_SECTION[]
} = {
  [DATA_SOURCE_TYPES.FILE]: [
    DETAILS_DIALOG_SECTION.ID,
    DETAILS_DIALOG_SECTION.LEFT_CRS,
    DETAILS_DIALOG_SECTION.FEATURE_COUNT,
    DETAILS_DIALOG_SECTION.REFERENCES,
    DETAILS_DIALOG_SECTION.LEFT_TAG,
    DETAILS_DIALOG_SECTION.ATTRIBUTES,
    DETAILS_DIALOG_SECTION.UNIQUE_ID,
    DETAILS_DIALOG_SECTION.DESCRIPTION
  ],
  [DATA_SOURCE_TYPES.RASTER]: [DETAILS_DIALOG_SECTION.ID, DETAILS_DIALOG_SECTION.RIGHT_CRS, DETAILS_DIALOG_SECTION.REFERENCES, DETAILS_DIALOG_SECTION.FULL_TAG, DETAILS_DIALOG_SECTION.DESCRIPTION],
  [DATA_SOURCE_TYPES.WMS_WMTS]: [
    DETAILS_DIALOG_SECTION.ID,
    DETAILS_DIALOG_SECTION.CAPABILITIES_URL,
    DETAILS_DIALOG_SECTION.REFERENCES,
    DETAILS_DIALOG_SECTION.FULL_TAG,
    DETAILS_DIALOG_SECTION.DESCRIPTION
  ],
  [DATA_SOURCE_TYPES.VECTOR]: [
    DETAILS_DIALOG_SECTION.ID,
    DETAILS_DIALOG_SECTION.LEFT_CRS,
    DETAILS_DIALOG_SECTION.FEATURE_COUNT,
    DETAILS_DIALOG_SECTION.REFERENCES,
    DETAILS_DIALOG_SECTION.LEFT_TAG,
    DETAILS_DIALOG_SECTION.ATTRIBUTES,
    DETAILS_DIALOG_SECTION.UNIQUE_ID,
    DETAILS_DIALOG_SECTION.DESCRIPTION
  ],
  [DATA_SOURCE_TYPES.OGC_API_FEATURES]: []
}

export interface JMemberAcl extends JMember, JDataSourceAcl {
  isAcl: boolean // whether it's a table row or an autocomplete option
  isSelected: boolean
  toBeAdded: boolean // whether it's an autocomplete chip (waiting to be made an ACL)
  isDirty: boolean // whether it needs to be updated (when saving)
  isAPIKey: boolean
  isGroup: boolean
}

export interface JAPIKeyAcl extends JApiKey, JDataSourceAcl {
  name: string
  isAcl: boolean // whether it's a table row or an autocomplete option
  isSelected: boolean
  toBeAdded: boolean // whether it's an autocomplete chip (waiting to be made an ACL)
  isDirty: boolean // whether it needs to be updated (when saving)
  isAPIKey: boolean
  isGroup: boolean
}

export interface JGroupAcl extends JGroup, JDataSourceAcl {
  roles: MEMBER_ROLES[]
  isAcl: boolean // whether it's a table row or an autocomplete option
  isSelected: boolean
  toBeAdded: boolean // whether it's an autocomplete chip (waiting to be made an ACL)
  isDirty: boolean // whether it needs to be updated (when saving)
  isAPIKey: boolean
  isGroup: boolean
}

export type JAcl = JMemberAcl | JAPIKeyAcl | JGroupAcl

export interface JProjectError {
  id: string
  status: "error"
  errorMessage: string
}

export interface JLayerError {
  id: string
  status: "error"
  errorMessage: string
}

export interface JLayerWithStatus extends JLayer {
  status: "success"
}

export interface JProjectWithStatus extends JProject {
  status: "success"
}
