import { store } from "app/store/store"
import { JGroup, JGroupCreateParams, JGroupMember, JGroupUpdateParams } from "group/model"
import groupRPO from "group/repository"
import { JMember } from "member/model"
import { MEMBER_ROLES } from "user/model"
import { getUserOrganization } from "user/tools/common"

export function getSelectedGroupId(): string {
  return store.getState().organization.selectedGroupId
}

export function retrieveRolesFromGroupMembers(group: JGroup, members: JMember[]): MEMBER_ROLES[] {
  const groupRoles: MEMBER_ROLES[] = []
  group.members.forEach(member => {
    const memberRoles = members.find(m => m.email === member.email)?.roles
    if (memberRoles && memberRoles.length > 0) {
      memberRoles.forEach(role => {
        if (!groupRoles.includes(role)) {
          groupRoles.push(role)
        }
      })
    }
  })
  return groupRoles
}

export async function updateMemberGroups(memberId: string, initialGroups: JGroup[], updatedGroups: JGroup[]): Promise<JGroupMember[]> {
  const groupsToAddMember = updatedGroups.filter(group => !initialGroups.some(g => g.id === group.id))
  const groupsToDeleteMember = initialGroups.filter(group => !updatedGroups.some(g => g.id === group.id))

  const newMembersByGroups = await Promise.all([...groupsToDeleteMember.map(group => deleteGroupMember(group.id, memberId)), ...groupsToAddMember.map(group => addGroupMembers(group.id, [memberId]))])

  return newMembersByGroups.flat()
}

export async function getAllGroups(): Promise<JGroup[]> {
  const organizationId = getUserOrganization().id
  if (typeof organizationId !== "string" || !organizationId) {
    return Promise.reject("organizationId must be a non empty string")
  }
  return groupRPO.getAllGroups(organizationId)
}

export async function getGroupById(groupId: string): Promise<JGroup> {
  const organizationId = getUserOrganization().id
  if (typeof organizationId !== "string" || !organizationId) {
    return Promise.reject("organizationId must be a non empty string")
  }
  if (typeof groupId !== "string" || !groupId) {
    return Promise.reject("groupId must be a non empty string")
  }
  return groupRPO.getGroupById(groupId, organizationId)
}

export async function updateGroup(groupId: string, params: JGroupUpdateParams): Promise<JGroup> {
  const organizationId = getUserOrganization().id
  if (typeof organizationId !== "string" || !organizationId) {
    return Promise.reject("organizationId must be a non empty string")
  }
  if (typeof groupId !== "string" || !groupId) {
    return Promise.reject("groupId must be a non empty string")
  }
  if (typeof params !== "object") {
    return Promise.reject("params must be an object")
  }
  if (typeof params.name !== "string" || !params.name) {
    return Promise.reject("params.name must be a non empty string")
  }
  return groupRPO.updateGroup(groupId, organizationId, params)
}

export async function deleteGroup(groupId: string): Promise<string> {
  const organizationId = getUserOrganization().id
  if (typeof organizationId !== "string" || !organizationId) {
    return Promise.reject("organizationId must be a non empty string")
  }
  if (typeof groupId !== "string" || !groupId) {
    return Promise.reject("groupId must be a non empty string")
  }
  return groupRPO.deleteGroup(groupId, organizationId)
}

export async function createGroup(params: JGroupCreateParams): Promise<JGroup> {
  const organizationId = getUserOrganization().id
  if (typeof organizationId !== "string" || !organizationId) {
    return Promise.reject("organizationId must be a non empty string")
  }
  if (typeof params !== "object") {
    return Promise.reject("params must be an object")
  }
  if (typeof params.name !== "string" || !params.name) {
    return Promise.reject("params.name must be a non empty string")
  }
  return groupRPO.createGroup(organizationId, params)
}

export async function deleteGroupMember(groupId: string, memberId: string): Promise<JGroupMember[]> {
  const organizationId = getUserOrganization().id
  if (typeof organizationId !== "string" || !organizationId) {
    return Promise.reject("organizationId must be a non empty string")
  }
  if (typeof groupId !== "string" || !groupId) {
    return Promise.reject("groupId must be a non empty string")
  }
  if (typeof memberId !== "string" || !memberId) {
    return Promise.reject("memberId must be a non empty string")
  }
  return groupRPO.deleteGroupMember(groupId, organizationId, memberId)
}

export async function addGroupMembers(groupId: string, emails: string[]): Promise<JGroupMember[]> {
  const organizationId = getUserOrganization().id
  if (typeof organizationId !== "string" || !organizationId) {
    return Promise.reject("organizationId must be a non empty string")
  }
  if (typeof groupId !== "string" || !groupId) {
    return Promise.reject("groupId must be a non empty string")
  }
  if (!Array.isArray(emails) || !emails.every(email => typeof email === "string")) {
    return Promise.reject("emails must be an array of strings")
  }
  return groupRPO.addGroupMembers(groupId, organizationId, emails)
}
