import { Autocomplete, Box, createFilterOptions, ListItemText, TextField, Typography, useTheme } from "@mui/material"
import { translate } from "app/language/service"
import { JGroup } from "group/model"
import React from "react"
import { StatusChip } from "ui/components/StatusChip"
import { STATUS_CHIP_LEVELS } from "ui/model"

interface GroupSelectProps {
  addedGroups: JGroup[] // contains groups for which the member is already part of (as of server state) and the newly added ones
  allGroups: JGroup[]
  onChange: (groups: JGroup[]) => void
  onBlur?: () => void
  id?: string
  sx?: any
  error?: string
}

export const GroupSelect = (props: GroupSelectProps): JSX.Element => {
  const sx = props.sx || {}
  const theme = useTheme()
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option: JGroup) => option.name
  })
  if (props.error) {
    sx["& label"] = { color: theme.palette.error.main }
  }
  return (
    <Box sx={sx}>
      <Autocomplete
        multiple
        limitTags={3}
        disableCloseOnSelect
        value={props.addedGroups}
        fullWidth
        id={props.id || "member-group-select"}
        options={props.allGroups}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        filterOptions={filterOptions}
        getOptionLabel={option => option.name}
        renderInput={params => <TextField {...params} label={translate("group.add.search")} />}
        renderTags={(tagValue, getTagProps) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              maxHeight: "5rem",
              overflowY: "auto",
              padding: "0.25rem",
              gap: "0.25rem"
            }}
          >
            {tagValue.map((option, index) => (
              <StatusChip {...getTagProps({ index })} key={option.id} sx={{ marginRight: "0.5rem" }} level={STATUS_CHIP_LEVELS.NEUTRAL} label={option.name} />
            ))}
          </Box>
        )}
        renderOption={(propss, option) => (
          <li {...propss}>
            <ListItemText>{option.name}</ListItemText>
          </li>
        )}
        onChange={(_, groups) => {
          props.onChange(groups)
        }}
        onBlur={() => props.onBlur && props.onBlur()}
      />
      {props.error && (
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 1.66,
            textAlign: "left",
            marginTop: ".1875rem !important",
            marginRight: ".875rem",
            marginBottom: 0,
            marginLeft: ".875rem"
          }}
          color={"error"}
        >
          {props.error}
        </Typography>
      )}
    </Box>
  )
}
