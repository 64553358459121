import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, TextField, Typography } from "@mui/material"
import { useErrorHandling } from "app/hook"
import { translate } from "app/language/service"
import { JRootState } from "app/model"
import { deepEquals } from "app/utils/common"
import { Formik } from "formik"
import React from "react"
import { connect } from "react-redux"
import { RichTextLabel } from "ui/components/RichTextLabel"
import { getRoleChips } from "ui/utils"
import { ROLES } from "user/model"
import { getUserOrganization, updateUser } from "user/tools/common"

interface JProfileSettingsFormDialogProps {
  close: () => void
  afterSubmit: () => void
  userName: string
  userEmail: string
  userRoles: ROLES[]
}

const ProfileSettingsFormDialogFn = (props: JProfileSettingsFormDialogProps): JSX.Element => {
  const { hasError, errorMessage, handleError, resetError } = useErrorHandling(translate("user.update.submit.error"))
  const organization = getUserOrganization()

  const initialValues = {
    userName: props.userName,
    userEmail: props.userEmail,
    userRoles: props.userRoles
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={values => {
        const errors: Partial<typeof initialValues> = {}
        if (!values.userName) {
          errors.userName = translate("label.field.required")
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        if (hasError) {
          resetError()
        }
        try {
          await updateUser({ name: values.userName })
          props.afterSubmit()
          setSubmitting(false)
        } catch (error: any) {
          console.error(error)
          handleError(error)
          setSubmitting(false)
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
        const hasChanged: boolean = !deepEquals(values, initialValues)
        const canSubmit: boolean = !isSubmitting && hasChanged && Object.keys(errors).length === 0
        return (
          <Dialog maxWidth="sm" fullWidth open>
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <DialogTitle>{translate("user.profile.settings")}</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="profile-settings-field-username"
                      name="userName"
                      label={translate("label.name")}
                      fullWidth
                      required
                      error={touched.userName && Boolean(errors.userName)}
                      helperText={touched.userName && errors.userName}
                      value={values.userName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField id="profile-settings-field-email" name="userEmail" label={translate("label.email")} value={props.userEmail} disabled fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField id="profile-settings-field-organization-name" name="organizationName" label={translate("label.organization.name")} value={organization.name} disabled fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <RichTextLabel label={translate("label.roles")} value={getRoleChips([...props.userRoles])} />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "space-between" }}>
                {hasError ? (
                  <Typography color="error" sx={{ marginLeft: "0.5em" }}>
                    {errorMessage}
                  </Typography>
                ) : (
                  <div />
                )}
                <Stack direction="row" alignItems="center" spacing={1}>
                  {isSubmitting && <CircularProgress size={20} />}
                  <Button id="organization-invitation-create-page-button-cancel" variant="outlined" disabled={isSubmitting} onClick={props.close}>
                    {translate("button.cancel")}
                  </Button>
                  <Button id="organization-invitation-create-page-button-save" type="submit" disabled={!canSubmit}>
                    {translate("button.update")}
                  </Button>
                </Stack>
              </DialogActions>
            </form>
          </Dialog>
        )
      }}
    </Formik>
  )
}

export const ProfileSettingsFormDialog = connect((state: JRootState) => ({
  userName: state.user.name,
  userEmail: state.user.email,
  userRoles: state.user.roles
}))(ProfileSettingsFormDialogFn)
