import { GridRowId, GridSelectionModel } from "@mui/x-data-grid-pro"
import { translate } from "app/language/service"
import React from "react"
import { ButtonMenu, JButtonMenuItem } from "ui/components/ButtonMenu"
import { ButtonMenuSelected } from "ui/components/ButtonMenuSelected"

interface GroupMemberGridRowMenuProps {
  selectedGroupMemberIds: GridSelectionModel
  memberId: GridRowId
  onShowDetailsDialog: () => void
  onClose: () => void
  onDeleteClick: () => void
}

export const GroupMemberGridRowMenu = (props: GroupMemberGridRowMenuProps): JSX.Element => {
  const displayAllOptions = !props.selectedGroupMemberIds.includes(props.memberId) || props.selectedGroupMemberIds.length === 1
  const items: JButtonMenuItem[] = displayAllOptions
    ? [
        {
          title: translate("member.details.tooltip"),
          onClick: () => {
            props.onShowDetailsDialog()
          }
        },
        {
          title: translate("member.delete.title"),
          onClick: () => {
            props.onDeleteClick()
          }
        }
      ]
    : [
        {
          title: translate("member.delete.title"),
          onClick: () => {
            props.onDeleteClick()
          }
        }
      ]
  if (displayAllOptions) {
    return <ButtonMenu id={`group-menu-${props.memberId}`} small items={items} onClose={props.onClose} />
  }
  return <ButtonMenuSelected id={`group-menu-${props.memberId}`} small items={items} onClose={props.onClose} selectedIds={props.selectedGroupMemberIds} />
}
