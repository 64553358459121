import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography } from "@mui/material"
import { useErrorHandling } from "app/hook"
import { translate } from "app/language/service"
import { JErrorResponse } from "app/model"
import { deepEquals } from "app/utils/common"
import { Formik } from "formik"
import { GroupSelect } from "group/components/GroupSelect"
import { JGroup } from "group/model"
import { getAllGroups, updateMemberGroups } from "group/tools/common"
import { JMember } from "member/model"
import memberRPO from "member/repository"
import { updateMember } from "member/tools/common"
import { messageSVC } from "message/service"
import React from "react"
import { TextLabel } from "ui/components/TextLabel"
import { RoleSelect } from "user/components/RoleSelect"
import { ALL_MEMBER_ROLES, MEMBER_ROLES } from "user/model"
import { getUserOrganization } from "user/tools/common"

interface JMemberUpdateProps {
  member: JMember
  onClose(): any
  onUpdate(): any
}

export const MemberUpdateDialog = (props: JMemberUpdateProps): JSX.Element => {
  const { hasError, errorMessage, handleError, resetError } = useErrorHandling(translate("member.update.submit.error"))
  const { hasError: hasLoadingError, errorMessage: LoadingErrorMessage, handleError: handleLoadingError } = useErrorHandling(translate("member.update.loading.error"))
  const [isLoading, setIsLoading] = React.useState(false)
  const [roles, setRoles] = React.useState(Array<MEMBER_ROLES>)
  const [allGroups, setAllGroups] = React.useState(Array<JGroup>)
  const [initialGroups, setInitialGroups] = React.useState(Array<JGroup>)

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const [roleData, groupData] = await Promise.all([memberRPO.getById(props.member.id, getUserOrganization().id), getAllGroups()])
        setRoles(roleData.roles)
        setAllGroups(groupData)
        setInitialGroups(groupData.filter(group => group.members.some(member => member.id === props.member.id)))
      } catch (error) {
        console.error(error)
        handleLoadingError(error as JErrorResponse)
        setAllGroups([])
        setInitialGroups([])
        setRoles([])
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [])

  const submit = async (values: any, setSubmitting: any) => {
    if (hasError) {
      resetError()
    }
    if (
      !deepEquals(values, {
        roles,
        groups: initialGroups
      })
    ) {
      try {
        await Promise.all([updateMember(props.member.id, getUserOrganization().id, values), updateMemberGroups(props.member.id, initialGroups, values.groups)])
        props.onUpdate()
        messageSVC.success(translate("member.update.success", { memberId: props.member.id }))
      } catch (error: any) {
        console.error(error)
        handleError(error)
      } finally {
        setSubmitting(false)
      }
    }
  }

  // This is needed in this context where we're using Formik, because even when the component
  // refreshes after loading the member, the initialValues with an empty role array will still
  // be locked in the Formik closure
  if (isLoading) {
    return (
      <Dialog open fullWidth maxWidth="sm">
        <DialogContent>
          <Stack spacing={2} alignItems="center">
            <CircularProgress />
          </Stack>
        </DialogContent>

        {hasLoadingError && (
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Typography color="error" sx={{ marginLeft: "0.5em" }}>
              {LoadingErrorMessage}
            </Typography>
            <Button onClick={props.onClose}>{translate("button.close")}</Button>
          </DialogActions>
        )}
      </Dialog>
    )
  }

  return (
    <Formik
      initialValues={{
        roles,
        groups: initialGroups
      }}
      validate={values => {
        if (hasError) {
          resetError()
        }
        const errors: any = {}
        return errors
      }}
      onSubmit={(values, { setSubmitting }) => submit(values, setSubmitting)}
    >
      {({ values, errors, handleSubmit, isSubmitting, setFieldValue }) => {
        const hasFormChanged: boolean = !deepEquals(values, {
          roles,
          groups: initialGroups
        })
        const canSubmit: boolean = !isSubmitting && hasFormChanged && Object.keys(errors).length === 0

        return (
          <Dialog open fullWidth maxWidth="sm" onClose={props.onClose}>
            <form onSubmit={handleSubmit} className="member-update-page-form">
              <DialogTitle>{translate("member.details.title")}</DialogTitle>

              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextLabel value={props.member.name} label={translate("label.name")} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextLabel label={translate("label.email")} value={props.member.email} />
                      </Grid>
                      <Grid item xs={6}>
                        <RoleSelect
                          id="member-update-page-field-roles"
                          // TODO: roles will maybe be multiple for members in the future, a
                          // change in behaviour will maybe be required
                          role={values.roles.length === 0 ? null : values.roles[0]}
                          availableRoles={ALL_MEMBER_ROLES}
                          onChange={newRoles => setFieldValue("roles", newRoles)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <GroupSelect id="member-update-page-field-groups" addedGroups={values.groups} allGroups={allGroups} onChange={newGroups => setFieldValue("groups", newGroups)} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ justifyContent: "space-between" }}>
                {hasError ? (
                  <Typography color="error" sx={{ marginLeft: "0.5em" }}>
                    {errorMessage}
                  </Typography>
                ) : (
                  <div />
                )}
                <Stack direction="row" alignItems="center" spacing={1}>
                  {isSubmitting && <CircularProgress size={20} />}
                  <Button id="member-update-page-button-cancel" disabled={isSubmitting} variant="outlined" onClick={props.onClose}>
                    {translate("button.cancel")}
                  </Button>
                  <Button id="member-update-page-button-save" type="submit" disabled={!canSubmit}>
                    {translate("button.save")}
                  </Button>
                </Stack>
              </DialogActions>
            </form>
          </Dialog>
        )
      }}
    </Formik>
  )
}
