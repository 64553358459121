import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { GridRowId, GridSelectionModel } from "@mui/x-data-grid-pro"
import { translate } from "app/language/service"
import { JGroup } from "group/model"
import { deleteGroup } from "group/tools/common"
import { messageSVC } from "message/service"
import React from "react"

interface GroupsDeleteDialogProps {
  groupIdsToDelete: GridSelectionModel
  currentRowGroupId: GridRowId | null
  allGroups: JGroup[]
  onClose: () => void
  onDelete: () => void
}

export const GroupsDeleteDialog = (props: GroupsDeleteDialogProps): JSX.Element => {
  const [groups, setGroups] = React.useState<JGroup[]>([])
  const [isDeletingGroups, setIsDeletingGroups] = React.useState(false)

  React.useEffect(() => {
    if (props.currentRowGroupId && !props.groupIdsToDelete.includes(props.currentRowGroupId)) {
      setGroups([props.allGroups.find(group => group.id === props.currentRowGroupId)])
    } else {
      setGroups(props.groupIdsToDelete.map(id => props.allGroups.find(group => group.id === id)))
    }
  }, [props.allGroups, props.groupIdsToDelete, props.currentRowGroupId])

  const handleDeleteGroups = async () => {
    setIsDeletingGroups(true)
    try {
      await Promise.all(groups.map(group => deleteGroup(group.id)))
      props.onDelete()
    } catch (error) {
      console.error(error)
      messageSVC.error(groups.length > 1 ? translate("group.delete.multiple.error") : translate("group.delete.single.error"))
    } finally {
      setIsDeletingGroups(false)
      props.onClose()
    }
  }

  return (
    <Dialog open={true} aria-labelledby="form-dialog-title">
      <DialogTitle
        sx={{
          minWidth: "20rem"
        }}
      >
        {groups.length > 1 ? translate("group.delete.multiple.message.title") : translate("group.delete.single.message.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          dangerouslySetInnerHTML={{
            __html:
              groups.length > 1
                ? translate("group.delete.multiple.message.confirm", { groupNames: groups.map(group => group.name).join("<br>") })
                : translate("group.delete.single.message.confirm", { groupName: groups?.[0]?.name })
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()} variant="outlined" color="primary" disabled={isDeletingGroups}>
          {translate("button.cancel")}
        </Button>
        <Button onClick={handleDeleteGroups} disabled={isDeletingGroups}>
          {translate("button.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
