import { light, regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography, useTheme } from "@mui/material"
import { Box, Stack } from "@mui/system"
import { useErrorHandling } from "app/hook"
import { translate } from "app/language/service"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { JPasswordPolicyCompliance, PASSWORD_MIN_LENGTH } from "user/model"
import userRPO from "user/repository"
import { getPasswordPolicyCompliance } from "user/tools/auth0"

interface JChangePasswordFormDialogProps {
  close: () => void
  afterSubmit: () => void
}

interface JChangePasswordFormValues {
  currentPassword: string
  newPassword: string
  newPasswordConfirmation: string
}

export const ChangePasswordFormDialog = (props: JChangePasswordFormDialogProps) => {
  const theme = useTheme()
  const [invalidCurrentPassword, setInvalidCurrentPassword] = useState(false)
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] = useState(false)
  const { hasError, errorMessage, handleError, resetError } = useErrorHandling(translate("user.change.password.submit.error"))
  const [passwordPolicyCompliance, setpasswordPolicyCompliance] = useState<JPasswordPolicyCompliance>({
    hasLowercaseLetters: false,
    hasMinimumLength: false,
    hasNumbers: false,
    hasSpecialCharacters: false,
    hasUppercaseLetters: false
  })

  const hidePasswordIcon = <FontAwesomeIcon size="sm" icon={light("eye-slash")} />
  const showPasswordIcon = <FontAwesomeIcon size="sm" icon={light("eye")} />
  const CompliantIcon = <Box component={FontAwesomeIcon} sx={{ marginRight: "0.5rem" }} color={theme.palette.success.main} icon={solid("check")} />
  const NonCompliantIcon = <Box component={FontAwesomeIcon} sx={{ marginRight: "0.5rem" }} color={theme.palette.error.main} icon={regular("times-circle")} />

  const {
    control,
    getValues,
    trigger,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<JChangePasswordFormValues>({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: ""
    }
  })

  const onSubmit = async (values: JChangePasswordFormValues) => {
    if (hasError) {
      resetError()
    }

    await userRPO
      .updateUser({
        currentPassword: values.currentPassword,
        newPassword: values.newPassword
      })
      .then(props.afterSubmit)
      .catch(error => {
        console.error(error)
        handleError(error)
        if (error.response?.data?.errors && Array.isArray(error.response.data.errors) && (error.response.data.errors as any[]).includes("invalid current password")) {
          setInvalidCurrentPassword(true)
          trigger("currentPassword")
        }
      })
  }

  return (
    <Dialog maxWidth="sm" fullWidth open>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <DialogTitle sx={{ m: 0, p: 2 }}>{translate("user.password.dialog.title")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Current password */}
            <Grid item xs={12}>
              <Controller
                control={control}
                name="currentPassword"
                rules={{
                  validate: v => {
                    const newPassword = getValues("newPassword")
                    if (!v) {
                      return translate("label.field.required")
                    }
                    if (invalidCurrentPassword) {
                      return translate("user.change.password.submit.error.currentPasswordIncorrect")
                    }
                    return true
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    onChange={e => {
                      if (invalidCurrentPassword) {
                        setInvalidCurrentPassword(false)
                        // do this in order to correctly refresh the validity state of the form
                        trigger("currentPassword").then(() => field.onChange(e))
                      } else {
                        field.onChange(e)
                      }
                    }}
                    type={showCurrentPassword ? "text" : "password"}
                    InputProps={{ endAdornment: <IconButton onClick={() => setShowCurrentPassword(!showCurrentPassword)}>{showCurrentPassword ? showPasswordIcon : hidePasswordIcon}</IconButton> }}
                    error={errors.currentPassword !== undefined}
                    helperText={errors.currentPassword?.message}
                    label={translate("user.password.current")}
                    fullWidth
                  />
                )}
              />
            </Grid>
            {/* New password */}
            <Grid item xs={12}>
              <Controller
                control={control}
                name="newPassword"
                rules={{ required: { value: true, message: translate("label.field.required") } }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    onChange={e => {
                      setpasswordPolicyCompliance({
                        ...getPasswordPolicyCompliance(e.target.value)
                      })
                      field.onChange(e)
                    }}
                    type={showNewPassword ? "text" : "password"}
                    InputProps={{ endAdornment: <IconButton onClick={() => setShowNewPassword(!showNewPassword)}>{showNewPassword ? showPasswordIcon : hidePasswordIcon}</IconButton> }}
                    error={errors.newPassword !== undefined}
                    helperText={errors.newPassword?.message}
                    label={translate("user.password.new")}
                    fullWidth
                  />
                )}
              />
            </Grid>
            {/* New password confirmation*/}
            <Grid item xs={12}>
              <Controller
                control={control}
                name="newPasswordConfirmation"
                rules={{
                  validate: v => {
                    const newPassword = getValues("newPassword")
                    if (!v) {
                      return translate("label.field.required")
                    }
                    if (v !== newPassword) {
                      return translate("user.password.confirmation.error")
                    }
                    return true
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    type={showNewPasswordConfirmation ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={() => setShowNewPasswordConfirmation(!showNewPasswordConfirmation)}>{showNewPasswordConfirmation ? showPasswordIcon : hidePasswordIcon}</IconButton>
                      )
                    }}
                    error={errors.newPasswordConfirmation !== undefined}
                    helperText={errors.newPasswordConfirmation?.message}
                    label={translate("user.password.confirmation")}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
          {getValues("newPassword") !== "" && (
            <Box
              sx={{
                display: "flex",
                marginTop: "1rem",
                flexDirection: "column",
                borderStyle: "solid",
                borderRadius: "0.4rem",
                borderWidth: "1px",
                borderColor: theme.palette.divider,
                padding: "0.5rem"
              }}
            >
              <Typography color="textPrimary">
                {passwordPolicyCompliance.hasMinimumLength ? CompliantIcon : NonCompliantIcon}
                {translate("user.password.compliance.isMinimumLength", { minLength: PASSWORD_MIN_LENGTH })}
              </Typography>
              <Typography color="textPrimary">
                {passwordPolicyCompliance.hasLowercaseLetters ? CompliantIcon : NonCompliantIcon}
                {translate("user.password.compliance.isLowercaseLetters")}
              </Typography>
              <Typography color="textPrimary">
                {passwordPolicyCompliance.hasUppercaseLetters ? CompliantIcon : NonCompliantIcon}
                {translate("user.password.compliance.isUppercaseLetters")}
              </Typography>
              <Typography color="textPrimary">
                {passwordPolicyCompliance.hasNumbers ? CompliantIcon : NonCompliantIcon}
                {translate("user.password.compliance.isNumbers")}
              </Typography>
              <Typography color="textPrimary">
                {passwordPolicyCompliance.hasSpecialCharacters ? CompliantIcon : NonCompliantIcon}
                {translate("user.password.compliance.isSpecialCharacters")}
              </Typography>
            </Box>
          )}
        </DialogContent>

        <DialogActions sx={{ justifyContent: "space-between" }}>
          {hasError ? (
            <Typography color="error" sx={{ marginLeft: "0.5em" }}>
              {errorMessage}
            </Typography>
          ) : (
            <div />
          )}
          <Stack direction="row" alignItems="center" spacing={1}>
            {isSubmitting && <CircularProgress size={20} />}
            <Button disabled={isSubmitting} variant="outlined" onClick={props.close}>
              {translate("button.cancel")}
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {translate("button.update")}
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  )
}
