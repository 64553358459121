import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography } from "@mui/material"
import { useErrorHandling } from "app/hook"
import { translate } from "app/language/service"
import { addGroupMembers } from "group/tools/common"
import { JMember } from "member/model"
import { getAllMembers } from "member/tools/common"
import { naturalSortString } from "project/utils"
import React from "react"
import { getUserOrganization } from "user/tools/common"
import { GroupMemberSelect } from "./GroupMemberSelect"

interface GroupMembersAddDialogProps {
  currentMembers: JMember[]
  groupId: string
  onClose: () => void
  onCreate: () => void
}

export const GroupMembersAddDialog = (props: GroupMembersAddDialogProps): JSX.Element => {
  const [addedMembers, setAddedMembers] = React.useState<JMember[]>([])
  const { hasError, errorMessage, handleError, resetError } = useErrorHandling(
    addedMembers.length > 0 ? translate("organization.group.members.multiple.add.submit.error") : translate("organization.group.members.single.add.submit.error")
  )
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [filteredExistingMembers, setFilteredExistingMembers] = React.useState<JMember[]>([])
  React.useEffect(() => {
    getAllMembers(getUserOrganization().id)
      .then(fetchedMembers => {
        fetchedMembers = fetchedMembers.filter(member => !props.currentMembers.find(m => m.email === member.email)).sort((m1, m2) => naturalSortString(m1.email, m2.email))
        setFilteredExistingMembers(fetchedMembers)
      })
      .catch(error => {
        console.error(error)
        setFilteredExistingMembers([])
      })
  }, [])

  const handleSubmit = () => {
    setIsSubmitting(true)
    if (hasError) {
      resetError()
    }
    addGroupMembers(
      props.groupId,
      addedMembers.map(member => member.email)
    )
      .then(() => {
        props.onCreate()
      })
      .catch(error => {
        console.error(error)
        handleError(error)
      })
      .finally(() => setIsSubmitting(false))
  }
  return (
    <Dialog open fullWidth maxWidth="sm" id="group-add-members-popup" onClose={props.onClose}>
      <DialogTitle sx={{ padding: "0 1rem 1rem 1rem" }}>{translate("organization.group.members.add")}</DialogTitle>
      <DialogContent sx={{ padding: "0 1rem 1rem 1rem" }}>
        <Grid container>
          <Grid item width={"100%"}>
            <GroupMemberSelect addedMembers={addedMembers} filteredExistingMembers={filteredExistingMembers} onChange={setAddedMembers} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", padding: "1rem 1rem 0 1rem" }}>
        {hasError ? (
          <Typography color="error" sx={{ marginLeft: "0.5rem" }}>
            {errorMessage}
          </Typography>
        ) : (
          <div />
        )}
        <Stack direction="row" alignItems="center" spacing={1}>
          {isSubmitting && <CircularProgress size={20} />}
          <Button id="group-add-members-button-cancel" variant="outlined" disabled={isSubmitting} onClick={props.onClose}>
            {translate("button.cancel")}
          </Button>
          <Button id="group-add-members-button-save" onClick={handleSubmit} disabled={isSubmitting || addedMembers.length === 0}>
            {translate("button.add")}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
